import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ColDef } from "ag-grid-community";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { SyncTable } from "@bitwarden/web-vault/app/components/sync/sync.table.col-def";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { SyncIcon } from "@bitwarden/web-vault/app/models/enum/sync.enum";
import {
  GridStrategy,
  SyncState,
  SyncStatus,
} from "@bitwarden/web-vault/app/models/types/general-types";
import { BasiqAccountSync } from "@bitwarden/web-vault/app/services/syncing/basiq.account.sync";
import { GlossSyncService } from "@bitwarden/web-vault/app/services/syncing/gloss-sync.service";
import { SyncStore } from "@bitwarden/web-vault/app/services/syncing/syncing.store";

@Component({
  selector: "app-sync",
  templateUrl: "./sync.component.html",
})
export class SyncComponent implements OnInit, OnDestroy {
  protected unsubscribe$ = new Subject<void>();

  readonly vm$ = this.syncStore.vm$;

  showSpinner = false;
  syncState: SyncState;
  autoSizeStrategy: GridStrategy = { type: "fitGridWidth" };
  syncStatus: SyncStatus = null;
  colDefs: ColDef[] = new SyncTable(this.i18nService).colDef;
  context = { componentParent: this };

  isSyncing = false;
  isCompleted = true;
  syncCompleteButton = new ActionButton({
    text: this.i18nService.t("sync"),
    class: "neutral",
    isEnabled: true,
    onClick: this.syncManually.bind(this),
    icon: SyncIcon.checkCircle,
    testId: "syncCompleteButton",
  });

  syncProcessButton = new ActionButton({
    text: this.i18nService.t("sync"),
    class: "neutral",
    isEnabled: false,
    onClick: null,
    icon: SyncIcon.syncAll,
    testId: "syncProcessButton",
  });

  mergeButton = new ActionButton({
    text: this.i18nService.t("merge"),
    class: "neutral",
    isEnabled: false,
    onClick: this.importSyncedData.bind(this),
    icon: "merge",
    testId: "syncMergeButton",
  });

  constructor(
    private glossSyncService: GlossSyncService,
    private basiqAuth: BasiqAuth,
    private route: ActivatedRoute,
    private i18nService: I18nService,
    private readonly syncStore: SyncStore
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async ngOnInit() {
    this.vm$.pipe(takeUntil(this.unsubscribe$)).subscribe((state) => {
      this.syncState = state;
      if (!this.syncState.isInitialState && this.syncState.isCompleted) {
        this.mergeButton.isEnabled = true;
        this.isSyncing = false;
        this.isCompleted = true;
        /** @Sinan ActionButton needs a way to update itself by setting the isEnabled, icon etc. so when it is updated it can update the caller as accordingly */
        this.mergeButton = new ActionButton({
          text: this.i18nService.t("merge"),
          class: "neutral",
          isEnabled: true,
          onClick: this.importSyncedData.bind(this),
          icon: "merge",
          testId: "syncMergeButton",
        });
      }
    });

    /** @Sinan The purpose here is if user is redirected to basiq and get back so that he does not need to click sync button again. But this is not tested yet */
    const isSync = this.route.snapshot.paramMap.get("sync");
    if (isSync) {
      this.syncManually();
      this.clearUrlParams();
    }

    this.mergeButton = new ActionButton({
      text: this.i18nService.t("merge"),
      class: "neutral",
      isEnabled: false,
      onClick: this.importSyncedData.bind(this),
      icon: "merge",
      testId: "syncMergeButton",
    });
  }

  clearUrlParams() {
    const currentURL = new URL(window.location.href);

    currentURL.searchParams.delete("sync");
    window.history.pushState({}, "", currentURL.toString());
  }

  async connect(accountStatus: BasiqAccountSync) {
    /** @Sinan - check if this works properly */
    await this.basiqAuth.connectToInstitution(accountStatus.accountView.institution.basiqId);
  }

  async consent() {
    await this.basiqAuth.requestBasiqConsent();
  }

  async retry() {
    await this.syncStore.startSync();
  }

  onFirstDataRendered(params: any): void {
    params.columnApi.sizeColumnsToFit();
  }

  onGridReady(params: any): void {
    params.columnApi.applyColumnState({
      state: [{ colId: "account", sort: "asc" }],
    });
  }

  syncManually() {
    this.isSyncing = true;
    this.isCompleted = false;
    this.syncStore.startSync().then(() => null);
  }

  async importSyncedData() {
    try {
      this.showSpinner = true;
      await this.glossSyncService.mergeSyncedData(this.syncState.accountStatus);
      this.showSpinner = false;
      location.reload();
    } catch (e) {
      this.showSpinner = false;
    }
  }

  getRowStyle() {
    return { height: "60px" }; // Adjust the padding value as needed
  }
}
