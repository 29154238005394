import { CurrencyPipe } from "@angular/common";
import {
  Component,
  ChangeDetectorRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
  OnDestroy,
} from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subject, take } from "rxjs";
import { takeUntil } from "rxjs/operators";

import "./transaction-table-tabs.scss";
import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { WizardService } from "@bitwarden/web-vault/app/components/account-wizard-stepper/wizard-stepper-service";
import { ScenarioDifferenceMessageComponent } from "@bitwarden/web-vault/app/components/scenario-difference-message/scenario-difference-message.component";
import { HelpTooltipComponent } from "@bitwarden/web-vault/app/components/tooltips/help-tooltip/help-tooltip.component";
import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import {
  ScenarioAccountInfo,
  ScenarioData,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { SharedService } from "@bitwarden/web-vault/app/shared/sharedAppEvent.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper-common";
import { HelperTooltip } from "@bitwarden/web-vault/app/shared/utils/helper-tooltip";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

const NONE = "NONE";

@Component({
  selector: "app-transaction-table-tabs",
  templateUrl: "transaction-table-tabs.component.html",
  styles: ["transaction-table-tabs.scss"],
  providers: [CurrencyPipe],
})
export class TransactionTableTabsComponent implements OnDestroy {
  @ViewChild("accordionContainer", { read: ViewContainerRef }) accordionContainer: ViewContainerRef;
  @ViewChild(HelpTooltipComponent) helpTooltipComponent: HelpTooltipComponent;
  inDevMode = HelperCommon.isDevEnv();
  @Output() linkConversion = new EventEmitter<any>();
  @Output() linkTransfer = new EventEmitter<any>();
  @Output() unlinkTransactions = new EventEmitter<any>();
  @Output() deleteTransaction = new EventEmitter<any>();
  @Output() resetColumns = new EventEmitter<any>();

  @Input() isLinkButtonEnabled: boolean;

  private destroy$ = new Subject<void>();
  tabs: Array<{ key: number; content: string; type: string; class: string }>;
  graphData: Array<GraphDataSet>;
  scenarioData: ScenarioData;
  dashboardService: DashboardService;
  hasTransactions = false;
  baseCurrency = GLOBAL_BASE_CURRENCY;
  scenarioDetails: any;
  scenarioPathName: string;
  scenarioPathDesc: string;

  differenceScenarioRef: MatDialogRef<ScenarioDifferenceMessageComponent>;

  selectedTabKey: string | null = null;
  tooltip: HelperTooltip;

  constructor(
    private injector: Injector,
    public dialog: MatDialog,
    private renderer: Renderer2,
    private deviceService: DeviceDetectorService,
    private sharedService: SharedService,
    private currencyPipe: CurrencyPipe,
    protected changeDetectorRef: ChangeDetectorRef,
    private dateFormat: DateFormat,
    private wizardService: WizardService,
    private preferenceService: PreferenceService,
    private i18nService: I18nService,
    private sanitizer: DomSanitizer
  ) {
    this.dashboardService = this.injector.get(DashboardService);
    this.subscribeToDashboardObservables();
    this.tooltip = new HelperTooltip();
  }

  tabName(tab: { key: number; content: string; type: string; class: string }) {
    if (this.tabs.length > 3 && this.isMobile() && !tab.class.includes("selected")) {
      return tab.content.slice(0, 3) + "..." + (tab.type === "transaction" ? "" : tab.key + 1);
    } else {
      return tab.content;
    }
  }

  tabContainerByDevice(tab: { key: number; content: string; type: string; class: string }) {
    if (this.isDesktop()) {
      return tab.class + " inDesktop";
    }
    if (this.isMobile()) {
      return tab.class;
    }
    if (this.isTablet()) {
      return tab.class;
    }
  }

  // shining effect on scenario 1 tooltip
  highlightScenarioButton(scenarioNumber: number) {
    const scenarioButton = document.getElementById(`scenario-${scenarioNumber}-help-button`);

    if (scenarioButton) {
      scenarioButton.classList.add("tw-animate-ping", "opacity-100");

      const removeHighlight = () => {
        scenarioButton.classList.replace("tw-animate-ping", "bg-transparent");
        scenarioButton.classList.replace("opacity-100", "opacity-25"); // Adjust as needed
        scenarioButton.removeEventListener("click", removeHighlight); // Clean up the event listener
      };

      scenarioButton.addEventListener("click", removeHighlight);
    }
  }

  async subscribeToDashboardObservables() {
    const preference = this.preferenceService.getPreferenceInstance();
    this.baseCurrency = preference.baseCurrency;
    this.dashboardService.graphData$.pipe(takeUntil(this.destroy$)).subscribe((graphData) => {
      this.graphData = this.dashboardService.isVaultPurge ? [] : graphData;
      this.buildTabData(false);
    });
    this.dashboardService.scenarioData$.pipe(takeUntil(this.destroy$)).subscribe((scenarioData) => {
      this.scenarioData = this.dashboardService.isVaultPurge
        ? { balance: [], scenario: [] }
        : scenarioData;
      if (this.scenarioData.scenario.length > 0) {
        this.graphData = this.scenarioData.balance;
        this.buildTabData(true);

        this.wizardService.buttonClick$.pipe(take(1)).subscribe((value) => {
          if (value) {
            this.openScenarioDifferenceMessage();
            this.wizardService.emitButtonClick(false);
          }
        });
      }
    });
  }

  async openScenarioDifferenceMessage() {
    const dialogRef = this.dialog.open(ScenarioDifferenceMessageComponent, {
      maxWidth: "800px",
      data: { scenarioData: this.scenarioData, baseCurrency: this.baseCurrency },
      disableClose: true,
      panelClass: ["scenario-difference-dialog", "xs:tw-h-full"],
      autoFocus: false,
    });
    this.differenceScenarioRef = dialogRef;
    // Use Renderer2 to add the class to the mat-dialog-container element
    dialogRef
      .afterOpened()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        const matDialogContainer = document.querySelector("mat-dialog-container");
        if (matDialogContainer && this.isMobile()) {
          this.renderer.addClass(matDialogContainer, "mobile-mat-dialog-container");
        } else {
          this.renderer.addClass(matDialogContainer, "desktop-mat-dialog-container");
        }
      });
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((result) => {
        if (result !== "skip") {
          this.clickOnScenario3Button();
        }
      });
  }

  // todo: needs improvement but logic is not use set timeout
  clickOnScenario3Button() {
    const onboardingStartPoint = document.getElementById("scenario-3-help-button");
    if (onboardingStartPoint) {
      onboardingStartPoint.scrollIntoView({ behavior: "smooth", block: "center" });
      setTimeout(() => {
        const helpButtonForScenario3 = document.getElementById("scenario-3-help-button");
        if (helpButtonForScenario3) {
          helpButtonForScenario3.dispatchEvent(
            new MouseEvent("click", {
              bubbles: true,
              cancelable: true,
            })
          );
        }
      }, 500);
    }
    this.highlightScenarioButton(1);
  }

  async updateTab(type: string, key: number) {
    // update the scenario tab on the dashboard service
    await this.dashboardService.updateTransactionsTable(type, key, false);

    this.sharedService.updateGridTabType(type);

    for (const key in this.tabs) {
      this.tabs[key].class = "tabs";
    }

    if (type === "transaction") {
      this.tabs[0].class = "tabs selected";
    } else if (this.hasTransactions) {
      this.tabs[key + 1].class = "tabs selected";
    } else {
      this.tabs[key].class = "tabs selected";
    }
  }

  async openTabHelp(event: MouseEvent, type: string, key: number) {
    this.toggleSelected(key.toString());
    this.getScenarioDetails(key);
    this.tooltip.show(event);
  }

  private buildTabData(hasScenario: boolean) {
    const tabData = [];

    const transactionTab = {
      content: "Transactions",
      type: "transaction",
      key: 0,
      class: "tabs transaction-tab",
    };

    this.hasTransactions = false;
    if (!hasScenario) {
      tabData.push(transactionTab);
    } else {
      if (this.graphData.length > 0) {
        tabData.push(transactionTab);
        this.hasTransactions = true;
      }

      for (let i = 0; i < this.scenarioData.scenario.length; i++) {
        const isSelected = i === 2;
        const scenarioTab = {
          content: "Path " + (i + 1),
          type: "scenario",
          key: i,
          class: isSelected ? "tabs selected" : "tabs",
          id: `scenario-${i + 1}-tab`,
        };
        tabData.push(scenarioTab);
      }
    }
    this.tabs = tabData;
  }

  protected getFormattedBalance(balance: number) {
    return this.currencyPipe.transform(balance, this.baseCurrency, "symbol");
  }

  protected getScenarioAccountInfo(account: ScenarioAccountInfo) {
    const accountName = this.getAccount(account);
    const rate = this.getRate(account);
    const institutionName = this.getInstitutionName(account);
    return `${institutionName} ${accountName} ${rate}`;
  }

  private getScenarioDetails(key: number) {
    const scenarioText = "";
    if (
      this.scenarioData &&
      this.scenarioData.scenario.length > 0 &&
      this.scenarioData.scenario?.[key]
    ) {
      this.scenarioDetails = this.scenarioData.scenario[key];
      const path = this.i18nService.t("path");
      const scenarioPaths: { [key: number]: string } = {
        0: "currentAccountGrowth",
        1: "bestExistingAccount",
        2: "topRegionalAccount",
      };
      const scenarioName = this.i18nService.t(scenarioPaths[key]);
      this.scenarioPathName = `${path} ${key + 1}: ${scenarioName}`;
      // extract the scenario help text from
      // const helpInfo = glossRouteInfo[GlossRouteEnum.dashboard];
      this.scenarioPathDesc = this.i18nService.t(scenarioPaths[key] + "Desc");
    }
    return scenarioText;
  }

  getInstitutionName(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.accountBank ? accountInfo.accountBank : "";
  }

  getUrl(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.accountUrl ? accountInfo.accountUrl : "";
  }

  getAccount(accountInfo: ScenarioAccountInfo) {
    return accountInfo?.accountName ? accountInfo.accountName : "";
  }

  getLastCheckedDate(accountInfo: ScenarioAccountInfo) {
    const hasAccountInfo = accountInfo?.rate && accountInfo?.rate.length > 0;
    return hasAccountInfo ? accountInfo.rate[0].last_updated : "";
  }

  // resorted to using innerHTML to avoid more method calls
  getLastCheckedDateMarkup(accountInfo: ScenarioAccountInfo) {
    const lastCheckedDate = this.getLastCheckedDate(accountInfo);
    const relativeDays = lastCheckedDate && this.dateFormat.toDateWithRelativeDays(lastCheckedDate);
    if (typeof relativeDays === "object") {
      const { date, duration } = relativeDays;
      return this.sanitizer.bypassSecurityTrustHtml(`<div>${date}</div><div>(${duration})</div>`);
    }
    return ``;
  }

  cleanDescription(descList: string[]) {
    return descList.filter((item) => item.length !== 0 && item.trim() !== NONE);
  }

  getScenarioAccountConditions(accountInfo: ScenarioAccountInfo) {
    const accountInfoRate = accountInfo.rate[0];
    const intrinsicDesc = accountInfoRate?.condition_intrinsic_desc.trim().split("\n");
    const actionDesc = accountInfoRate?.condition_action_desc.trim().split("\r\n*");
    const allDesc = intrinsicDesc.concat(actionDesc);
    return this.cleanDescription(allDesc);
  }

  getRate(accountInfo: ScenarioAccountInfo) {
    if (accountInfo.rate && accountInfo.rate.length > 0) {
      const maxRate = accountInfo.rate.reduce((max, current) => {
        return current.rate > max ? current.rate : max;
      }, accountInfo.rate[0].rate);
      const minRate = accountInfo.rate.reduce((min, current) => {
        return current.rate < min ? current.rate : min;
      }, accountInfo.rate[0].rate);

      const rate = accountInfo.rate.length > 1 ? `${minRate} - ${maxRate}` : `${maxRate}`;
      return `(${rate}% p.a.)`;
    } else {
      return "(unknown account type)";
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  callLinkConversion() {
    this.linkConversion.emit();
  }

  callDeleteTransaction() {
    this.deleteTransaction.emit();
  }

  callLinkTransfer() {
    this.linkTransfer.emit();
  }

  callUnlinkTransactions() {
    this.unlinkTransactions.emit();
  }

  toggleSelected(tabKey: string | null) {
    this.selectedTabKey = tabKey;
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  isTablet() {
    return this.deviceService.isTablet();
  }

  isDesktop() {
    return this.deviceService.isDesktop();
  }
}
