import { ColDef } from "ag-grid-community";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";

import { SyncActionButtonsCellRenderer } from "../cell-renderers/sync-action-buttons-renderer/sync-action-buttons-cell-renderer.component";
import { SyncStatusRendererComponent } from "../cell-renderers/sync-status-renderer/sync-status-renderer.component";

const commonDefs = {};

export class SyncTable {
  constructor(private i18nService: I18nService) {}

  colDef: ColDef[] = [
    {
      headerName: this.i18nService.t("account"),
      field: "account",
      ...commonDefs,
      valueGetter: (params) => params.data?.accountView?.name,
    },
    {
      headerName: this.i18nService.t("status"),
      field: "status",
      ...commonDefs,
      cellRenderer: SyncStatusRendererComponent,
    },
    {
      headerName: "Info",
      field: "info",
      ...commonDefs,
      valueFormatter: (p) => p.data.point?.data?.message,
    },
    {
      headerName: this.i18nService.t("lastSyncedAt"),
      field: "lastSyncedAt",
      ...commonDefs,
      valueFormatter: (p) => p.data.lastSyncedAt?.getDateTimeAndZoneString(),
    },
    {
      //TODO - Add a buttons depending on p.data.point.data.actions
      headerName: this.i18nService.t("action"),
      field: "action",
      ...commonDefs,
      cellRenderer: SyncActionButtonsCellRenderer,
    },
  ];
}
