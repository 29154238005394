import { Injector } from "@angular/core";

import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { SplitCategoryType } from "@bitwarden/web-vault/app/models/types/split-category-type";
import { CategoryService } from "@bitwarden/web-vault/app/services/DataService/category/category.service";

import { Category } from "../../models/data/blobby/category.data";

import { HelperCommon } from "./helper-common";

export class HelperCategory extends HelperCommon {
  private categoryService: CategoryService;

  constructor(injector: Injector) {
    super(injector);
    this.categoryService = injector.get(CategoryService);
  }

  protected async updateTransactionCategoryToDefault(deletedCategory: Category) {
    const defaultCategory: Category = await this.categoryService.getGeneralDefaultCategory();
    const transactions = await this.transactionService.getAll(HelperCategory.RUN_VALUATION);
    const transactionsByCategory = this.filterTransactionsByCategory(transactions, deletedCategory);

    for (const transaction of transactionsByCategory) {
      for (let i = 0; i < transaction.categories.length; i++) {
        const transCat = transaction.categories[i];

        if (transCat.categoryId === deletedCategory.id) {
          if (!this.isDefaultCategoryExist(transaction.categories, defaultCategory.id)) {
            await this.setToDefault(transCat, defaultCategory);
            await this.transactionService.update(transaction);
          } else {
            /** If default category-renderer exist just delete the category-renderer from transaction */
            transaction.categories.splice(i, 1);
          }
        }
      }
    }
  }

  private filterTransactionsByCategory(transactions: Transaction[], category: Category) {
    return transactions.filter((transaction) => {
      return transaction.categories.some(
        (transCategory) => transCategory.categoryId === category.id
      );
    });
  }

  private async setToDefault(category: SplitCategoryType, defaultCategory: Category) {
    category.categoryId = defaultCategory.id;
    category.name = defaultCategory.name;
    category.roundingDefault = true;
    category.weight = 1;

    return category;
  }

  private isDefaultCategoryExist(categories: SplitCategoryType[], defaultId: string) {
    let isExist = false;

    for (const category of categories) {
      if (category.categoryId === defaultId) {
        isExist = true;
        break;
      }
    }
    return isExist;
  }
}
