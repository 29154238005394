<app-spinner [showSpinner]="balanceForm.loading"></app-spinner>
<div class="modal-container">
  <modal-header [showBackButton]="false" [title]="balanceForm.title"></modal-header>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <form [formGroup]="balanceForm.formGroup" name="accountForm">
      <div class="tw-mb-6 tw-flex tw-flex-col tw-gap-4">
        <radio-button
          [options]="balanceForm.balances"
          (onClick)="balanceForm.selectBalance($event)"
          class="tw-gap-2"
        >
        </radio-button>
        <div class="tw-flex tw-justify-between tw-gap-4 sm:tw-gap-6 tb:tw-flex-col">
          <app-date-picker
            [label]="balanceForm.i18nService.t('date')"
            [isRequired]="true"
            [hasError]="balanceForm.formGroup.get('date').invalid"
            (changeDateEvent)="balanceForm.onDateChange($event)"
            class="tw-w-full"
          >
          </app-date-picker>
          <app-gloss-input
            [options]="balanceForm.timeInputOptions"
            class="tw-w-full"
          ></app-gloss-input>
        </div>
        <div class="tw-flex tw-justify-between tw-gap-4 tb:tw-flex-col">
          <app-gloss-input
            [options]="balanceForm.balanceInputOptions"
            class="tw-w-full"
          ></app-gloss-input>
          <app-currencies
            (selectedCurrency)="balanceForm.selectCurrency($event)"
            [label]="balanceForm.i18nService.t('symbol')"
            class="tw-w-full"
          ></app-currencies>
        </div>
        <app-gloss-input
          [options]="balanceForm.descriptionInputOptions"
          class="tw-w-full"
        ></app-gloss-input>
      </div>
      <app-gloss-button
        [options]="balanceForm.saveButtonOptions"
        class="tw-w-full"
      ></app-gloss-button>
    </form>
  </div>
</div>
