import { Injectable } from "@angular/core";

import { fileTypeAllow } from "../../shared/utils/file-upload/file-type";
import { extractFileType } from "../../shared/utils/helper-string";

@Injectable({
  providedIn: "root",
})
export class FileValidationRule {
  private readonly MAXIMUM_FILE_SIZE = 1024 * 1024; // 1MB 1*1024*1024
  fileName = "";

  isValidType(file: File) {
    const fileType = extractFileType(file);
    return fileTypeAllow.includes(fileType);
  }

  isValidSize(file: File) {
    return file.size <= this.MAXIMUM_FILE_SIZE;
  }
}
