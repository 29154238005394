import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { InstitutionResponse } from "@bitwarden/web-vault/app/models/data/response/institution.response";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import { InstitutionConfigType } from "@bitwarden/web-vault/app/models/types/environement-config.type";
import { Country } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionAccount } from "@bitwarden/web-vault/app/models/types/institution.type";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { AppStateService } from "@bitwarden/web-vault/app/services/app-state.service";
import { PerformanceService } from "@bitwarden/web-vault/app/services/performance/performance.service";
import { AppState } from "@bitwarden/web-vault/app/state/app.state";

import { Institution } from "../../../models/data/blobby/institution.data";
import { ItemCount } from "../../../models/data/shared/item-count";
import { UserLocationCodeEnum } from "../../../models/enum/user-location.enum";
import { ItemCountService } from "../../../services/DataService/state/item-count.service";

@Injectable({
  providedIn: "root",
})
export class InstitutionService {
  private _institutionsMasterList: Institution[] = null;
  private _countriesMasterList: Country[] = null;
  private _isMasterListLoaded = false;
  private _isMasterListLoading = false;
  private pending: Array<any> = [];
  private appState: AppState;
  private itemCount: ItemCount;

  protected institutionListSubject = new BehaviorSubject<Institution[]>([]);
  institutionList$ = this.institutionListSubject.asObservable();
  institutions: Institution[] = [];

  protected countriesListSubject = new BehaviorSubject<Country[]>([]);
  countriesList$ = this.countriesListSubject.asObservable();
  countries: Country[] = [];

  protected hasAccount: boolean;
  protected userLocation: string;
  isExistingUser: boolean;

  constructor(
    private logService: LogService,
    private performanceService: PerformanceService,
    private dataRepositoryService: DataRepositoryService,
    private appStateService: AppStateService,
    private preferenceService: PreferenceService,
    private itemCountService: ItemCountService
  ) {
    this.appState = this.appStateService.getAppStateInstance();
  }

  async create(newInstitution: Institution): Promise<Institution> {
    try {
      return await this.dataRepositoryService.createInstitution(newInstitution);
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async update(institution: Institution, triggerObservable: boolean): Promise<Institution> {
    try {
      const updatedInstitution = await this.dataRepositoryService.updateInstitution(institution);
      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return updatedInstitution;
    } catch (e) {
      this.logService.error(e);
      throw e;
    }
  }

  async delete(institution: Institution, triggerObservable: boolean): Promise<boolean> {
    try {
      const success = await this.dataRepositoryService.deleteInstitution(institution);

      if (triggerObservable) {
        // todo refactor updateObservables await this.updateObservables();
      }
      return success;
    } catch (e) {
      this.logService.error(e.message);
      throw e;
    }
  }

  async getAll(): Promise<Institution[]> {
    return await this.dataRepositoryService.getAllInstitutions();
  }

  async filterInstitutionAccountById(
    institutionAccountId: string,
    institution: Institution
  ): Promise<InstitutionAccount> {
    if (institution?.availableAccounts) {
      const institutionAccounts = institution.availableAccounts;
      return institutionAccounts.find((a) => a.id === institutionAccountId);
    }
  }

  async getInstitutionById(institutionId: string): Promise<Institution> {
    const institutions = await this.dataRepositoryService.getAllInstitutions();
    return institutions.find((i) => i.id === institutionId);
  }

  async getAccountType(account: Book): Promise<InstitutionAccount> {
    const institutions = await this.dataRepositoryService.getAllInstitutions();
    const institution = institutions.find(
      (institution) => institution.id === account.institutionLink.institutionId
    );
    return institution?.availableAccounts?.find(
      (accountType) => accountType.id === account.institutionLink.institutionAccountId
    );
  }

  /*todo following http request and url are mock data. once the api is ready they should be replaced */
  /** return the institution of the selected country */
  async getCountryInstitutions(selectedCountry: Country) {
    const institutions = await this.getInstitutionsMasterList();
    return institutions.filter(
      (institution) => institution.bic.countryCode === selectedCountry.code
    );
  }

  async initialise() {
    this.itemCount = this.itemCountService.getItemCountInstance();
    this.hasAccount = this.itemCount.getTotalAccount() > 0;

    let userLocation = (await this.preferenceService.get(PreferenceType.userLocation)) as string;

    if (!userLocation) {
      if (this.hasAccount) {
        userLocation = UserLocationCodeEnum.AU;
      } else {
        this.preferenceService.setUserLocation(false);
      }
    }

    if (
      (!this.appState.getIsInitialized() && !this._isMasterListLoading && userLocation) ||
      this.isExistingUser
    ) {
      this._isMasterListLoading = true;
      this.logService.info("Loading Institutions from API");
      await this.loadInstitution(userLocation);
    }
  }

  async loadInstitution(location?: string): Promise<void> {
    await this.LoadInstitutionsFromApi(location)
      .then()
      .finally(() => {
        this._isMasterListLoaded = true;
        this._isMasterListLoading = false;
        this.pending.forEach((resolve) => {
          resolve();
        });
      });
  }

  isMasterListInitialised(): Promise<void> {
    const userLocation = Promise.resolve(this.preferenceService.get(PreferenceType.userLocation));
    return new Promise((resolve) => {
      if (this._isMasterListLoaded && userLocation) {
        resolve();
      } else {
        this.logService.info("Waiting for isMasterListInitialised to resolve");
        this.pending.push(() => {
          resolve();
        });
      }
    });
  }

  async getInstitutionsMasterList(): Promise<Institution[]> {
    return await this.isMasterListInitialised().then(async () => {
      return this._institutionsMasterList;
    });
  }

  async getCountriesMasterListObservable(): Promise<Country[]> {
    let countries: Country[] = [];
    this.countriesList$.subscribe((countriesList) => {
      countries = countriesList;
    });

    return countries;
  }

  async getInstitutionsMasterListObservable(): Promise<Institution[]> {
    let institutions: Institution[] = [];
    this.institutionList$.subscribe((institutionLists) => {
      institutions = institutionLists;
    });

    return institutions;
  }

  async getCountryMasterList(): Promise<Country[]> {
    return await this.isMasterListInitialised().then(async () => {
      return this._countriesMasterList;
    });
  }

  async LoadInstitutionsFromApi(location?: string): Promise<void> {
    this.performanceService.mark("LoadInstitutionsFromApi");
    try {
      const institutionConfig = process.env.INSTITUTIONS as unknown as InstitutionConfigType;
      const payload = institutionConfig.endpoint.institutions.includes("v2")
        ? `${institutionConfig.endpoint.institutions}/${location}`
        : `${institutionConfig.endpoint.institutions}`;

      const institutionsResult = await this.dataRepositoryService.send(
        "GET",
        payload,
        null,
        true,
        true,
        `${institutionConfig.url}/${institutionConfig.apiStage}`
      );

      this._institutionsMasterList = institutionsResult.institutions.map(
        (insto: any) => new Institution(new InstitutionResponse(insto))
      );
      this._countriesMasterList = institutionsResult.countries as Country[];
      this.logService.info("Institutions Loaded");

      /** Update the Observable Institutions and Countries */
      this.institutionListSubject.next(this._institutionsMasterList);
      this.countriesListSubject.next(this._countriesMasterList);
    } catch (error) {
      this._institutionsMasterList = [];
      this._countriesMasterList = [];
      this.logService.error("Institution Token Error");
      this.logService.error(error);
    } finally {
      this.performanceService.markEnd();
    }
  }

  async updateInstitution(institution: Institution) {
    return await this.dataRepositoryService.updateInstitution(institution);
  }

  async isInstitutionExist(institution: Institution): Promise<boolean> {
    const institutions = await this.dataRepositoryService.getAllInstitutions();
    return institutions.some((inst) => inst.name === institution.name);
  }
}
