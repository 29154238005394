import { BalanceAlignmentDescription } from "@bitwarden/web-vault/app/models/data/utils/glossBalance.utils";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";

import { Transaction } from "../../../models/data/blobby/transaction.data";

export const createBalanceTransactionResponse = (
  transaction: Transaction,
  openingBalance: number
) => {
  return {
    accountId: transaction.accountId,
    description: transaction.description || BalanceAlignmentDescription.Generated,
    bankImportedBalance: openingBalance,
    balance: openingBalance,
    currency: transaction.quantity.actualQuantity.symbol,
    date: transaction.getRawTransactionDate(),
    categories: transaction.categories,
    classifications: transaction.classifications,
    definition: TransactionStatusEnum.alignment,
  };
};
