import { Injector } from "@angular/core";
import { take } from "rxjs";

import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { SyncIcon } from "@bitwarden/web-vault/app/models/enum/sync.enum";
import { ISync, ISyncStore } from "@bitwarden/web-vault/app/models/interfaces/sync.interface";
import { SyncStatusPoints } from "@bitwarden/web-vault/app/services/api/basiq/fixture/sync-status-messages";
import { BasiqTransactionProcessingService } from "@bitwarden/web-vault/app/services/api/basiq/transaction.processing.service";
import { SyncStore } from "@bitwarden/web-vault/app/services/syncing/syncing.store";

import { AccountState, StatusPoint } from "../../models/types/general-types";
import { ThirdPartyTransaction } from "../../models/types/transaction.types";
import { AccountView } from "../../models/view/account.view";

export class BasiqAccountSync implements ISync {
  isMerged = false;
  id: string;

  accountView: AccountView;
  /**
   * The raw transactions that were fetched from the third party provider like Basiq or Plaid. This should be how we pass the original data type that we pass to the import when doing a regular connections
   * @type {{ data:ThirdPartyTransaction[] }}
   */
  rawTransactions: { data: ThirdPartyTransaction[] } = { data: [] };

  /**
   * Weather the account sync is completed or not. success or failure.
   */
  isCompleted = false;

  /**
   * A point at which the syncing of the account is at.
   * @type {point}
   */
  point: StatusPoint = {
    key: "started-syncing",
    icon: SyncIcon.syncAll,
    data: null,
  };

  lastSyncedAt: GlossDate = GlossDate.getCurrentGlossDate();

  private syncStore: SyncStore;
  private transactionProcessingService: BasiqTransactionProcessingService;

  constructor(accountView: AccountView, private injector: Injector) {
    this.accountView = accountView;
    this.id = accountView.id;
    this.transactionProcessingService = this.injector.get(BasiqTransactionProcessingService);
  }

  private completeAccountSync(statusPoint: StatusPoint) {
    this.syncStore.getCurrentState.pipe(take(1)).subscribe((currentState) => {
      this.updateState(currentState, statusPoint);
    });
  }

  updateState(currentState: ISyncStore, statusPoint: StatusPoint) {
    const newStatus = this.getNewState(currentState, statusPoint);
    this.syncStore.updateAccountStatus(newStatus);
  }

  getNewState(currentState: ISyncStore, statusPoint: StatusPoint): AccountState[] {
    return currentState.accountsState.map((accountStatus) => {
      if (this.accountView.originalBook.id === accountStatus.accountId) {
        return this.updatedAccountState(accountStatus, statusPoint);
      }
      return accountStatus;
    });
  }

  updatedAccountState(accountStatus: AccountState, statusPoint: StatusPoint): AccountState {
    accountStatus.point = statusPoint;
    accountStatus.isCompleted = true;
    accountStatus.rawTransactions = this.rawTransactions.data;

    return accountStatus;
  }

  async sync() {
    try {
      this.syncStore = this.injector.get(SyncStore);
      this.rawTransactions = await this.transactionProcessingService.getRawBasiqTransactions(
        this.accountView.originalBook,
        true
      );

      this.completeAccountSync(SyncStatusPoints.dataFetchSuccess);
    } catch (error) {
      this.failSync(SyncStatusPoints.dataFetchFailure);
    }
  }

  failSync(statusPoint: StatusPoint) {
    this.completeAccountSync(statusPoint);
  }

  getAccountStatus(): AccountState {
    return {
      accountId: this.accountView.originalBook.id,
      isMerged: this.isMerged,
      point: this.point,
      isCompleted: this.isCompleted,
      isStarted: true,
      accountView: this.accountView,
      lastSyncedAt: this.lastSyncedAt,
      rawTransactions: this.rawTransactions.data,
    };
  }
}
