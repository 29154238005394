import { SyncIcon } from "@bitwarden/web-vault/app/models/enum/sync.enum";
import { StatusPoint } from "@bitwarden/web-vault/app/models/types/general-types";

/** @Sinan clear this messaging into a type */
export const SyncStatusPoints: Record<string, StatusPoint> = {
  /** When there is no active connection to the institution*/
  started: {
    key: "started-syncing",
    icon: SyncIcon.syncAll,
    data: null,
  },

  workingOnSync: {
    key: "working-on-sync",
    icon: SyncIcon.syncAll,
    data: null,
  },
  /** When there is no active connector [Basiq or Plaid] */
  noConnectorAvailable: {
    key: "failed",
    icon: SyncIcon.xCircle,
    data: {
      type: "failure",
      message: "Gloss Vault had some troubles connecting",
      messageI18nKey: "noConnectorFound",
      actions: ["connect"],
    },
  },
  /** When there is no active connection to the institution*/
  noBasiqUser: {
    key: "failed",
    icon: SyncIcon.xCircle,
    data: {
      type: "failure",
      message: "You need to connect to Basiq first",
      messageI18nKey: "needToConnectToBasiq",
      actions: ["consent"],
    },
  } /** When there is no active connection to the institution*/,
  noBasiqConsent: {
    key: "failed",
    icon: SyncIcon.xCircle,
    data: {
      type: "failure",
      message: "You need to authenticate with Basiq first",
      messageI18nKey: "needToConnectToBasiq",
      actions: ["consent"],
    },
  },
  /** When there is no active connection to the institution*/
  noConnection: {
    key: "failed",
    icon: SyncIcon.xCircle,
    data: {
      type: "failure",
      message: "Gloss Vault couldn't connect to your institution",
      messageI18nKey: "noConnectionFound",
      actions: ["connect"],
    },
  },

  /** When the connection to institution failed to refresh: meaning it could not update transactions in Basiq as well*/
  connectionRefreshFailure: {
    key: "failed",
    icon: SyncIcon.xCircle,
    data: {
      type: "failure",
      message: "Connection refresh failed",
      messageI18nKey: "connectionRefreshFailed",
      actions: ["retry", "connect"],
    },
  },

  /** When the connection refreshed but somehow could not get the new data: Refresh gives us jobs, this is when this job fails*/
  jobFetchFail: {
    key: "failed",
    icon: SyncIcon.xCircle,
    data: {
      type: "failure",
      message: "Could not retrieve data",
      messageI18nKey: "couldNotRetrieveData",
      actions: ["retry"],
    },
  },

  /** When job is successful and Basiq updates the data. Now we can fetch the new data*/
  jobFetchSuccess: {
    key: "ready",
    icon: SyncIcon.checkCircle,
    data: {
      type: "success",
      message: "Ready to merge",
      messageI18nKey: "jobFetchSuccess",
      actions: [],
    },
  },

  /** When data is successfully retrieved*/
  dataFetchSuccess: {
    key: "ready",
    icon: SyncIcon.checkCircle,
    data: {
      type: "success",
      message: "Transactions fetched successfully",
      actions: [],
      messageI18nKey: "transactionsFetchSuccessfully",
    },
  },

  /** When data is successfully retrieved*/
  dataFetchFailure: {
    key: "failed",
    icon: SyncIcon.xCircle,
    data: {
      type: "failure",
      message: "Failed to fetch transactions. Something went wrong.",
      actions: ["retry"],
      messageI18nKey: "transactionsFetchFailed",
    },
  },
};
