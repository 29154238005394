import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { CustomerSupportBoxComponent } from "@bitwarden/web-vault/app/components/customer-support-box/customer-support-box.component";
import { RoleAccessData } from "@bitwarden/web-vault/app/models/data/role-access.data";

@Component({
  selector: "app-user-access-page",
  templateUrl: "user-access-page.component.html",
})
export class UserAccessPageComponent implements OnInit, OnDestroy {
  @Output() toggleDisplay = new EventEmitter<void>();
  private destroy$ = new Subject<void>();
  private roleAccess: RoleAccessData;

  earlyRequestDialogRef: MatDialogRef<CustomerSupportBoxComponent>;
  isPremiumUser = false;
  requestAccessButtonOptions: ActionButton;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private i18nService: I18nService
  ) {
    this.requestAccessButtonOptions = new ActionButton({
      text: this.i18nService.t("requestAccess"),
      class: "primary",
      icon: "link",
      onClick: this.requestEarlyAccess.bind(this),
    });
  }

  ngOnInit() {
    this.checkRoleAccess();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  checkRoleAccess() {
    if (this.roleAccess) {
      const claim = this.roleAccess.getClaim();

      this.isPremiumUser = claim.includes("premium");
    }
  }

  requestEarlyAccess = async () => {
    this.earlyRequestDialogRef = this.dialog.open(CustomerSupportBoxComponent, {
      panelClass: "no-background-dialog",
      data: {
        supportBoxHeader: this.i18nService.t("requestEarlyAccess"),
        textFieldMessage: this.i18nService.t("earlyAccessDefaultMessage"),
        dialogOpen: true,
        closeDialogue: this.closeEarlyRequestDialog.bind(this),
      },
      disableClose: true,
    });

    this.earlyRequestDialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  };

  redirectToSettings() {
    this.toggleDisplay.emit();
  }

  closeEarlyRequestDialog() {
    this.earlyRequestDialogRef.close();
  }
}
