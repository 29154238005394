<div
  class="tw-flex tw-flex-col tw-gap-6 tw-rounded-lg tw-bg-white tw-p-6 md:tw-h-full md:tw-w-full md:tw-p-4"
>
  <nav
    class="tw-relative tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-4"
    (click)="redirectToSettings()"
  >
    <div class="md:tw-hidden">
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <div
      class="tw-hidden tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-neutral-50 tw-shadow-button md:tw-flex"
    >
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <span class="tw-text-xl tw-font-semibold tw-text-neutral-800 md:tw-hidden">{{
      "changeMasterPassword" | i18n
    }}</span>
    <div class="tw-absolute tw-hidden tw-w-full tw-items-center tw-justify-center md:tw-flex">
      <span class="tw-text-xl tw-font-semibold tw-text-neutral-800">{{
        "changeMasterPassword" | i18n
      }}</span>
    </div>
  </nav>
  <!--  todo: slowly remove app-callout-->
  <app-gloss-callout title="dangerZone" body="loggedOutWarning"></app-gloss-callout>
  <form
    #form
    [appApiAction]="formPromise"
    ngNativeValidate
    autocomplete="off"
    class="tw-flex tw-w-fit tw-min-w-[400px] tw-max-w-[500px] tw-flex-col tw-gap-6 xs:tw-items-center xs:tw-justify-center md:tw-w-full md:tw-min-w-[300px]"
  >
    <app-password-input [options]="currentPasswordInputOptions"></app-password-input>

    <div class="tw-flex tw-w-full tw-gap-6 xs:tw-flex-col">
      <div class="tw-flex tw-w-[50%] tw-flex-col xs:tw-w-full">
        <app-password-input [options]="newPasswordInputOptions"></app-password-input>
        <app-password-strength
          [password]="password"
          [email]="email"
          [showText]="true"
          (passwordStrengthResult)="getStrengthResult($event)"
        >
        </app-password-strength>
      </div>
      <div class="tw-flex tw-w-[50%] xs:tw-w-full">
        <app-password-input [options]="confirmPasswordInputOptions"></app-password-input>
      </div>
    </div>

    <!--    todo: password hint design is a bit different https://www.figma.com/design/cCdcAGMB3MR1Sd6l3L8SvS/Gloss-%7C-User-Access-page?node-id=105-14279&node-type=frame&t=wHY8v8w8GxQzMvhr-0-->
    <div class="tw-flex tw-w-full tw-flex-col">
      <label for="masterPasswordHint">{{ "masterPassHintLabel" | i18n }}</label>
      <input
        id="masterPasswordHint"
        class="input-field"
        maxlength="50"
        type="text"
        name="MasterPasswordHint"
        [(ngModel)]="passwordHint"
      />
    </div>

    <app-gloss-button
      class="tw-w-full"
      [options]="{
        text: 'save' | i18n,
        class: 'primary',
        onClick: submitChange.bind(this),
        isEnabled: !form.loading
      }"
    ></app-gloss-button>
  </form>
</div>
