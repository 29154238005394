import { BaseResponse } from "@bitwarden/common/models/response/base.response";

export class WizardResponse extends BaseResponse {
  __v: number;
  id: string;
  step: { stepIndex: number; isComplete: boolean };

  constructor(response: any) {
    super(response);
    this.__v = this.getResponseProperty("__v");
    this.id = this.getResponseProperty("_id");
    // this is to fix the conflict of changing the name of step to _step
    // the old user using step instead of _step so wizard response gives only undefined for step property
    const step = this.getResponseProperty("_step");
    if (step) {
      this.step = step;
    } else {
      this.step = this.getResponseProperty("step");
    }
  }
}
