<ng-container *ngIf="helpOpen">
  <div
    *ngIf="!formSendSuccess"
    class="customer-support modal-container tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-overflow-auto"
  >
    <div class="tw-absolute tw-z-[3] tw-h-full tw-w-full tw-bg-neutral-500 tw-opacity-40"></div>
    <div class="tw-z-[4] tw-flex tw-flex-col xs:tw-h-full xs:tw-w-full xs:tw-bg-white">
      <div class="m-header tw-mb-[30px] tw-px-5">
        <div class="tw-flex tw-items-center tw-text-2xl xs:tw-text-lg">
          <svg class="tw-mr-3 tw-h-8 tw-w-8 tw-text-primary">
            <use xlink:href="images/sprite.svg#help" />
          </svg>
          {{ supportBoxHeader }}
        </div>
        <button (click)="closeHelpBox()" aria-label="Close dialog" tabindex="-1">
          <svg class="tw-h-4 tw-w-4 tw-text-black">
            <use [attr.xlink:href]="'images/sprite.svg#close'" />
          </svg>
        </button>
      </div>
      <div class="m-body tw-flex tw-flex-col tw-p-5">
        <div
          *ngIf="requestProcessing"
          class="tw-flex tw-h-[390px] tw-items-center tw-justify-center"
        >
          <div class="lds-default">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div *ngIf="!requestProcessing">
          <form
            [formGroup]="supportEnquiry"
            (ngSubmit)="sendMessage()"
            class="tw-flex tw-flex-col tw-gap-2"
          >
            <div class="autocomplete-container">
              <label for="firstName">{{ "firstName" | i18n }} *</label>
              <mat-form-field appearance="fill" class="autocomplete-form-field" floatLabel="always">
                <input
                  id="firstName"
                  type="text"
                  name="firstName"
                  class="autocomplete-input"
                  formControlName="firstName"
                  matInput
                  required
                  placeholder="First Name"
                />
                <mat-error
                  class="error-inline input-error"
                  *ngIf="
                    supportEnquiry.controls.firstName.errors?.required &&
                    supportEnquiry.controls.firstName.touched
                  "
                >
                  {{ "firstNameisRequired" | i18n }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="tw-flex tw-h-[70px] tw-w-full tw-flex-col">
              <label for="lastName">{{ "lastName" | i18n }}</label>
              <input
                id="lastName"
                type="text"
                name="lastName"
                class="input-field"
                formControlName="lastName"
                placeholder="Last Name"
              />
            </div>

            <div class="autocomplete-container">
              <label for="email">{{ "emailAddress" | i18n }} *</label>
              <mat-form-field appearance="fill" class="autocomplete-form-field" floatLabel="always">
                <input
                  id="email"
                  type="text"
                  name="email"
                  class="autocomplete-input"
                  formControlName="email"
                  matInput
                  required
                  placeholder="email"
                />
                <mat-error
                  class="error-inline input-error"
                  *ngIf="
                    supportEnquiry.controls.email.errors?.required &&
                    supportEnquiry.controls.email.touched
                  "
                >
                  {{ "emailRequired" | i18n }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="autocomplete-container !tw-h-auto">
              <label for="message">{{ "message" | i18n }} *</label>
              <mat-form-field appearance="fill" floatLabel="always">
                <textarea
                  id="message"
                  type="text"
                  name="message"
                  formControlName="message"
                  matInput
                  required
                  placeholder="Enter your message..."
                  [rows]="rows"
                ></textarea>
                <mat-error
                  class="error-inline tw-text-xs"
                  *ngIf="
                    supportEnquiry.controls.message.errors?.required &&
                    supportEnquiry.controls.message.touched
                  "
                >
                  {{ "emailRequiredDesc" | i18n }}
                </mat-error>
              </mat-form-field>
            </div>

            <div class="tw-mt-8">
              <app-gloss-button
                [options]="{
                  class: 'accent',
                  text: 'submit' | i18n,
                  onClick: submit.bind(this),
                  isEnabled: true
                }"
              >
              </app-gloss-button>
              <div *ngIf="submitClicked && !supportEnquiry.valid" class="tw-pl-3 tw-text-danger">
                Don't forget to fill out all the fields
              </div>
            </div>
          </form>
          <div *ngIf="webError" class="tw-text-danger">
            {{ webError }}
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
