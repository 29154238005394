<div
  class="tw-flex tw-flex-col tw-gap-6 tw-rounded-lg tw-bg-white tw-p-6 md:tw-h-full md:tw-w-full md:tw-p-4"
>
  <nav
    class="tw-relative tw-flex tw-w-full tw-cursor-pointer tw-items-center tw-gap-4"
    (click)="redirectToSettings()"
  >
    <div class="md:tw-hidden">
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <div
      class="tw-hidden tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-lg tw-bg-neutral-50 tw-shadow-button md:tw-flex"
    >
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#left-arrow" />
      </svg>
    </div>
    <span class="tw-text-xl tw-font-semibold tw-text-neutral-800 md:tw-hidden">{{
      "advancedFeatures" | i18n
    }}</span>
    <div class="tw-absolute tw-hidden tw-w-full tw-items-center tw-justify-center md:tw-flex">
      <span class="tw-text-xl tw-font-semibold tw-text-neutral-800">{{
        "advancedFeatures" | i18n
      }}</span>
    </div>
  </nav>
  <div class="tw-flex tw-flex-col tw-gap-6">
    <app-gloss-callout title="dangerZone" body="dangerZoneDesc"></app-gloss-callout>

    <ng-container *ngFor="let item of contextList">
      <ng-container
        *ngTemplateOutlet="
          advancedFeaturesButtons;
          context: {
            roleScope: item.roleScope,
            buttonText: item.buttonText,
            buttonOptions: item.buttonOptions
          }
        "
      >
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template
  #advancedFeaturesButtons
  let-roleScope="roleScope"
  let-buttonText="buttonText"
  let-buttonOptions="buttonOptions"
>
  <div *appRoleScope="roleScope" class="tw-w-[190px] md:tw-w-full">
    <div class="tw-mb-2.5 tw-whitespace-nowrap tw-font-medium">{{ buttonText | i18n }}</div>
    <app-gloss-button [options]="buttonOptions"></app-gloss-button>
  </div>
</ng-template>
