// async-cell-renderer.component.ts
import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";

@Component({
  selector: "app-out-amount-cell-renderer",
  templateUrl: "./out-amount-renderer.component.html",
})
export class OutAmountCellRenderer implements ICellRendererAngularComp {
  amount: number;
  symbol: string;
  isOut = false;

  agInit(params: any): void {
    if (params.data?.direction) {
      if (params.data.direction === TransactionDirection.Out) {
        this.isOut = true;
        this.amount = params.data.quantity.actualQuantity.amount;
        this.symbol = params.data.quantity.actualQuantity.symbol;
      }
    } else if (params.node?.group) {
      this.isOut = true;
      this.amount = params.node.aggData?.amountOut;
    } else {
      this.isOut = false;
    }
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }
}
