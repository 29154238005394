import { ButtonClass, ButtonType } from "@bitwarden/web-vault/app/models/types/button.types";

import { IButton } from "../../../models/interfaces/button.interface";

export abstract class Button implements IButton {
  isEnabled: boolean;
  class: ButtonClass;
  fullClass: string;
  extraClasses: string;
  testId: string;
  type: ButtonType;
  text: string;
  link: string;
  icon: string;
  onClick: () => void;

  constructor(properties: {
    type?: string;
    link?: string;
    onClick?: () => void;
    isEnabled?: boolean;
    icon?: string;
    class?: string;
    fullClass?: string;
    text?: string;
    testId?: string;
  }) {
    this.class = (properties.class as ButtonClass) || "primary";
    this.type = (properties.type as ButtonType) || "none";
    this.link = properties.link || null;
    this.text = properties.text || "";
    this.icon = properties.icon || "";
    this.isEnabled = properties.isEnabled ?? true;
    this.onClick = () => this.onButtonClicked(properties?.onClick);
    this.fullClass = properties.fullClass || "";
    this.testId = properties.testId;
  }

  enableButton(value: boolean) {
    this.isEnabled = value;
  }

  onButtonClicked(onClick: () => void) {
    this.type === "submit" && this.enableButton(false);
    onClick && onClick();
  }
}
