import { Injectable, Injector } from "@angular/core";
import { take } from "rxjs";

import { TransactionBasiqImporter } from "@bitwarden/web-vault/app/importers/transaction-basiq-importer";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { ConnectorResponse } from "@bitwarden/web-vault/app/models/data/response/connector.response";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { ISyncStore } from "@bitwarden/web-vault/app/models/interfaces/sync.interface";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { SyncStatusPoints } from "@bitwarden/web-vault/app/services/api/basiq/fixture/sync-status-messages";
import { BasiqConnector } from "@bitwarden/web-vault/app/services/syncing/basiq-connector.service";
import { GlossConnectorService } from "@bitwarden/web-vault/app/services/syncing/gloss-connector.service";
import { SyncStore } from "@bitwarden/web-vault/app/services/syncing/syncing.store";

import {
  AccountOriginGroup,
  AccountStatusOriginGroup,
  AccountState,
  Origin,
  SyncStatus,
  StatusPoint,
} from "../../models/types/general-types";
import { AccountView } from "../../models/view/account.view";
import { BookService } from "../DataService/book/book.service";

@Injectable({
  providedIn: "root",
})
export class GlossSyncService {
  private dataRepositoryService: DataRepositoryService;
  private transactionBasiqImporter: TransactionBasiqImporter;
  private institutionService: InstitutionService;
  private autoAccounts: AccountView[] = [];
  private accountOriginGroup: AccountOriginGroup = null;
  private bookService: BookService;
  private syncStore: SyncStore;
  private connectors: BasiqConnector[] = [];

  constructor(private injector: Injector) {
    this.bookService = this.injector.get(BookService);
    this.dataRepositoryService = this.injector.get(DataRepositoryService);
    this.transactionBasiqImporter = this.injector.get(TransactionBasiqImporter);
    this.institutionService = this.injector.get(InstitutionService);
  }

  async sync() {
    this.syncStore = this.injector.get(SyncStore);
    this.connectors = this.getGlossConnectors();
    if (!this.connectors || this.connectors.length === 0) {
      this.failSync(SyncStatusPoints.noConnectorAvailable);
      return;
    }

    this.runConnectorLevelSync();
  }

  runConnectorLevelSync() {
    this.syncStore.getCurrentState.pipe(take(1)).subscribe((currentState) => {
      this.updateState(currentState, SyncStatusPoints.workingOnSync);
      this.connectors.forEach((connector) => {
        connector.sync().then(() => null);
      });
    });
  }

  failSync(statusPoint: StatusPoint) {
    this.syncStore.getCurrentState.pipe(take(1)).subscribe((currentState) => {
      this.updateState(currentState, statusPoint);
    });
  }

  updateState(currentState: ISyncStore, statusPoint: StatusPoint) {
    const newState = this.getNewState(currentState, statusPoint);

    this.syncStore.updateAccountStatus(newState);
  }

  getNewState(currentState: ISyncStore, statusPoint: StatusPoint): AccountState[] {
    return currentState.accountsState.map((accountStatus) => {
      const isAccount = this.autoAccounts.some(
        (accountView) => accountView.originalBook.id === accountStatus.accountId
      );
      if (isAccount) {
        accountStatus.point = statusPoint;
      }

      if (isAccount && statusPoint.key === "failed") {
        accountStatus.isCompleted = true;
      }
      return accountStatus;
    });
  }

  /** Get the last statuses of all accounts that have a connector such as Basiq or Plaid */
  async getLastStatus(): Promise<SyncStatus> {
    let accountStatus: AccountState[] = [];
    const connectors: Connector[] = await this.dataRepositoryService.getAllConnectors();
    if (connectors.length > 0) {
      accountStatus = await this.getLastConnectors(connectors);
    }
    return {
      isStarted: false,
      isFinished: null,
      isSyncableAccounts: true,
      accountStatus,
    };
  }

  async getLastConnectors(connectors: Connector[]): Promise<AccountState[]> {
    const accountStatus: AccountState[] = [];
    for (const connector of connectors) {
      const connectorAccountStatus = await this.getConnectorLastState(connector);
      accountStatus.push(...connectorAccountStatus);
    }

    return accountStatus;
  }

  async getConnectorLastState(connector: Connector): Promise<AccountState[]> {
    const accountStatusWithViews: AccountState[] = [];
    const connectorAccountStatus = connector.accountStatus;
    for (const accStatus of connectorAccountStatus) {
      const accStatusWithView = await this.addAccountView(accStatus);
      accountStatusWithViews.push(accStatusWithView);
    }
    return accountStatusWithViews;
  }

  async addAccountView(accStatus: AccountState): Promise<AccountState> {
    const account = await this.bookService.get(accStatus.accountId);
    if (!account) {
      return;
    }
    const accountInstoId = account.institutionLink.institutionId;
    const institution = await this.institutionService.getInstitutionById(accountInstoId);
    accStatus.isCompleted = false;
    accStatus.accountView = new AccountView(account, institution);
    accStatus.lastSyncedAt = new GlossDate().setToDateObj(accStatus.lastSyncedAt as GlossDate);

    return accStatus;
  }

  /** Checks if there are any auto-books based on Basiq or Plaid that needs a sync */
  async isSyncableAccounts(): Promise<boolean> {
    this.autoAccounts = await this.bookService.getAutoBooksView();
    return this.autoAccounts.length > 0;
  }

  /** Groups the accounts based on their origin being Basiq or Plaid */
  private getAccountOriginGroup(): AccountOriginGroup {
    const grouped = {} as AccountOriginGroup;
    for (const account of this.autoAccounts) {
      const accountOrigin = account.origin;
      if (!grouped[accountOrigin]) {
        grouped[accountOrigin] = [];
      }
      grouped[accountOrigin].push(account);
    }

    return grouped;
  }

  /** Get the connectors as a Class to be used*/
  getGlossConnectors(): BasiqConnector[] {
    this.accountOriginGroup = this.getAccountOriginGroup();
    const connectors: BasiqConnector[] = [];
    for (const origin in this.accountOriginGroup) {
      const connector = new GlossConnectorService(
        origin as Origin,
        this.accountOriginGroup[origin as Origin],
        this.injector
      ).getConnector();
      connectors.push(connector);
    }
    return connectors;
  }

  /** Handles the saving the data part */
  async mergeSyncedData(accountStatuses: AccountState[]) {
    for (const accountStatus of accountStatuses) {
      if (accountStatus.rawTransactions.length === 0) {
        accountStatus.isMerged = true;
        continue;
      }
      const { accountView, rawTransactions } = accountStatus;

      const book = accountView.originalBook;
      const transactions = { data: rawTransactions };
      await this.transactionBasiqImporter.saveSyncedTransactions(book, false, transactions);
      await this.transactionBasiqImporter.addTomorrowOpeningBalance(book);
      /** @Sinan add closing balance here */
      accountStatus.isMerged = true;
    }
    await this.updateConnectorsInBlobby(accountStatuses);
  }

  /** Once data synced the connectors' statuses are too in blobby , so we can know what is and was the last status */
  async updateConnectorsInBlobby(accountStatuses: AccountState[]) {
    /** connectors in Blobby: They are without AccountViw and ThirdPartyTransactions defined in the type  */
    const blobbyConnectors = await this.getBlobbyConnectors();

    /** The object that has connectors key, aka origin, as key and the accounts statuses as value*/
    const connectorStatusRecord: AccountStatusOriginGroup =
      this.getConnectorStatusMap(accountStatuses);

    /** Now we need to either update those connectors or add them if they do not exist */
    for (const origin in connectorStatusRecord) {
      /** we need to remove accountViews and rawTransaction from them before saving them. Cuz it takes too much space
       * in blobby, and we dynamically can create them */
      const accountStatuses: AccountState[] = this.getCleanStates(
        connectorStatusRecord[origin as Origin]
      );

      /** identify which connector we are updating/creating */
      const connector: Connector = blobbyConnectors.find(
        (connector: Connector) => connector.origin === origin
      );
      if (connector) {
        /** set the accountsStatus on the connector to simplified (no rawTransactions no accountViews) */
        connector.accountStatus = accountStatuses;
        await this.dataRepositoryService.updateConnector(connector);
      } else {
        /** get the new connector object that has the simplified accountStatus */
        const newConnectorObj: any = this.getNewConnectorObj(origin as Origin, accountStatuses);
        const newConnector = new Connector(new ConnectorResponse(newConnectorObj));
        await this.dataRepositoryService.createConnector(newConnector);
      }
    }
  }

  getNewConnectorObj(origin: Origin, accountStatuses: AccountState[]): any {
    return {
      _id: crypto.randomUUID(),
      _name: origin,
      _origin: origin,
      _connectorType: origin,
      _institution: [],
      _connectionInfo: {},
      accountStatus: accountStatuses,
    };
  }

  /** Remove rawTransactions and accountViews from account states, so we don't save them in blobby and set the point key accordingly */
  getCleanStates(accountStates: AccountState[]): AccountState[] {
    return accountStates.map((status: AccountState) => {
      status.rawTransactions = [];
      status.accountId = status.accountView.originalBook.id;
      status.accountView = null;
      status.point.key = status.point.data.type === "success" ? "synced" : "failed";
      return status;
    });
  }

  /** Get a Record<origin, AccountState[]> so can know which connector has which accounts */
  getConnectorStatusMap(accountStatuses: AccountState[]): AccountStatusOriginGroup {
    return accountStatuses.reduce((acc: AccountStatusOriginGroup, accountStatus: AccountState) => {
      const connectorKey = accountStatus.accountView.originalBook.origin;
      if (!acc[connectorKey]) {
        acc[connectorKey] = [];
      }

      acc[connectorKey as Origin].push(accountStatus);
      return acc;
    }, {} as AccountStatusOriginGroup);
  }

  /** Get all the connectors from blobby */
  async getBlobbyConnectors(): Promise<Connector[]> {
    return await this.dataRepositoryService.getAllConnectors();
  }
}
