import { BasiqConnector } from "../../services/syncing/basiq-connector.service";
import { BasiqInstitutionConnection } from "../../services/syncing/basiq-institution-connection";
import { AccountState, StatusPoint } from "../types/general-types";

/** This interface is the base component store interface to use ngRX Component store */
export interface ISyncStore {
  isInitialState: boolean;
  isStarted: boolean;
  isCompleted: boolean;
  isSyncAccounts: boolean;
  accountsState: AccountState[];
  basiqConnector: BasiqConnector;
  basiqInstitutionConnections: BasiqInstitutionConnection[];
}

export interface ISync {
  sync(): void;
  updateState(currentState: ISyncStore, statusPoint: StatusPoint): void;
  failSync(statusPoint: StatusPoint): void;
  getNewState(currentState: ISyncStore, statusPoint: StatusPoint): AccountState[];
}
