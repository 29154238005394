<!--<mat-icon-->
<!--  class="tw-block tw-w-full tw-mt-[3px] mat-icon-secondary mat-icon-tooltip {{ class }}"-->
<!--  [ngClass]="{ clicked: isClicked }"-->
<!--&gt;-->
<!--  info_outline-->
<!--</mat-icon>-->
<svg
  id="help-info-button"
  class="tw-ml-2 tw-h-{{ size }} tw-w-{{ size }} tw-text-neutral-500 hover:tw-text-primary {{
    class
  }}"
  [ngClass]="{ 'tw-text-primary': isClicked }"
>
  <use xlink:href="images/sprite.svg#info-tooltip" />
</svg>
