<div class="tw-flex tw-h-full tw-items-center tw-justify-between">
  <app-gloss-button
    *ngIf="buttons.consent"
    [options]="syncBasiqConsentButtonOptions"
  ></app-gloss-button>
  <app-gloss-button
    *ngIf="buttons.connect"
    [options]="syncBasiqConnectButtonOptions"
  ></app-gloss-button>
  <app-gloss-button
    *ngIf="buttons.retry"
    [options]="syncBasiqRetryButtonOptions"
  ></app-gloss-button>
</div>
