import { ComponentType } from "@angular/cdk/portal";
import { Component, EventEmitter, Output } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { RemoveBasiqConsentComponent } from "@bitwarden/web-vault/app/settings/remove-basiq-consent/remove-basiq-consent.component";

import { RoleScope } from "../models/enum/role-access.enum";

import { DeauthorizeSessionsComponent } from "./deauthorize-sessions.component";
import { DeleteAccountComponent } from "./delete-account.component";
import { PurgeVaultComponent } from "./purge-vault.component";

@Component({
  selector: "app-danger-zone",
  templateUrl: "./danger-zone.component.html",
})
export class DangerZoneComponent {
  @Output() toggleDisplay = new EventEmitter<void>();
  private dialogRef: MatDialogRef<any> | null = null;
  private destroy$ = new Subject<void>();
  protected readonly RoleScope = RoleScope;
  contextList: { roleScope: RoleScope; buttonText: string; buttonOptions: ActionButton }[] = [];

  constructor(private dialog: MatDialog, private i18nService: I18nService) {
    this.contextList = [
      {
        roleScope: RoleScope.GLOSS,
        buttonText: "deauthorizeSessions",
        buttonOptions: this.generateButtonOptions(
          "deauthorizeSessions",
          DeauthorizeSessionsComponent
        ),
      },
      {
        roleScope: RoleScope.BETA_ACCESS,
        buttonText: "purgeVault",
        buttonOptions: this.generateButtonOptions("purgeVault", PurgeVaultComponent),
      },
      {
        roleScope: RoleScope.BETA_ACCESS,
        buttonText: "removeBasiqConsent",
        buttonOptions: this.generateButtonOptions(
          "removeBasiqConsent",
          RemoveBasiqConsentComponent
        ),
      },
      {
        roleScope: RoleScope.GLOSS,
        buttonText: "deleteAccount",
        buttonOptions: this.generateButtonOptions("deleteAccount", DeleteAccountComponent),
      },
    ];
  }

  // todo now we can really have a factory for this
  generateButtonOptions(text: string, component: ComponentType<any>): ActionButton {
    return new ActionButton({
      text: this.i18nService.t(text),
      class: "danger",
      onClick: () => this.openDialog(component),
      isEnabled: !this.isDialogOpen(),
    });
  }

  openDialog(component: ComponentType<any>) {
    this.dialogRef = this.dialog.open(component, {
      panelClass: "mobile-dialog",
      width: "600px",
      maxWidth: "100vh",
    });
    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef = null;
      });
  }

  isDialogOpen(): boolean {
    return this.dialogRef !== null;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  redirectToSettings() {
    this.toggleDisplay.emit();
  }
}
