import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";

// todo: refactor the html so it handles the different types of dialogs, right now only warning

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
})
export class ConfirmationDialogComponent {
  isMessageKey = false;
  closeBtnOptions: ActionButton;
  confirmBtnOptions: ActionButton;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private i18nService: I18nService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      type: string;
      message: string;
    }
  ) {
    this.closeBtnOptions = new ActionButton({
      text: this.i18nService.t("no"),
      class: "neutral",
    });

    this.confirmBtnOptions = new ActionButton({
      text: this.i18nService.t("yes"),
      class: "primary",
    });

    if (data.message) {
      this.isMessageKey = this.isAllLowerCase(data.message) || this.isCamelCase(data.message);
    }
  }

  private isAllLowerCase(message: string) {
    return /^[a-z]+$/.test(message);
  }

  private isCamelCase(message: string) {
    return /^[a-z]+([A-Z][a-z]+)+$/.test(message);
  }
}
