import {
  Component,
  ElementRef,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDatepicker } from "@angular/material/datepicker";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSelect, MatSelectChange } from "@angular/material/select";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { BalanceForm } from "@bitwarden/web-vault/app/models/types/account.types";
import { CabinetFileUploadedData } from "@bitwarden/web-vault/app/models/types/cabinet.types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { CabinetService } from "@bitwarden/web-vault/app/services/DataService/vault-file/cabinet.service";
import {
  BalanceActionEnum,
  BookBalance,
} from "@bitwarden/web-vault/app/shared/utils/helper.book/balance";

@Component({
  selector: "app-cabinet-file-upload",
  templateUrl: "./cabinet-file-upload.component.html",
})
export class CabinetFileUploadComponent implements OnInit, OnDestroy {
  uploading = false;
  uploaded = false;
  @ViewChild("accountSelect") accountSelect: MatSelect;
  @ViewChild("fromPicker") fromPicker: MatDatepicker<Date>;
  @ViewChild("toPicker") toPicker: MatDatepicker<Date>;
  @ViewChild("openingBalanceInput") openingBalanceInput: ElementRef;
  @ViewChild("closingBalanceInput") closingBalanceInput: ElementRef;
  baseCurrency: string;
  openingBalanceErrorText: string;
  openingBalanceError: boolean;
  fromDateText = "";
  toDateText = "";
  accountViews: AccountView[] = [];
  accountInAction: AccountView;
  linkedAccounts: AccountView[] = [];
  _mOpeningBalances = new Map<string, BookBalance>();
  _mClosingBalances = new Map<string, BookBalance>();
  fileContent: string | ArrayBuffer | null = null;
  selectedFile: File | null = null;
  openingBalance: string;
  closingBalance: string;
  isResetOpeningForm = false;
  isResetClosingForm = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      closeForm: CallableFunction;
    },
    private bookService: BookService,
    private cabinetComponentService: CabinetService,
    private preferenceService: PreferenceService,
    private globalService: GlobalService,
    private injector: Injector
  ) {
    const preference = this.preferenceService.getPreferenceInstance();
    this.baseCurrency = preference.currency || GLOBAL_BASE_CURRENCY;
  }

  async ngOnInit(): Promise<void> {
    this.accountViews = await this.bookService.getBooksView();
  }

  ngOnDestroy(): void {
    this.uploading = false;
  }

  isAccountLinked(account: AccountView): boolean {
    return this.linkedAccounts.some((linkedAccount) => linkedAccount.id === account.id);
  }

  private isFromDateAfterToDate(): boolean {
    return new Date(this.toDateText) < new Date(this.fromDateText);
  }

  onDateChange(event: any, controlName: string) {
    const date = event.value;
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-CA", options);

    if (controlName === "fromDate") {
      this.fromDateText = formattedDate;
      if (this.isFromDateAfterToDate()) {
        this.toDateText = formattedDate;
        this.toPicker.select(date);
      }
    } else if (controlName === "toDate") {
      this.toDateText = formattedDate;
      if (this.isFromDateAfterToDate()) {
        this.fromDateText = formattedDate;
        this.fromPicker.select(date);
      }
    }
  }

  accountSelected(event: MatSelectChange) {
    this.accountInAction = event.value as AccountView;
    this.resetBalanceInputs();
  }

  resetBalanceInputs() {
    this.openingBalance = "";
    this.closingBalance = "";
    this.openingBalanceInput.nativeElement.value = "";
    this.closingBalanceInput.nativeElement.value = "";
  }

  resetAccountSelection() {
    this.accountInAction = null;
    this.accountSelect.value = null;
    this.resetBalanceInputs();
  }

  async linkSelectedAccount() {
    if (!this.accountInAction) {
      this.globalService.showMessage("warning", "emptyAccount", "selectAccount");
      return;
    }
    const isLinked = this.linkedAccounts.some((account) => account.id === this.accountInAction.id);
    if (!isLinked) {
      this.linkedAccounts.push(this.accountInAction);
    }
    await this.setOpeningBalanceForm();
    await this.setClosingBalanceForm();

    if (this.isResetOpeningForm && this.isResetClosingForm) {
      this.resetAccountSelection();
    }
  }

  async setOpeningBalanceForm() {
    const openingBalanceForm: BalanceForm = {
      balance: this.openingBalance,
      currency: this.baseCurrency,
      date: this.fromDateText,
      transactionStatus: TransactionStatusEnum.manual_balance,
    };

    if (!this.openingBalance || this.openingBalance.replace(/\s+/g, "") === "") {
      this._mOpeningBalances.set(this.accountInAction.id, null);
      return;
    }

    if (!this.fromDateText || this.fromDateText.replace(/\s+/g, "") === "") {
      this._mOpeningBalances.set(this.accountInAction.id, null);
      return;
    }

    const openingBalance = new BookBalance(this.accountInAction, openingBalanceForm, this.injector);
    const isReady = await openingBalance.isReady();
    this.isResetOpeningForm = !(
      openingBalance.isSameBalanceSameDate &&
      openingBalance.action !== BalanceActionEnum.keep_existing
    );
    if (isReady) {
      this._mOpeningBalances.set(this.accountInAction.id, openingBalance);
    }
  }

  async setClosingBalanceForm() {
    const closingBalanceForm: BalanceForm = {
      balance: this.closingBalance,
      currency: this.baseCurrency,
      date: this.toDateText,
      transactionStatus: TransactionStatusEnum.manual_balance,
    };

    if (!this.closingBalance || this.closingBalance.replace(/\s+/g, "") === "") {
      this._mClosingBalances.set(this.accountInAction.id, null);
      return;
    }

    if (!this.toDateText || this.toDateText.replace(/\s+/g, "") === "") {
      this._mClosingBalances.set(this.accountInAction.id, null);
      return;
    }

    const closingBalance = new BookBalance(this.accountInAction, closingBalanceForm, this.injector);
    const isReady = await closingBalance.isReady();
    this.isResetClosingForm = !(
      closingBalance.isSameBalanceSameDate &&
      closingBalance.action !== BalanceActionEnum.keep_existing
    );
    if (isReady) {
      this._mClosingBalances.set(this.accountInAction.id, closingBalance);
    }
  }

  editAccount(account: AccountView) {
    this.accountSelect.value = account;
    this.accountInAction = account;
    this.openingBalance = this._mOpeningBalances.get(account.id)?.balanceForm?.balance || "";
    this.closingBalance = this._mClosingBalances.get(account.id)?.balanceForm?.balance || "";
    this.openingBalanceInput.nativeElement.value = this.openingBalance;
    this.closingBalanceInput.nativeElement.value = this.closingBalance;
  }

  removeAccount(account: AccountView) {
    if (this.accountInAction?.id === account.id) {
      this.resetAccountSelection();
    }
    this.linkedAccounts = this.linkedAccounts.filter((acc) => acc.id !== account.id);
  }

  openingBalanceChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.openingBalance = inputElement.value;
  }

  closingBalanceChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.closingBalance = inputElement.value;
  }

  receivedSelectedFile(event: File) {
    this.selectedFile = event;
  }

  async saveUploadedFile() {
    try {
      this.uploading = true;
      const data: CabinetFileUploadedData = {
        name: this.selectedFile?.name,
        mimeType: this.selectedFile?.type,
        statementToDate: this.toDateText,
        statementFromDate: this.fromDateText,
        statementAccounts: this.linkedAccounts,
        openingBalances: this._mOpeningBalances,
        closingBalances: this._mClosingBalances,
        file: this.selectedFile,
      };

      const isSaved = await this.cabinetComponentService.saveFile(data);
      if (isSaved) {
        this.data.closeForm(isSaved);
      }
    } catch (error) {
      this.cabinetComponentService.displayCatchError(error);
    } finally {
      this.uploading = false;
    }
  }
}
