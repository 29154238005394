<div class="tw-flex tw-flex-col tw-gap-6">
  <!--        select file-->
  <div class="tw-flex tw-w-full tw-flex-col">
    <span class="tw-font-medium">{{ "selectedFile" | i18n }} *</span>
    <!--          outer-->
    <div
      class="tw-h-24 tw-w-full tw-cursor-pointer tw-rounded-xl tw-bg-neutral-50 tw-p-2 tw-shadow-inner"
      [ngClass]="{ 'tw-border-solid tw-border-danger ': errorMessage }"
      (click)="fileSelector.click()"
      (dragover)="onDragOver($event)"
      (dragleave)="onDragLeave($event)"
      (drop)="onDrop($event)"
    >
      <input
        #fileSelector
        type="file"
        id="file"
        class="form-control-file"
        name="file"
        (change)="fileSelected($event)"
        [accept]="fileTypeAllowed"
        hidden
      />
      <!--            dot line -->
      <div
        class="tw-inset-0 tw-flex tw-h-full tw-flex-col tw-items-center tw-justify-center tw-rounded-lg tw-border tw-border-dashed tw-border-neutral-300 tw-opacity-60"
        style="stroke-dasharray: 10, 20"
      >
        <span
          *ngIf="fileName; else noFile"
          class="tw-pointer-events-none tw-font-medium tw-opacity-60"
        >
          {{ fileName }}
        </span>
        <ng-template #noFile>
          <span class="tw-pointer-events-none tw-font-medium tw-underline tw-opacity-60">
            {{ "clickOrDragFile" | i18n }}
          </span>
          <span class="tw-pointer-events-none tw-opacity-60">
            {{ "fileUploadLimit" | i18n }}
          </span>
          <span class="tw-pointer-events-none tw-opacity-60">
            {{ "cabinetfileTypes" | i18n }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
  <div *ngIf="errorMessage" class="tw-text-danger">{{ errorMessage | i18n }}</div>
</div>
