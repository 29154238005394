<div class="ironfly-primary-graph">
  <app-primary-summary-graph-hud
    [dashboardParameters]="dashboardParameters"
    [graphData]="graphData"
    [scenarioData]="scenarioData"
    [selectedBand]="selectedBand"
  ></app-primary-summary-graph-hud>

  <div id="psgContainer" class="psg-container"></div>
  <div id="psgLegend" class="psg-legend">
    <app-primary-summary-graph-legend
      [dashboardParameters]="dashboardParameters"
      [scenarioData]="scenarioData"
    ></app-primary-summary-graph-legend>
  </div>
</div>
