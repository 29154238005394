import { Component, Input, OnInit } from "@angular/core";

import { InputTypes } from "../../../models/enum/inputTypes.enum";
import { GlossInputOptions } from "../../../models/types/general-types";

@Component({
  selector: "app-password-input",
  templateUrl: "./password-input.component.html",
})
export class PasswordInputComponent implements OnInit {
  @Input() options: GlossInputOptions;

  passwordVisible = false;
  passwordInputOptions: GlossInputOptions;

  ngOnInit(): void {
    this.updatePasswordInputOptions();
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
    this.updatePasswordInputOptions();
  }

  private updatePasswordInputOptions() {
    this.passwordInputOptions = {
      ...this.options,
      type: this.passwordVisible ? InputTypes.text : InputTypes.password,
    };
  }
}
