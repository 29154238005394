import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { SyncIcon } from "@bitwarden/web-vault/app/models/enum/sync.enum";
import { AccountState } from "@bitwarden/web-vault/app/models/types/general-types";

import "./sync-status-renderer.component.stye.scss";

@Component({
  selector: "app-sync-status-renderer",
  templateUrl: "./sync-status-renderer.component.html",
  styles: ["sync-status-renderer.component.scss"],
})
export class SyncStatusRendererComponent implements ICellRendererAngularComp {
  params: { data: AccountState };
  key: string;
  icon: string;

  agInit(params: { data: AccountState }): void {
    this.params = params;
    this.icon = params.data?.point?.icon;
    this.key = params.data?.point?.key;
  }

  refresh(params: any): boolean {
    return false; // No need to refresh since data fetch is already handled
  }

  protected readonly SyncIcon = SyncIcon;
}
