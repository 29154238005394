import { Component } from "@angular/core";
import { Subject, takeUntil } from "rxjs";

import { IMainProgressBar } from "../../../models/interfaces/main-progress-bar";
import { MainProgressBar } from "../../../services/progress-bar/main-progress-bar";

@Component({
  selector: "main-progress-bar",
  templateUrl: "/main-progress-bar.component.html",
})
export class MainProgressBarComponent {
  progressbar: IMainProgressBar;
  loadingPercentage: number;
  private destroy$ = new Subject<void>();

  constructor(protected mainProgressBar: MainProgressBar) {
    this.progressbar = mainProgressBar;
    this.mainProgressBar
      .getLoadingPercentage()
      .pipe(takeUntil(this.destroy$))
      .subscribe((percentage) => {
        this.loadingPercentage = percentage;
      });
  }

  getBackgroundStyle() {
    const percentage = this.loadingPercentage;
    return {
      background: `conic-gradient(#00C4C4 ${percentage * 3.6}deg, #F5F5F5 ${percentage}deg)`,
    };
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
