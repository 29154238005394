import { Injectable, Injector } from "@angular/core";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { sortTransaction } from "@bitwarden/web-vault/app/shared/utils/helper.transactions/sort";

import { BasiqIoMapper } from "../../../importers/data-mapper/mappers/basiq-io/basiq-io-mapper";
import { BasiqIoTransaction } from "../../../importers/data-mapper/mappers/basiq-io/basiq-io-transaction";
import { TransactionBasiqImporter } from "../../../importers/transaction-basiq-importer";
import { Book } from "../../../models/data/blobby/book.data";
import { Transaction } from "../../../models/data/blobby/transaction.data";
import DateFormat from "../../../shared/utils/helper.date/date-format";
import { validationResult } from "../../../validators/base-validator";
import { BasiqValidator } from "../../../validators/basiq-validator";
import { DataRepositoryService } from "../../DataRepository/data-repository.service";
import { TransactionImportService } from "../../transaction-import.service";
import { BasiqIoConfigService } from "../basiqio-config.service";

@Injectable({
  providedIn: "root",
})
export class BasiqTransactionProcessingService {
  private sortedExistingTransactions: Transaction[] = [];
  private basiqTransactions: any;
  private transactionBasiqImporter: TransactionBasiqImporter;
  constructor(
    private basiqAuth: BasiqAuth,
    private dataRepositoryService: DataRepositoryService,
    private dateFormat: DateFormat,

    private basiqValidator: BasiqValidator,
    private transactionImportService: TransactionImportService,
    private log: LogService,
    private basiqIoConfigService: BasiqIoConfigService,
    private injector: Injector
  ) {}

  async getRawBasiqTransactions(account: Book, hasTransactions = false) {
    let lastTransactionDate = null;
    if ((await this.hasTransactions()) || hasTransactions) {
      lastTransactionDate = this.getLastTransactionDate(account) || null;
    }

    await this.deletePendingTransactions(account);
    return await this.getAllTransactions(lastTransactionDate, account);
  }

  async getValidatedTransactions(
    account: Book,
    isAddAlignment = true,
    basiqTransactions: { data: BasiqIoTransaction[] }
  ) {
    try {
      this.basiqTransactions = basiqTransactions;
      const basiqMapper = new BasiqIoMapper(this.basiqTransactions.data, [account], this.injector);
      const categorized = await basiqMapper.categorizeTransactions(this.basiqTransactions.data);
      const classified = await basiqMapper.classifyTransactions(categorized);

      this.transactionBasiqImporter = this.injector.get(TransactionBasiqImporter);
      const transactionImportResult = await this.transactionBasiqImporter.parse(
        classified,
        isAddAlignment
      );
      const validatedTransactions: validationResult =
        await this.basiqValidator.matchTransactionRecord(transactionImportResult.transactions);

      validatedTransactions.newRecord.sort(sortTransaction);
      if (isAddAlignment) {
        this.transactionBasiqImporter.addAlignmentTransactions(validatedTransactions);
      }

      return validatedTransactions.newRecord;
    } catch (e) {
      this.log.info(JSON.stringify(account));
      this.log.error(e);
    }
  }

  async addTransaction(transaction: Transaction) {
    await this.transactionImportService.import([transaction], []);
  }

  async importValidatedTransactions(
    account: Book,
    isAddAlignment = true,
    basiqTransactions: { data: BasiqIoTransaction[] }
  ) {
    const validatedTransactions = await this.getValidatedTransactions(
      account,
      isAddAlignment,
      basiqTransactions
    );

    await this.transactionImportService.import(validatedTransactions, []);
  }

  async saveBasiqTransactionsOfAccount(account: Book, isAddAlignment = true) {
    try {
      this.basiqTransactions = await this.getRawBasiqTransactions(account);
      await this.importValidatedTransactions(account, isAddAlignment, this.basiqTransactions);
    } catch (e) {
      this.log.info(JSON.stringify(account));
      this.log.error(e);
    }
  }

  async deletePendingTransactions(account: Book) {
    const transactions = await this.dataRepositoryService.getAllTransactions();
    const sourceTransactions = await this.dataRepositoryService.getAllSourceTransactions();
    const pendingTransactions = transactions.filter(
      (transaction: Transaction) =>
        transaction.definition === "pending" && transaction.accountId === account.id
    );
    for (const pendingTransaction of pendingTransactions) {
      const sourceTransaction = sourceTransactions.find(
        (sourceTransaction) =>
          sourceTransaction.id === pendingTransaction.sourceId &&
          sourceTransaction.accountId === account.id
      );
      const isDeleted = await this.dataRepositoryService.deleteTransaction(pendingTransaction);
      if (isDeleted) {
        await this.dataRepositoryService.deleteSourceTransaction(sourceTransaction);
      }
    }
  }

  async hasTransactions(): Promise<boolean> {
    this.sortedExistingTransactions = await this.dataRepositoryService.getAllTransactions();
    return !!this.sortedExistingTransactions.length;
  }

  getLastTransactionDate(basiqAccountInGloss: Book) {
    const pendingTransactions = this.sortedExistingTransactions.filter(
      (transaction) =>
        transaction.accountId === basiqAccountInGloss.id &&
        transaction.definition === TransactionStatusEnum.pending
    );
    const postedTransactions = this.sortedExistingTransactions.filter(
      (transaction) =>
        transaction.accountId === basiqAccountInGloss.id &&
        transaction.definition == TransactionStatusEnum.transaction
    );

    const latestPendingAccountTransaction = pendingTransactions.length
      ? pendingTransactions[pendingTransactions.length - 1]
      : null;

    const latestPostedAccountTransaction = postedTransactions.length
      ? postedTransactions[postedTransactions.length - 1]
      : null;
    const lastPendingDate = latestPendingAccountTransaction
      ? latestPendingAccountTransaction.transactionDate.date.getTime()
      : 0;

    const lastPostedDate = latestPostedAccountTransaction
      ? latestPostedAccountTransaction.transactionDate.date.getTime()
      : 0;

    let latestAccountTransaction;

    if (lastPendingDate && lastPostedDate) {
      latestAccountTransaction = Math.min(lastPendingDate, lastPostedDate);
    } else if (lastPendingDate) {
      latestAccountTransaction = lastPendingDate;
    } else if (lastPostedDate) {
      latestAccountTransaction = lastPostedDate;
    }

    return latestAccountTransaction
      ? this.dateFormat.getDateStringFromStamp(latestAccountTransaction)
      : null;
  }

  private async getAllTransactions(lastTransactionsDate: string, account: Book) {
    const result: { data: BasiqIoTransaction[] } = { data: [] };
    let nextLoop = true;
    let nextUrlCall = null;

    while (nextLoop) {
      try {
        const transactionsResponse: any = await this.basiqIoConfigService.retrieveTransactions(
          lastTransactionsDate,
          account,
          nextUrlCall
        );

        if (!transactionsResponse) {
          throw new Error("Transactions not retrieved.");
        }

        nextUrlCall = transactionsResponse?.nextPath;
        result.data = result.data.concat(transactionsResponse.transactions);

        nextLoop = nextUrlCall;
      } catch (error) {
        nextLoop = false; // Exit the loop
      }
    }

    return result;
  }
}
