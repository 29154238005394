import { AfterViewInit, Component, DoCheck, OnDestroy, OnInit } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { DashboardParameters } from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { RoleAccessData } from "@bitwarden/web-vault/app/models/data/role-access.data";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import { ScenarioData } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper-common";

import { WizardService } from "../../components/account-wizard-stepper/wizard-stepper-service";
import { Transaction } from "../../models/data/blobby/transaction.data";
import { DashboardService } from "../../services/dashboard/dashboard-service";
import { MainProgressBar } from "../../services/progress-bar/main-progress-bar";

@Component({
  selector: "app-dash-primary",
  templateUrl: "./dash-primary.component.html",
})
export class DashPrimaryComponent implements OnInit, DoCheck, AfterViewInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private roleAccess: RoleAccessData;

  showGraph = false;
  transactions: Array<Transaction>;
  graphData: Array<GraphDataSet>;
  scenarioData: ScenarioData;
  defaultDates: Array<Date> = [];
  dashboardParameters: DashboardParameters;
  defaultGranularity: GranularityProperty;
  granularityOptions: Array<GranularityProperty>;
  showSpinner = false;
  hasNoAccounts = false;
  hasNoTransaction = false;
  isDev = false;
  hasEarlyAccess = true;
  renewWizard = false;
  stepSubscribe$: Subscription;
  hasScenarioData = false;
  wizardRenew = false;
  newBalance = false;

  constructor(
    private dashboardService: DashboardService,
    private globalService: GlobalService,
    private roleAccessService: RoleAccessService,
    private blobbyService: BlobbyService,
    protected wizardService: WizardService,
    protected mainProgressBar: MainProgressBar,
    private basicAuthService: BasiqAuth
  ) {}

  async ngOnInit(): Promise<void> {
    this.mainProgressBar.reset();
    this.mainProgressBar
      .getIsLoadingCompleted()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isLoadingCompleted) => {
        if (isLoadingCompleted) {
          this.basicAuthService.runConsentStatus();
        }
      });
    try {
      this.dashboardService.resetDashboardData();
      this.isDev = HelperCommon.isDevEnv();
      this.subscribeWizard();
      this.wizardService
        .getRenewStep()
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (renewStepBool: any) => {
            this.renewWizard = renewStepBool;
            if (renewStepBool) {
              this.wizardRenew = true;
              this.subscribeWizard();
              this.newBalance = true;
            }
          },
          error: () => this.resetState(),
          complete: () => this.resetState(),
        });
    } catch (error) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
    }
  }

  ngDoCheck() {
    this.initialiseComponent();

    if (!this.roleAccess) {
      this.roleAccess = this.roleAccessService.getRoleAccess();

      if (this.dashboardParameters) {
        this.hasEarlyAccess = this.showComingSoon();
      }
    }
  }

  initialiseComponent() {
    if (this.blobbyService.getInitialised()) {
      const itemCountInstance = this.blobbyService.getItemCountService().getItemCountInstance();
      this.hasNoAccounts = itemCountInstance.getTotalTransaction() === 0;
      this.hasNoTransaction = itemCountInstance.getTotalAccount() === 0;

      this.showGraph = !this.hasNoAccounts && !this.hasNoTransaction;
    }
  }

  async ngAfterViewInit() {
    try {
      await this.dashboardService.triggerTransactionObservables();
      await this.subscribeToDashObservables();
      this.dashboardService.isVaultPurge = false;
      await this.dashboardService.loadDashboardData();
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", "somethingWentWrong");
    }
  }

  ngOnDestroy() {
    this.stepSubscribe$.unsubscribe();
    this.dashboardService.resetDashboardData();
    this.destroy$.next();
    this.destroy$.complete();
    this.mainProgressBar.reset();
  }

  showComingSoon() {
    const scope = this.roleAccess.getScope();
    return scope.includes(RoleScope.BETA_ACCESS);
  }

  subscribeWizard() {
    this.wizardRenew = false;
    this.stepSubscribe$ = this.wizardService
      .getSteps()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (steps: any) => steps,
        error: () => this.resetState(),
        complete: async () => {
          if (!this.showGraph || this.renewWizard) {
            await this.dashboardService.refresh(false);
          }
          this.dashboardService.isVaultPurge = false;
          this.resetState();
          this.wizardService.restartWizardCompleted();
          this.dashboardService.initialiseDashboardSettings();

          const triggerTransactionObservables =
            await this.dashboardService.triggerTransactionObservables();
          const loadDashboardData = await this.dashboardService.loadDashboardData();
          Promise.resolve([triggerTransactionObservables, loadDashboardData]);
        },
      });
  }

  resetState() {
    this.wizardService.restartWizardInProgress();
    this.mainProgressBar.reset();
  }

  /**
   * subscribeToDashServices - Subscribe to the observables in the dashboard service
   */
  async subscribeToDashObservables() {
    this.dashboardService.graphData$.pipe(takeUntil(this.destroy$)).subscribe((graphData) => {
      this.graphData = this.dashboardService.isVaultPurge ? [] : graphData;
    });

    this.dashboardService.isSpinner$.pipe(takeUntil(this.destroy$)).subscribe((isSpinner) => {
      this.showSpinner = isSpinner;
    });

    this.dashboardService.scenarioData$.pipe(takeUntil(this.destroy$)).subscribe((scenarioData) => {
      this.scenarioData = this.dashboardService.isVaultPurge
        ? {
            scenario: [],
            balance: [],
          }
        : scenarioData;
      if (this.scenarioData.scenario.length > 0) {
        this.hasScenarioData = true;
      }
      this.newBalance = false;
    });

    this.dashboardService.defaultStartDate$
      .pipe(takeUntil(this.destroy$))
      .subscribe((startDate) => {
        if (startDate) {
          // note that JS only sees reassignment of array as an update, so we need to reassign the variable
          const newDefaultDates = [];
          newDefaultDates.push(new Date(startDate));
          if (this.defaultDates[1]) {
            newDefaultDates.push(this.defaultDates[1]);
          }
          this.defaultDates = newDefaultDates;
        }
      });

    this.dashboardService.defaultEndDate$.pipe(takeUntil(this.destroy$)).subscribe((endDate) => {
      if (endDate) {
        // note that JS only sees reassignment of array as an update, so we need to reassign the variable
        const newDefaultDates = [];
        if (this.defaultDates[0]) {
          newDefaultDates.push(this.defaultDates[0]);
        } else {
          newDefaultDates.push(null);
        }
        newDefaultDates.push(new Date(endDate));
        this.defaultDates = newDefaultDates;
      }
    });

    this.dashboardService.dashboardConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dashboardParameters) => {
        if (dashboardParameters) {
          this.dashboardParameters = dashboardParameters;
          this.hasEarlyAccess = ["summary", "combined"].includes(dashboardParameters.name)
            ? this.showComingSoon()
            : true;
        }
      });

    this.dashboardService.defaultGranularity$
      .pipe(takeUntil(this.destroy$))
      .subscribe((defaultGranularity) => {
        // Handle the new defaultGranularity value here
        this.defaultGranularity = defaultGranularity;
      });

    this.dashboardService.granularityOptions$
      .pipe(takeUntil(this.destroy$))
      .subscribe((granularityOptions) => {
        // Handle the new granularityOptions value here
        this.granularityOptions = granularityOptions;
      });
  }

  callFilter(data: any) {
    this.showSpinner = true;
    const { selectedAccounts, selectedDirections, selectedCategories, selectedClassifications } =
      data;
    this.dashboardService
      .filterDashboardTransactions(
        selectedAccounts,
        selectedDirections,
        selectedCategories,
        selectedClassifications
      )
      .then(() => (this.showSpinner = false));
  }

  callScenario() {
    this.dashboardService.graphScenario().then();
  }
}
