import { Component, Input, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { TransactionStatusEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";

import { Transaction } from "../../models/data/blobby/transaction.data";
import { TransactionView } from "../../models/view/transaction.view";
import { DashboardService } from "../../services/dashboard/dashboard-service";

@Component({
  selector: "app-filtered-transactions-table-card",
  templateUrl: "./filtered-transactions-table-card.component.html",
})
export class FilteredTransactionsTableCardComponent implements OnInit {
  @Input() title: string;
  private unsubscribe$ = new Subject<void>();
  private accounts: Array<Book>;
  private accountViews: AccountView[] = [];
  protected readonly RoleScope = RoleScope;

  filteredTransactions: Array<Transaction> = [];
  filteredTransactionsView: Array<TransactionView> = [];
  groupBalances: BalanceGrouping;
  mockAccounts: Array<Book>;

  constructor(
    private dashboardService: DashboardService,
    private logger: LogService,
    private bookService: BookService
  ) {}

  async ngOnInit(): Promise<void> {
    this.accounts = await this.bookService.getAll();
    this.accountViews = await this.bookService.getBooksView();

    setTimeout(() => {
      this.subscribeToDashboardService();
    }, 0);
  }

  subscribeToDashboardService() {
    this.logger.debug("FilteredTransactionsTableCardComponent: subscribeToDashObservables");
    /** Subscribe to the filtered transaction list on the Dashboard Service */
    this.dashboardService.filteredTransactions$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((filteredTransactions) => {
        if (filteredTransactions) {
          this.filteredTransactions = filteredTransactions;
          this.filteredTransactionsView = this.filteredTransactions
            .map((transaction: Transaction) => {
              const direction = transaction.direction;
              const amount = transaction.quantity?.actualQuantity?.amount;
              const valuationAmount = transaction?.valuation?.normalizedValue?.amount;
              const accountName = this.accounts.find(
                (account) => account.id === transaction.accountId
              )?.name;
              const institutionName = this.accountViews.find(
                (view) => view.originalBook.id === transaction.accountId
              )?.institution.name;
              const productName = this.accountViews.find(
                (view) => view.originalBook.id === transaction.accountId
              )?.institutionAccountType.name;

              const transactionView = new TransactionView(transaction);
              /** Assign a sortable column value */
              transactionView.accountName = accountName;
              transactionView.institutionName = institutionName;
              transactionView.productName = productName;
              transactionView.amountIn = (direction === "IN" && amount) || 0;
              transactionView.amountOut = (direction === "OUT" && amount) || 0;
              transactionView.aggregateAmount = valuationAmount || 0;

              return transactionView;
            })
            .filter(
              (transaction) => transaction.definition !== TransactionStatusEnum.manual_balance
            );
        }
      });

    /** Subscribe to groupBalances to get the granularity balance */
    this.dashboardService.groupBalances$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((groupBalances) => {
        this.groupBalances = groupBalances;
      });

    /** Subscribe to mockAccounts to get fake account names for table */
    this.dashboardService.mockAccounts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((mockAccounts) => {
        this.mockAccounts = mockAccounts;
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
