import { SelectionModel } from "@angular/cdk/collections";
import { ScrollStrategyOptions } from "@angular/cdk/overlay";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { DatePipe } from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatMenu, MatMenuTrigger } from "@angular/material/menu";
import { MatSort } from "@angular/material/sort";
import { Router } from "@angular/router";
import { actions } from "@storybook/addon-actions";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { LinkFileAccountComponent } from "@bitwarden/web-vault/app/gloss/import/linking/link-file-account/link-file-account.component";
import { LinkingNewAccountsComponent } from "@bitwarden/web-vault/app/gloss/import/linking/linking-accounts/linking-accounts.component";
import { UserChoiceDateFormatComponent } from "@bitwarden/web-vault/app/gloss/import/user-choice-date-format/user-choice-date-format.component";
import { UserChoiceOfYearComponent } from "@bitwarden/web-vault/app/gloss/import/user-choice-of-year/user-choice-of-year.component";
import { defaultMapping } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/default-mapping";
import { MappingEngine } from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine";
import {
  MappingConfigurationItem,
  Row,
} from "@bitwarden/web-vault/app/importers/data-mapper/mapping-engine-types";
import {
  ArrangeStore,
  ImportArrangeInterface,
} from "@bitwarden/web-vault/app/importers/store/arrange.import.strore";
import { ImportStore } from "@bitwarden/web-vault/app/importers/store/import.store";
import {
  ImportInstitutionsInterface,
  InstitutionStore,
} from "@bitwarden/web-vault/app/importers/store/institution.import.store";
import { ImportTypeStore } from "@bitwarden/web-vault/app/importers/store/type.import.store";
import {
  ImportUploadInterface,
  UploadStore,
} from "@bitwarden/web-vault/app/importers/store/upload.import.store";
import { TransactionCsvImporter } from "@bitwarden/web-vault/app/importers/transaction-csv-importer";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { AmountTypeEnum } from "@bitwarden/web-vault/app/models/enum/transactionType";
import { DateFormatIndex } from "@bitwarden/web-vault/app/models/types/general-types";
import {
  AmountType,
  ImportState,
  TableColumns,
} from "@bitwarden/web-vault/app/models/types/import.types";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { SourceImportService } from "@bitwarden/web-vault/app/services/DataService/source-import/source-import.service";
import { TransactionConversionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction-conversion.service";
import { TransactionImportService } from "@bitwarden/web-vault/app/services/transaction-import.service";
import HelperCsvImporter from "@bitwarden/web-vault/app/shared/utils/helper-csv-importer";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper-preference";
import { validationResult } from "@bitwarden/web-vault/app/validators/base-validator";
import { CsvValidator } from "@bitwarden/web-vault/app/validators/csv-validator";

@Component({
  selector: "app-arrange-columns-table",
  templateUrl: "./arrange-columns-table.component.html",
})
export class ArrangeColumnsTableComponent implements OnInit {
  @ViewChild("menuTrigger") menuTrigger: MatMenuTrigger;
  @ViewChild("actionButtonsTrigger") actionButtonsTrigger: MatMenuTrigger;
  @ViewChild("cardContentRef") cardContentRef: ElementRef;
  @ViewChild("table", { read: ElementRef }) dynamicTableRef: ElementRef;
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatMenu) actionButtons: MatMenu;
  @Input() records: any;
  @Output() goToPreview: EventEmitter<validationResult> = new EventEmitter<validationResult>();

  columnToOptionMap = new Map<TableColumns, MappingConfigurationItem>();
  columns: Array<any>;
  displayedColumns: Array<any>;
  dataSource: any;
  actionNeeded = false;
  accountActionNeeded = false;
  dateFormatActionNeeded = false;
  selection: SelectionModel<any>;
  noCurrencyTransactions: Transaction[];
  noSymbolTransactions: Transaction[];
  institutionCsvMap: any; // StringToString;
  showSpinner = false;
  fileHasOwnCurrency = false;
  amountType: AmountType = AmountTypeEnum.regular;
  amountTypes: string[] = [AmountTypeEnum.regular, AmountTypeEnum.reverse];
  glossTransactionHeaders = defaultMapping;
  itemSize = 30;
  private viewChecked = false;
  private fileAccountLinkDialogueRef: MatDialogRef<LinkFileAccountComponent>;
  private dialogueRef: MatDialogRef<LinkingNewAccountsComponent>;
  private userDateFormatRef: MatDialogRef<UserChoiceDateFormatComponent>;
  private userYearRef: MatDialogRef<UserChoiceOfYearComponent>;
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  protected vm$ = this.importStore.vm$;
  protected vmUpload$ = this.uploadStore.vmUpload$;
  protected vmInstitution$ = this.institutionStore.vmInstitution$;
  protected vmArrange$ = this.arrangeStore.vmArrange$;
  protected vmShowSpinner$ = this.arrangeStore.showSpinner$;
  protected arrangementStatus$ = this.importStore.arrangementStatus$;
  protected setInstitutionMapper$ = this.uploadStore.setInstitutionMapper$;
  protected readonly actions = actions;
  state: ImportState;
  uploadState: ImportUploadInterface;
  institutionState: ImportInstitutionsInterface;
  arrangeState: ImportArrangeInterface;
  csvMapper: MappingConfigurationItem[];
  constructor(
    private logService: LogService,
    private i18nService: I18nService,
    private sourceImportService: SourceImportService,
    private transactionImportService: TransactionImportService,
    private platformUtilsService: PlatformUtilsService,
    private datePipe: DatePipe,
    private router: Router,
    private formBuilder: FormBuilder,
    private transactionConversion: TransactionConversionService,
    private globalService: GlobalService,
    private renderer: Renderer2,
    public dialog: MatDialog,
    public transactionCsvImporter: TransactionCsvImporter,
    public csvValidator: CsvValidator,
    public bookService: BookService,
    public helperPreference: HelperPreference,
    protected readonly sso: ScrollStrategyOptions,
    private importStore: ImportStore,
    private readonly importTypeStore: ImportTypeStore,
    private readonly institutionStore: InstitutionStore,
    private readonly uploadStore: UploadStore,
    private readonly arrangeStore: ArrangeStore,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    this.vmArrange$.subscribe((arrangeState) => {
      this.arrangeState = arrangeState;
    });

    this.vmUpload$.pipe(takeUntil(this.destroy$)).subscribe((uploadState) => {
      this.uploadState = uploadState;
      this.vmInstitution$.pipe(takeUntil(this.destroy$)).subscribe((institutionState) => {
        if (uploadState.fileList?.length) {
          this.institutionState = institutionState;
          this.csvMapper = institutionState.selectedInstitution.csvMapper;
          this.arrangeStore.startArranging(
            uploadState,
            institutionState,
            this.arrangeState.amountType
          );
        }
      });
    });

    this.vm$.subscribe((state) => {
      this.state = state;
    });

    this.setInstitutionMapper$.subscribe((setInstoMap) => {
      if (setInstoMap) {
        if (this.uploadState.goWithDefaultMapper) {
          this.institutionState.selectedInstitution.csvMapper = defaultMapping;
          this.institutionStore.setSelectedInstitution(this.institutionState.selectedInstitution);
        }
      }
    });
  }

  onAmountTypeChange(event: any) {
    const amountType = event.value;
    // Perform any other actions based on the selected value
    this.arrangeStore.setAmountType(amountType);

    if (!this.arrangeState.accountActionNeeded) {
      this.arrangeStore.setAccountActionNeeded(true);
    }
    if (!this.arrangeState.shouldMap) {
      this.arrangeStore.setShouldMap(true);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  getAccountActionNeed(): boolean {
    let need = false;
    if (this.records) {
      if (this.records.newAccounts.length > 0) {
        need = true;
      }
    } else {
      need = true;
    }

    return need;
  }

  ngAfterViewInit() {
    this.calculateVirtualScrollHeight();
  }

  private calculateVirtualScrollHeight() {
    if (!this.viewChecked) {
      return;
    }

    // Get the height of mat-card-content
    const cardContentHeight = this.cardContentRef.nativeElement.offsetHeight;

    // Get the height of mat-table
    const tableHeight = this.dynamicTableRef.nativeElement.clientHeight;

    // Set the height of the cdk-virtual-scroll-viewport
    let virtualScrollHeight = Math.min(cardContentHeight, tableHeight + 10); // tableHeight + 10 to avoid the scroll bar for smaller data
    virtualScrollHeight = Math.max(virtualScrollHeight, this.itemSize * 4); // Set a minimum height of 4 times the itemSize

    // If the table height is smaller than the mat-card-content height, adjust the mat-card-content height
    if (tableHeight < cardContentHeight) {
      this.renderer.setStyle(this.cardContentRef.nativeElement, "height", `${tableHeight}px`);
      this.renderer.removeClass(this.cardContentRef.nativeElement, "fill-available-height");
    } else {
      // Otherwise, reset the mat-card-content height to its original value
      this.renderer.removeStyle(this.cardContentRef.nativeElement, "height");
      this.renderer.addClass(this.cardContentRef.nativeElement, "fill-available-height");
    }

    // Convert height to the number of virtual scroll items (rounding up to the nearest whole number)
    const numItems = Math.ceil(virtualScrollHeight / this.itemSize);
    this.virtualScroll.setTotalContentSize(numItems * this.itemSize);

    // Set the height of the cdk-virtual-scroll-viewport
    this.renderer.setStyle(
      this.virtualScroll.elementRef.nativeElement,
      "height",
      `${virtualScrollHeight}px`
    );

    // Recalculate the visible range
    this.virtualScroll.checkViewportSize();
  }

  @HostListener("window:resize", ["$event"])
  onWindowResize(event: Event) {
    // Re-calculate the virtual scroll height when the window is resized
    this.calculateVirtualScrollHeight();
  }

  /** At this stage the file rows and userDateFormatIndex is defined either when uploading file or by setting date format manually */
  async takeAccountAction() {
    if (this.arrangeState.shouldMap) {
      this.uploadStore
        .setImportDataBeforeArrangement(this.institutionState.selectedInstitution, this.uploadState)
        .then(() => {
          this.arrangeStore
            .startArranging(this.uploadState, this.institutionState, this.arrangeState.amountType)
            .then(() => {
              this.openLinkDialogue(this.arrangeState.records);
            });
        });
    } else {
      await this.openLinkDialogue(this.arrangeState.records);
    }
  }

  async beforeMenuOpens(event: Event) {
    this.arrangeStore.setShowSpinner(true);

    setTimeout(async () => {
      if (this.arrangeState.shouldMap) {
        const helperCsvImporter = new HelperCsvImporter();
        const mapping = new MappingEngine(this.csvMapper);
        const { possibleDateFormats, csvResults, dateFormatIndex } =
          helperCsvImporter.getInfoFromCsv(this.uploadState.fileContent, null, mapping);

        if (!Object.prototype.hasOwnProperty.call(csvResults[0], "date")) {
          this.globalService.showErrorMessage("errorOccurred", "pleaseSelectADateColumnFirst");
          this.actionButtonsTrigger.closeMenu();
          this.arrangeStore.setShowSpinner(false);
          return;
        }

        this.uploadStore.setUserDateFormatIndex(dateFormatIndex);
        this.uploadStore.setPossibleDateFormats(possibleDateFormats);

        await this.uploadStore.setImportDataBeforeArrangement(
          this.institutionState.selectedInstitution,
          this.uploadState
        );
        await this.arrangeStore.startArranging(
          this.uploadState,
          this.institutionState,
          this.arrangeState.amountType
        );
      }
      this.arrangeStore.setShowSpinner(false);
    }, 500);
  }

  async takeDateFormatAction() {
    if (this.arrangeState.shouldMap) {
      const helperCsvImporter = new HelperCsvImporter();
      const mapping = new MappingEngine(this.csvMapper);
      const { csvResults } = helperCsvImporter.getInfoFromCsv(
        this.uploadState.fileContent,
        null,
        mapping
      );

      if (!Object.prototype.hasOwnProperty.call(csvResults[0], "date")) {
        this.globalService.showErrorMessage("errorOccurred", "pleaseSelectADateColumnFirst");
        return;
      }

      await this.uploadStore.setImportDataBeforeArrangement(
        this.institutionState.selectedInstitution,
        this.uploadState
      );
    }
    if (
      !this.institutionState.selectedInstitution.csvMapper.some(
        (mappingConfigItem) => mappingConfigItem.key === "date"
      ) ||
      !Object.prototype.hasOwnProperty.call(this.uploadState.fileRows[0], "date")
    ) {
      this.globalService.showErrorMessage("errorOccurred", "pleaseSelectADateColumnFirst");
      return;
    }

    if (this.arrangeState.shouldMap) {
      this.uploadStore
        .setImportDataBeforeArrangement(this.institutionState.selectedInstitution, this.uploadState)
        .then(() => {
          this.arrangeStore
            .startArranging(this.uploadState, this.institutionState, this.arrangeState.amountType)
            .then(() => {
              const data = {
                possibleDateFormats: this.uploadState.possibleDateFormats,
                csvResults: this.uploadState.fileRows,
              };

              this.openUserDateFormatDialogue(data);
            });
        });
    } else {
      const data = {
        possibleDateFormats: this.uploadState.possibleDateFormats,
        csvResults: this.uploadState.fileRows,
      };
      if (this.uploadState.possibleDateFormats && this.uploadState.possibleDateFormats.length > 1) {
        await this.openUserDateFormatDialogue(data);
      }
    }
  }

  async takeYearAction() {
    await this.openUserYearDialogue();
  }

  isValidDate(value: string): boolean {
    return !isNaN(Date.parse(value));
  }
  isColumnADate(column: TableColumns, selectedOption: MappingConfigurationItem) {
    const originalHeader = column.originalHeader;
    for (const csvRow of this.uploadState.fileRows) {
      if (!this.isValidDate(csvRow[originalHeader])) {
        return false;
      }
    }

    return true;
  }
  async handleHeaderChange(event: {
    column: TableColumns;
    selectedOption: MappingConfigurationItem;
  }) {
    const { column, selectedOption } = event;
    column.lastKey = selectedOption.key;
    this.csvMapper = this.institutionState.selectedInstitution.csvMapper.map((mappingItem) => {
      mappingItem.mapping = mappingItem.mapping.filter(
        (mappingItemName) =>
          mappingItemName !== column.columnDef && mappingItemName !== column.columnDef.toLowerCase()
      );

      if (mappingItem.key === selectedOption.key) {
        const keyExists = mappingItem.mapping.find(
          (mappingItemName) =>
            mappingItemName === column.columnDef ||
            mappingItemName === column.columnDef.toLowerCase()
        );
        if (!keyExists) {
          mappingItem.mapping.push(column.columnDef);
        }
      }

      return mappingItem;
    });

    if (!this.csvMapper.some((mapping) => mapping.key === selectedOption.key)) {
      selectedOption.mapping.push(column.columnDef);
      this.csvMapper = [...this.csvMapper, selectedOption];
    }

    /*
    this.institutionState.selectedInstitution.csvMapper = institutionHeadersMapping;
    this.institutionStore.setSelectedInstitution(this.institutionState.selectedInstitution);
    this.arrangeStore.setHeadersMapping(institutionHeadersMapping);
*/

    if (this.uploadState.userDateFormatIndex) {
      this.uploadStore.setUserDateFormatIndex(null);
    }
    if (!this.arrangeState.accountActionNeeded) {
      this.arrangeStore.setAccountActionNeeded(true);
    }
    if (!this.arrangeState.shouldMap) {
      this.arrangeStore.setShouldMap(true);
    }

    // await this.uploadStore.setImportDataBeforeArrangement(this.institutionState.selectedInstitution, this.uploadState.fileContent)

    // await this.arrangeStore.setMappingConfig( this.uploadState, this.institutionState)
    //this.institutionCsvMap[column.originalHeader] = selectedOption;
  }

  /** Helps filter out based on categories' names as well*/
  customFilterPredicate(data: any, filter: string): boolean {
    const lowerCaseFilter = filter.trim().toLowerCase();

    // Search through all columns, including nested properties
    for (const column of this.displayedColumns) {
      const columnValue = this.getColumnValue(data, column);
      if (columnValue && columnValue.toLowerCase().includes(lowerCaseFilter)) {
        return true;
      }
    }
    return false;
  }

  /** since category-renderer values are objects we should stringify them to compare to filter string*/
  getColumnValue(data: any, column: string): string | null {
    const properties: string[] = column.split("."); // Handle nested properties
    let value = data;
    for (const prop of properties) {
      value = value[prop];
      if (!value) {
        return null;
      }
    }
    return JSON.stringify(value);
  }

  /*todo search for a more material ui filter , a more efficient and native one */
  applyFilter(event: Event) {
    this.dataSource.filter = (event.target as HTMLInputElement).value;
  }

  getActionsNeeded() {
    return this.accountActionNeeded || this.dateFormatActionNeeded;
  }

  async next() {
    this.arrangeStore.setShowSpinner(true);

    setTimeout(async () => {
      if (this.arrangeState.shouldMap) {
        await this.uploadStore.setImportDataBeforeArrangement(
          this.institutionState.selectedInstitution,
          this.uploadState
        );
        await this.arrangeStore.startArranging(
          this.uploadState,
          this.institutionState,
          this.arrangeState.amountType
        );
      }
      const canProcess = this.arrangeStore.canProcess(this.arrangeState);
      if (canProcess) {
        const institution = this.institutionState.selectedInstitution;
        institution.csvMapper = this.arrangeState.headersMapping;
        this.institutionStore.setSelectedInstitution(institution);
        this.importStore.setBalanceAlignment(
          this.arrangeState,
          this.institutionState.selectedInstitution.balanceAlignmentMapper
        );
        this.importStore.goToImport();
      }
      this.arrangeStore.setShowSpinner(false);
    }, 500);
  }

  previous() {
    this.uploadStore.reset();
    this.importStore.gotBackToUpload();
  }

  async openLinkDialogue(records: validationResult) {
    const dialogRef = this.dialog.open(LinkingNewAccountsComponent, {
      width: "800px",
      disableClose: true,
      data: {
        records: records,
        noCurrencyTransactions: this.arrangeState.noCurrencyTransactions,
        noSymbolTransactions: this.arrangeState.noSymbolTransactions,
        actionSucceeded: this.actionSucceeded.bind(this),
        institution: this.institutionState.selectedInstitution,
      },
    });

    this.dialogueRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((records: validationResult) => {
        if (records) {
          this.arrangeStore.setRecordsAfterAccountAction(records);
        }
      });
  }

  async openUserDateFormatDialogue(data: { possibleDateFormats: string[]; csvResults: any[] }) {
    const { possibleDateFormats } = data;
    if (!possibleDateFormats || possibleDateFormats?.length < 2) {
      this.globalService.showWarningMessage("warning", "dateFormatAlreadySet");
      return;
    }
    const dialogRef = await this.dialog.open(UserChoiceDateFormatComponent, {
      width: "800px",
      // todo maxHeight does not work for some reason. hardcoded it in the linking-accounts.component.html
      maxHeight: "85vh",
      disableClose: true,
      scrollStrategy: this.sso.block(),

      data: {
        possibleDateFormats: possibleDateFormats,
        formatSelected: this.formatSelected.bind(this),
      },
    });

    this.userDateFormatRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((dateFormat: string) => {
        if (dateFormat) {
          const userDateFormatIndex = this.getDateFormatIndexByString(dateFormat);
          this.uploadStore.setUserDateFormatIndex(userDateFormatIndex);
        }
      });
  }

  async openUserYearDialogue() {
    const dialogRef = this.dialog.open(UserChoiceOfYearComponent, {
      width: "800px",
      // todo maxHeight does not work for some reason. hardcoded it in the linking-accounts.component.html
      maxHeight: "85vh",
      disableClose: true,
      scrollStrategy: this.sso.block(),
      data: {
        setTheYear: this.setTheYear.bind(this),
      },
    });

    this.userYearRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((year: string) => {
        if (year) {
          const todayStamp = new Date().getTime();
          let allGood = true;
          const newFileRows: Row[] = [];
          for (let i = 0; i < this.uploadState.fileRows.length; i++) {
            const row = this.uploadState.fileRows[i];
            const newRowDate = row.date + this.uploadState.userDateFormatIndex.separator + year;
            const rowDateStamp = new Date(newRowDate).getTime();
            if (rowDateStamp > todayStamp) {
              allGood = false;
              break;
            }
            row.date = newRowDate;
            newFileRows.push(row);
          }

          if (allGood) {
            this.uploadStore.setUserDateFormatIndex({
              ...this.uploadState.userDateFormatIndex,
              year: 2,
            });
            this.uploadStore.setFileRows(newFileRows);
            this.arrangeStore.startArranging(
              this.uploadState,
              this.institutionState,
              this.amountType
            );
          } else {
            this.globalService.showErrorMessage("errorOccurred", "someTransactionsExceedToday");
          }
        }
      });
  }

  setTheYear(year: string) {
    this.userYearRef?.close(year);
  }

  getDateFormatIndexByString(dateFormatString: string): DateFormatIndex {
    // @sinan yes this is buggy. We need to select the dateformat from the UI and not convert it back to a string and so.
    //
    // @sinan todo test that I'm not sure it work
    // debugger
    const parts = dateFormatString.split(/\w+/);
    const datePartsMap = new Map<string, number>();
    const separator = parts[1];

    const dateParts = dateFormatString.split(separator);
    dateParts.forEach((dp, index) => {
      datePartsMap.set(dp.charAt(0), index);
    });

    return {
      year: datePartsMap.get("Y"),
      month: datePartsMap.get("M"),
      day: datePartsMap.get("D"),
      separator,
      format: "",
    };
  }
  async formatSelected(userDateFormat: string) {
    this.userDateFormatRef?.close(userDateFormat);
  }

  async actionSucceeded(records: validationResult) {
    this.dialogueRef?.close(records);
  }

  async fileAccountSelected(fileAccount: Book) {
    this.fileAccountLinkDialogueRef?.close(fileAccount);
  }
}
