import { AbstractControl, FormControl } from "@angular/forms";

export class HelperNumericInput {
  static onInput(event: Event, formControl: AbstractControl) {
    // Get the input element and its value
    const inputElement = event.target as HTMLInputElement;
    const inputValue = inputElement.value;
    const isNegative =
      inputValue.startsWith("-") && inputValue.indexOf("-") === inputValue.lastIndexOf("-");
    // Remove non-numeric characters using regular expressions
    let numericValue = inputValue.replace(/[^0-9.]/g, "");

    // Ensure there is only one decimal point
    const decimalCount = numericValue.split(".").length - 1;
    if (decimalCount > 1) {
      // More than one decimal point, remove the extra ones
      numericValue = numericValue.slice(0, numericValue.lastIndexOf("."));
    }

    numericValue = isNegative ? `-${numericValue}` : numericValue;
    // Set the filtered value back into the input
    inputElement.value = numericValue;

    // Update the form control with the filtered numeric value
    formControl.setValue(numericValue);
  }

  static clearDefaultValue(formControl: AbstractControl) {
    if (formControl.value === 0) {
      formControl.setValue("");
    }
  }

  static isNumberValidator(control: FormControl) {
    const value = control.value;
    if (isNaN(value)) {
      return { notANumber: true };
    }
    return null;
  }
}
