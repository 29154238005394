import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatCheckboxChange } from "@angular/material/checkbox";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { EstimateGroupAddEditComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimate-group-add-edit/estimate-group-add-edit.component";
import { EstimatesAddEditComponent } from "@bitwarden/web-vault/app/gloss/estimates/estimates-add-edit/estimates-add-edit.component";
import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { EstimateResponse } from "@bitwarden/web-vault/app/models/data/response/estimate.response";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { EstimateService } from "@bitwarden/web-vault/app/services/DataService/estimates/estimate-service";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";

@Component({
  selector: "app-estimates",
  templateUrl: "./estimates.component.html",
})
export class EstimatesComponent implements OnInit, OnDestroy {
  protected check: Record<string, boolean> = {};
  loading = false;
  estimateGroups: Estimate[];
  selectedEstimates: Estimate[] = [];
  selectedGroup: Estimate;
  isEditMode = false;
  isEditGroup = false;
  isNewGroup = true;
  displayedColumns: string[] = [
    "checkBox",
    "name",
    "categories",
    "classifications",
    "account",
    "direction",
    "period",
    "value",
    "edit",
  ];
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  private dialogueRef: MatDialogRef<EstimatesAddEditComponent>;
  private groupDialogueRef: MatDialogRef<EstimateGroupAddEditComponent>;

  dataSource: MatTableDataSource<Estimate>;
  estimates: Estimate[] = [];

  constructor(
    public dialog: MatDialog,
    private estimateService: EstimateService,
    private globalService: GlobalService,
    private dataRepoService: DataRepositoryService,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  isActionButtonEnabled() {
    return this.selectedEstimates.length > 0;
  }

  get destroyObservable() {
    return this.destroy$.asObservable();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }
  async ngOnInit(): Promise<void> {
    try {
      await this.setTable();
      this.estimateGroups = await this.dataRepoService.getAllEstimateGroups();
      this.selectedGroup = this.estimateGroups.length > 0 ? this.estimateGroups[0] : null;
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }

  selectEstimate(estimate: Estimate) {
    this.selectedEstimates.push(estimate);
  }

  deselectEstimate(estimate: Estimate) {
    this.selectedEstimates = this.selectedEstimates.filter((item) => item.id !== estimate.id);
  }
  toggleCheckBox(event: MatCheckboxChange, item: Estimate) {
    if (event.checked) {
      this.selectEstimate(item);
    } else {
      this.deselectEstimate(item);
    }
  }
  async setTable() {
    this.estimates = await this.estimateService.getAllEstimates();
    this.dataSource = new MatTableDataSource<Estimate>(this.estimates);
  }

  async openEstimateForm(estimate: Estimate) {
    this.isEditMode = !!estimate;

    const dialogRef = this.dialog.open(EstimatesAddEditComponent, {
      width: "800px",
      disableClose: true,
      data: {
        isEditMode: this.isEditMode,
        estimate,
        actionSucceeded: this.actionSucceeded.bind(this),
        delete: null,
      },
    });

    this.dialogueRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((estimateGroup) => {
        if (estimateGroup) {
          this.selectedGroup = estimateGroup;
        }
      });
  }

  async deleteEstimate() {
    const confirmed = await this.confirmationDialogService.confirmFor(
      ConfirmationEnum.deletingEstimate
    );
    if (confirmed) {
      this.loading = true;
      try {
        const deleted = await this.estimateService.bulkDelete(this.selectedEstimates);
        if (deleted) {
          await this.actionSucceeded({
            message: "deletedSuccessfully",
            data: new Estimate(new EstimateResponse("")),
          });
        } else {
          await this.actionFailed("notDeleted");
        }
      } catch (e) {
        this.globalService.showErrorMessage("errorOccurred", e);
      } finally {
        this.loading = false;
      }
    }
  }

  /*  // TODO how to behave when there are estimates in the group
  async deleteGroup(estimateGroup: Estimate, index?: number) {
    const confirmed = await this.globalService.showRemoveDialogue();
    if (confirmed) {
      this.loading = true;
      try {
        // we create a new EstimateDate cuz when it destructure it from the estimateGroup.estimates array it sees it as an Object not a an EstimateActionData
        const theEstimateOfType = new Estimate(new EstimateResponse(estimateGroup));
        const estimatesDeleted = await this.estimateService.deleteEstimatesOfGroup(estimateGroup);
        const deleted = await this.estimateService.deleteEstimateGroup(theEstimateOfType, false);
        if (deleted) {
          let nextIndex;
          if (this.estimateGroups.length > 1) {
            nextIndex = index === this.estimateGroups.length - 1 ? 0 : index + 1;
          }
          if (estimatesDeleted) {
            await this.actionSucceeded({
              message: "deletedSuccessfully",
              data:
                this.estimateGroups.length > 1
                  ? this.estimateGroups[nextIndex]
                  : new Estimate(new EstimateResponse("")),
            });
          } else {
            await this.actionSucceeded({
              message: "deletedGroupNotEstimates",
              data:
                this.estimateGroups.length > 1
                  ? this.estimateGroups[nextIndex]
                  : new Estimate(new EstimateResponse("")),
            });
          }
        } else {
          await this.actionFailed("notDeleted");
        }
      } catch (e) {
        this.globalService.showErrorMessage("errorOccurred", e);
      } finally {
        this.loading = false;
      }
    }
  }*/
  async actionSucceeded(eventData: { message: string; data?: Estimate }) {
    await this.setTable();
    const { message, data } = eventData;
    this.estimateGroups = await this.dataRepoService.getAllEstimateGroups();
    this.dialogueRef?.close(data);
    this.groupDialogueRef?.close(data);
    this.globalService.showSuccessMessage("succeeded", message);

    if (data) {
      this.selectedGroup = data;
    }
  }

  async actionFailed(actionKey: string) {
    this.dialogueRef?.close(this.estimates);
    this.globalService.showErrorMessage("errorOccurred", actionKey);
  }
}
