import { Estimate } from "@bitwarden/web-vault/app/models/data/blobby/estimate.data";
import { Scenario } from "@bitwarden/web-vault/app/models/data/blobby/scenario.data";
import { SourceBook } from "@bitwarden/web-vault/app/models/data/blobby/source-book";
import { SourceCategory } from "@bitwarden/web-vault/app/models/data/blobby/source-category";
import { VaultFile } from "@bitwarden/web-vault/app/models/data/blobby/vault-file.data";
import { Wizard } from "@bitwarden/web-vault/app/models/data/blobby/wizard.data";
import { ScenarioGroup } from "@bitwarden/web-vault/app/models/data/scenario-group.data";
import { UserLocation } from "@bitwarden/web-vault/app/models/data/user-location";

import { Book } from "../data/blobby/book.data";
import { Category } from "../data/blobby/category.data";
import { Classification } from "../data/blobby/classification.data";
import { Connector } from "../data/blobby/connector.data";
import { EstimateActionData } from "../data/blobby/estimate.action.data";
import { Institution } from "../data/blobby/institution.data";
import { Preference } from "../data/blobby/preference.data";
import { ReferenceData } from "../data/blobby/reference-data.data";
import { SourceTransaction } from "../data/blobby/source-transaction.data";
import { Transaction } from "../data/blobby/transaction.data";
import { ImportHandler } from "../data/import-handler.data";
import { SymbolInfoData } from "../data/symbol-info.data";
import { SymbolData } from "../data/symbol.data";

export type GlossEncryptedDataType =
  | Category
  | ReferenceData
  | Classification
  | Institution
  | Transaction
  | Preference
  | Book
  | EstimateActionData
  | Estimate
  | SymbolInfoData
  | SymbolData
  | SourceTransaction
  | SourceBook
  | ImportHandler
  | Scenario
  | SourceCategory
  | ScenarioGroup
  | Connector
  | VaultFile
  | UserLocation
  | Wizard;

export type DataServiceAbstractionTypeArray = GlossEncryptedDataType[];
export type crudFlagDataType = crudFlag.create | crudFlag.update | crudFlag.delete;
export enum crudFlag {
  create = "create",
  update = "update",
  delete = "delete",
}
