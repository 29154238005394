import { ScrollStrategyOptions } from "@angular/cdk/overlay";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { ImportError } from "@bitwarden/common/importers/import-error";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { InstitutionsAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/institutions-add-edit/institutions-add-edit.component";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { TransactionImportType } from "@bitwarden/web-vault/app/models/enum/transactionImportType";
import { DateFormatIndex, ImportSteps } from "@bitwarden/web-vault/app/models/types/general-types";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper-preference";
import { validationResult } from "@bitwarden/web-vault/app/validators/base-validator";

import HelperCsvImporter from "../../shared/utils/helper-csv-importer";

@Component({
  selector: "app-import",
  templateUrl: "./import-csv.component.html",
})
export class ImportCsvComponent implements OnInit {
  loading: boolean;
  isDataValidated = false;
  fileSelected: File;
  formPromise: Promise<ImportError>;
  format: TransactionImportType = null;
  records: validationResult;
  fileRows: any[];
  competedSteps: ImportSteps[] = [];
  userDateFormatIndex: DateFormatIndex;
  step: ImportSteps = "institution";
  institutions: Institution[];
  selectedInstitution: Institution;
  completedStep: ImportSteps;
  possibleDateFormats: string[];

  protected organizationId: string = null;
  private institutionDialogueRef: MatDialogRef<InstitutionsAddEditComponent>;
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    public dialog: MatDialog,
    protected i18nService: I18nService,
    protected platformUtilsService: PlatformUtilsService,
    private logService: LogService,
    private institutionService: InstitutionService,
    protected globalService: GlobalService,
    protected bookService: BookService,
    protected helperPreference: HelperPreference,
    protected readonly sso: ScrollStrategyOptions,
    private confirmationDialogService: ConfirmationDialogService
  ) {}

  async ngOnInit() {
    this.loading = false;
    this.institutions = await this.institutionService.getAll();
    if (this.institutions.length) {
      this.selectedInstitution = this.institutions[0];
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  async newInstitutionCreated(actionMessage: string) {
    this.institutions = await this.institutionService.getAll();
    this.institutionDialogueRef?.close(this.institutions);
    this.globalService.showSuccessMessage("succeeded", actionMessage);
  }

  async gotToArrangement(event: Event) {
    event.preventDefault();
    this.loading = true;
    const fileEl = document.getElementById("file") as HTMLInputElement;
    const files = fileEl.files;

    if (files == null || files.length === 0) {
      this.platformUtilsService.showToast(
        "error",
        this.i18nService.t("errorOccurred"),
        this.i18nService.t("selectFile")
      );
      this.loading = false;
      return;
    }

    if (!files[0].name.endsWith(".csv")) {
      this.globalService.showErrorMessage("errorOccurred", "pleaseSelectACsvFile");
      return;
    }

    const helperCsvImporter = new HelperCsvImporter();
    const content = await helperCsvImporter.getFileContents(files[0]);
    let csvMapper = null;

    if (this.selectedInstitution.csvMapper && this.selectedInstitution.csvMapper[0]) {
      const headers = await helperCsvImporter.getCsvHeaders(content);
      const keysOriginalHeader = Object.keys(headers);
      const keysCsvMapper = Object.keys(this.selectedInstitution.csvMapper[0]);

      const hasAllKeys = keysOriginalHeader.every((key) => keysCsvMapper.includes(key));
      if (!hasAllKeys) {
        const goWithDefault = await this.confirmationDialogService.confirmFor(
          ConfirmationEnum.usingDefaultInstitution
        );
        if (!goWithDefault) {
          return;
        }
      } else {
        csvMapper = this.selectedInstitution.csvMapper[0];
      }
    }

    const { dateFormatIndex, csvResults, possibleDateFormats } = helperCsvImporter.getInfoFromCsv(
      content,
      csvMapper
    );
    this.fileRows = csvResults;
    this.userDateFormatIndex = dateFormatIndex;
    this.possibleDateFormats = possibleDateFormats;

    this.step = "arrange";
    this.completedStep = "upload";
    this.competedSteps.push(this.completedStep);
  }

  async goToPreview(records: validationResult) {
    this.loading = true;

    try {
      this.step = "import";
      this.completedStep = "arrange";
      this.competedSteps.push(this.completedStep);
      this.records = records;
      this.isDataValidated = true;
      /*await this.processAfterDateFormat(csvResults, this.userDateFormatIndex);*/
    } catch (e) {
      this.logService.error(e);
    }
  }

  setSelectedFile(event: Event) {
    const fileInputEl = <HTMLInputElement>event.target;
    this.fileSelected = fileInputEl.files.length > 0 ? fileInputEl.files[0] : null;
  }

  // close preview table on clicking the discard button on preview-table
  handleCloseTable() {
    this.isDataValidated = false;
    this.step = "institution";
    this.competedSteps = [];
    this.fileSelected = null;
  }
}
