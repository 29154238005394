import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  selector: "accordion-component",
  standalone: true,
  templateUrl: "./accordion.component.html",
  imports: [CommonModule],
})
export class AccordionComponent {
  @Input() set id(value: string) {
    this._id = "#" + value;
  }
  @Input() title = "";
  @Output() accordionClicked = new EventEmitter<void>();
  protected isCollapsed = false;
  protected _id: string;

  onAccordionClicked() {
    this.isCollapsed = !this.isCollapsed;
    this.accordionClicked.emit();
  }
}
