import { ScrollStrategyOptions } from "@angular/cdk/overlay";
import { Component, OnInit } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { ImportError } from "@bitwarden/common/importers/import-error";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { InstitutionsAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-institutions/institutions-add-edit/institutions-add-edit.component";
import { alignmentDefaultMapping } from "@bitwarden/web-vault/app/importers/data-mapper/mappers/default-mapping";
import { ImportStore } from "@bitwarden/web-vault/app/importers/store/import.store";
import { InstitutionStore } from "@bitwarden/web-vault/app/importers/store/institution.import.store";
import { ImportTypeStore } from "@bitwarden/web-vault/app/importers/store/type.import.store";
import {
  ImportUploadInterface,
  UploadStore,
} from "@bitwarden/web-vault/app/importers/store/upload.import.store";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import {
  ImportStepsEnum,
  TransactionImportType,
} from "@bitwarden/web-vault/app/models/enum/transactionImportType";
import { DateFormatIndex, ImportSteps } from "@bitwarden/web-vault/app/models/types/general-types";
import { ImportType } from "@bitwarden/web-vault/app/models/types/import.types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper-preference";
import { validationResult } from "@bitwarden/web-vault/app/validators/base-validator";

@Component({
  selector: "app-csv-import",
  templateUrl: "./csv-import.component.html",
})
export class CsvImportComponent implements OnInit {
  loading: boolean;
  showSpinner = false;
  isDataValidated = false;
  fileSelected: File;
  formPromise: Promise<ImportError>;
  format: TransactionImportType = null;
  records: validationResult;
  fileRows: any[];
  competedSteps: ImportSteps[] = [];
  userDateFormatIndex: DateFormatIndex;
  step: ImportSteps = "institution";
  institutions: Institution[];
  completedStep: ImportSteps;
  possibleDateFormats: string[];
  selectedInstitution: Institution;
  /*TODO make this one a type */
  institutionState: {
    selectedInstitution: Institution;
    institutions: Institution[];
  };

  uploadState: ImportUploadInterface;
  importType: ImportType;
  /*TOOD find a way to set a type for state*/
  state: any;
  protected organizationId: string = null;
  private institutionDialogueRef: MatDialogRef<InstitutionsAddEditComponent>;
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  readonly vm$ = this.importStore.vm$;
  readonly fileList$ = this.uploadStore.fileList$;
  readonly importType$ = this.importTypeStore.importType$;
  readonly vmInstitution$ = this.institutionStore.vmInstitution$;
  readonly vmUpload$ = this.uploadStore.vmUpload$;
  readonly goWithDefaultMapper$ = this.uploadStore.goWithDefaultMapper$;
  constructor(
    public dialog: MatDialog,
    protected i18nService: I18nService,
    protected platformUtilsService: PlatformUtilsService,
    private logService: LogService,
    protected globalService: GlobalService,
    protected bookService: BookService,
    protected dataRepositoryService: DataRepositoryService,
    protected helperPreference: HelperPreference,
    protected readonly sso: ScrollStrategyOptions,
    private readonly importStore: ImportStore,
    private readonly importTypeStore: ImportTypeStore,
    private readonly institutionStore: InstitutionStore,
    private readonly uploadStore: UploadStore
  ) {}

  async ngOnInit() {
    /*todo @sinan can we do this too in the store level ?*/

    this.vmInstitution$.pipe(takeUntil(this.destroy$)).subscribe((institutionState) => {
      this.institutionState = institutionState;
      if (!institutionState.selectedInstitution) {
        this.institutionStore.setInitialInstitution();
      }
    });
    this.importType$.pipe(takeUntil(this.destroy$)).subscribe((importType) => {
      this.importType = importType;
    });

    this.vmUpload$.pipe(takeUntil(this.destroy$)).subscribe((uploadState) => {
      this.uploadState = uploadState;
    });

    this.vm$.pipe(takeUntil(this.destroy$)).subscribe((state) => {
      this.state = state;
    });

    /*TODO find a way to listen to fileList changes in vm$*/
    this.fileList$.subscribe((fileList) => {
      if (fileList) {
        this.uploadStore.arrangeFileContent(fileList);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  onInstitutionSelected(event: MatSelectChange): void {
    event.value.balanceAlignmentMapper = event.value.balanceAlignmentMapper
      ? event.value.balanceAlignmentMapper
      : alignmentDefaultMapping;
    this.institutionStore.setSelectedInstitution(event.value);
  }
  async gotToUpload(event: Event) {
    event.preventDefault();
    this.importStore.goToUpload(this.institutionState);
  }

  async createNewInstitution() {
    const dialogRef = this.dialog.open(InstitutionsAddEditComponent, {
      width: "800px", // for mat-dialog-container, built in max-width: 80vw; so this width works for smaller screens too
      data: {
        actionSucceeded: this.newInstitutionCreated.bind(this),
      },
    });

    this.institutionDialogueRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dataRepositoryService.getAllInstitutions().then((institutions) => {
          this.institutionStore.setSelectedInstitution(institutions[institutions.length - 1]);
        });
      });
  }

  async newInstitutionCreated(actionMessage: string) {
    await this.institutionStore.setAllInstitutions();
    this.institutionDialogueRef?.close();
    this.globalService.showSuccessMessage("succeeded", actionMessage);
  }

  async gotToArrangement(event: Event) {
    event.preventDefault();
    this.showSpinner = true;
    setTimeout(async () => {
      try {
        if (this.uploadState.canProceed && !this.uploadState.error) {
          await this.uploadStore.setImportDataBeforeArrangement(
            this.institutionState.selectedInstitution,
            this.uploadState
          );
          this.showSpinner = false;
          if (this.uploadState.fileContent && this.uploadState.fileContent !== "") {
            this.importStore.gotToArrangement();
          }
        } else {
          this.showSpinner = false;
          this.uploadStore.setError({ titleKey: "errorOccurred", textKey: "selectFile" });
        }
      } catch (e) {
        this.showSpinner = false;
        this.uploadStore.setError({ titleKey: "errorOccurred", textKey: "somethingWentWrong" });
      }
    }, 500);
  }

  async goToPreview(records: validationResult) {
    this.loading = true;

    try {
      this.step = "import";
      this.completedStep = "arrange";
      this.competedSteps.push(this.completedStep);
      this.records = records;
      this.isDataValidated = true;
      /*await this.processAfterDateFormat(csvResults, this.userDateFormatIndex);*/
    } catch (e) {
      this.logService.error(e);
    }
  }

  async goBAckToInstitution() {
    this.uploadStore.reset();
    this.importStore.gotBackToInstitution();
  }

  async goBackToMainImportSection() {
    this.importStore.resetImport();
  }

  async goBackToArrange() {
    this.step = "arrange";
    this.competedSteps.pop();
  }

  async setSelectedFile(event: Event) {
    const fileInputEl = <HTMLInputElement>event.target;
    await this.uploadStore.fileSelected(
      this.institutionState.selectedInstitution,
      fileInputEl.files
    );
    /*    this.goWithDefaultMapper$.pipe(takeUntil(this.destroy$)).subscribe((goWithDefaultMapper) => {
      if(goWithDefaultMapper){
        this.institutionState.selectedInstitution.csvMapper = null
        this.institutionStore.setSelectedInstitution(this.institutionState.selectedInstitution) ;
      }
    });*/
  }

  // close preview table on clicking the discard button on preview-table
  handleCloseTable() {
    this.isDataValidated = false;
    this.step = "institution";
    this.competedSteps = [];
    this.fileSelected = null;
  }

  protected readonly ImportStepsEnum = ImportStepsEnum;
}
