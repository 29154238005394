import { parse } from "date-fns";

import { BaseResponse } from "../../../../../../../libs/common/src/models/response/base.response";
import { TransactionDirection } from "../../enum/transactionDirection";
import { DateFormatIndex, DateString, TransactionStatusType } from "../../types/general-types";
import { SplitCategoryType } from "../../types/split-category-type";
import { SplitClassificationType } from "../../types/split-classification-type";
import { Allocation } from "../allocation.data";
import { GlossBalance } from "../shared/gloss-balance";
import { GlossDate } from "../shared/gloss-date";
import { GlossQuantity } from "../shared/gloss-quantity";
import { Valuation } from "../valuation.data";

export class TransactionResponse extends BaseResponse {
  __v: number;
  id: string;
  accountId: string;
  private _sourceId: string;
  description: string;
  kind: string;
  _hash: string;
  direction: TransactionDirection;

  private _revalTransaction: boolean;
  private _valuationPrice: number;
  private _isUsingValuationPrice: boolean;
  private _valuation: Valuation;
  private _balance: GlossBalance;
  private _quantity: GlossQuantity;
  private _transactionDate: string | GlossDate;
  private _allocations?: Allocation[];

  private _categories?: SplitCategoryType[]; // array of categories and weights ids
  private _classifications?: SplitClassificationType[]; // array of classifications ids and their weights
  private _linkedTo: Array<string>;
  private _currencyBalances: Record<string, number>;
  private _bankImportedBalance: number;
  private _definition: TransactionStatusType;

  constructor(response: any = null) {
    super(response);

    this.__v = this.getResponseProperty("__v");
    this.sourceId = this.getResponseProperty("_sourceId");
    this.id = this.getResponseProperty("_id") || this.getResponseProperty("id");
    this.definition =
      this.getResponseProperty("definition") || this.getResponseProperty("_definition");
    this.accountId = this.getResponseProperty("accountId") || this.getResponseProperty("Account");
    this.description = this.getResponseProperty("description")
      ? this.getResponseProperty("description")
      : "";
    this.kind = this.getResponseProperty("kind") ? this.getResponseProperty("kind") : "";
    this._hash = this.getResponseProperty("_hash");
    this.direction = this.getResponseProperty("direction");

    this.valuationPrice =
      this.getResponseProperty("valuationPrice") || this.getResponseProperty("price");
    this.isUsingValuationPrice = this.getResponseProperty("_isUsingValuationPrice");
    this.categories = this.getResponseProperty("categories");
    this.classifications = this.getResponseProperty("classifications");
    this.allocations = this.getResponseProperty("allocations");
    this.bankImportedBalance = response;
    this.linkedTo = this.getResponseProperty("_linkedTo");
    this.revalTransaction = this.getResponseProperty("_revalTransaction")
      ? this.getResponseProperty("_revalTransaction")
      : false;
    this.setQuantityAndDirection(response);
    this.setCurrencyBalances(response);
    this.balance = response;
    this.valuation = response;

    if (response.dateFormatIndex) {
      this.setTransactionDateFromFormat(response.dateFormatIndex);
    } else {
      this.setTransactionDate();
    }
  }

  set sourceId(sourceId: string) {
    this._sourceId = sourceId;
  }

  get sourceId(): string {
    return this._sourceId;
  }
  get revalTransaction(): boolean {
    return this._revalTransaction;
  }

  set revalTransaction(revalTransaction: boolean) {
    this._revalTransaction = revalTransaction;
  }

  get allocations(): Allocation[] {
    return this._allocations;
  }

  set allocations(allocations: Array<Record<string, any>>) {
    const allocationObjects = [];
    if (Array.isArray(allocations)) {
      for (const allocation of allocations) {
        const castAllocation = new Allocation().setToAllocationObj(allocation);
        allocationObjects.push(castAllocation);
      }
    }
    this._allocations = allocationObjects;
  }

  get categories(): { categoryId: string; weight: number }[] {
    return this._categories;
  }

  set categories(
    value: { categoryId: string; weight: number }[] | { categoryId: string; weight: number }
  ) {
    if (Array.isArray(value)) {
      this._categories = value;
    } else {
      this._categories = [value];
    }
  }
  get definition(): TransactionStatusType {
    return this._definition;
  }

  set definition(value: TransactionStatusType) {
    this._definition = value;
  }

  get classifications(): SplitClassificationType[] {
    return this._classifications;
  }

  set classifications(value: SplitClassificationType[] | SplitClassificationType) {
    if (Array.isArray(value)) {
      this._classifications = value;
    } else {
      this._classifications = [value];
    }
  }

  get balance(): GlossBalance {
    return this._balance;
  }

  set balance(response: Record<string, any>) {
    if (response.balance && response.balance instanceof GlossBalance) {
      this._balance = response.balance;
    } else if (response._balance && response._balance instanceof GlossBalance) {
      this._balance = response._balance;
    } else if (typeof response.balance === "object") {
      this._balance = new GlossBalance().setToBalanceObj(response.balance);
    } else if (typeof response._balance === "object") {
      this._balance = new GlossBalance().setToBalanceObj(response._balance);
    } else {
      this._balance = new GlossBalance().setToBalanceObj(response);
    }
  }

  get quantity(): GlossQuantity {
    return this._quantity;
  }

  set quantity(response: Record<string, any>) {
    this._quantity = new GlossQuantity().setToQuantityObj(response);
  }

  get valuation(): Valuation {
    return this._valuation;
  }

  set valuation(response: Record<string, any>) {
    if (response.valuation && response.valuation instanceof Valuation) {
      this._valuation = response.valuation;
    } else if (response._valuation && response._valuation instanceof Valuation) {
      this._valuation = response._valuation;
    } else if (typeof response.valuation === "object") {
      this._valuation = new Valuation().setToValuationObj(response.valuation);
    } else {
      this._valuation = new Valuation().setToValuationObj(response);
    }
  }

  get valuationPrice(): number {
    return this._valuationPrice;
  }

  set valuationPrice(value: any) {
    if (typeof value === "string") {
      this._valuationPrice = Number.parseFloat(value.replace(/[\s,]/g, ""));
    } else {
      this._valuationPrice = Number.parseFloat(value);
    }
  }

  get bankImportedBalance(): number {
    return this._bankImportedBalance;
  }

  set bankImportedBalance(response: any) {
    if (typeof response === "string") {
      this._bankImportedBalance = Number.parseFloat(response.replace(/[\s,]/g, ""));
    } else if (typeof response === "object") {
      if (
        response.bankImportedBalance !== null &&
        response.bankImportedBalance !== undefined &&
        !isNaN(Number.parseFloat(response.bankImportedBalance))
      ) {
        this._bankImportedBalance = Number.parseFloat(response.bankImportedBalance);
      } else {
        this._bankImportedBalance = Number.parseFloat(this.getResponseProperty("balance"));
      }
    } else {
      this._bankImportedBalance = Number.parseFloat(response);
    }
  }

  get transactionDate(): string | GlossDate {
    return this._transactionDate;
  }

  set transactionDate(value: string | GlossDate) {
    this._transactionDate = value;
  }

  get currencyBalances(): Record<string, number> {
    return this._currencyBalances;
  }

  set currencyBalances(value: Record<string, number>) {
    this._currencyBalances = value;
  }

  get linkedTo(): Array<string> {
    return this._linkedTo;
  }

  set linkedTo(value: Array<string>) {
    this._linkedTo = value;
  }

  get isUsingValuationPrice(): boolean {
    return this._isUsingValuationPrice;
  }

  set isUsingValuationPrice(value: boolean) {
    this._isUsingValuationPrice = value;
  }

  getMonthIndexFromString(monthString: string): number {
    const formatString = monthString.length > 3 ? "MMMM" : "MMM";

    const parsedMonth = parse(monthString, formatString, new Date());

    return parsedMonth.getMonth() + 1;
  }
  /**
    returns the timestamp of the date .
   -  the reason I use dateFormatIndex is to be able to get the correct timestamp for all browsers . some browsers may
      run Date.parse() differently depending on the js envirenment .
   -  it assumes transactionDateString will always be of numerical and and some separator such as 05/06/2000 etc
   - If this is not the case We can tweek things based on a sample of variaty of datetypes as well.
   * */
  getTransactionDateFromFormatIndex(
    transactionDateString: DateString,
    dateFormatIndex: DateFormatIndex
  ): Date {
    const parts = transactionDateString.trim().split(dateFormatIndex.separator);

    if (isNaN(parseInt(parts[dateFormatIndex.month]))) {
      parts[dateFormatIndex.month] = this.getMonthIndexFromString(
        parts[dateFormatIndex.month]
      ).toString();
    }

    let year = parseInt(parts[dateFormatIndex.year]) || 1900;
    const monthIdx = (parseInt(parts[dateFormatIndex.month]) || 1) - 1; // 0 to 11
    const day = parseInt(parts[dateFormatIndex.day]) || 1;

    // todo make something that will not break in 20 years
    /* Assume YY date < 49 are in 2000, over 49 in 1900. */
    if (year < 49) {
      year += 2000;
    } else if (year > 49 && year < 99) {
      year += 1000;
    }

    const date = new Date(
      year,
      monthIdx,
      day,

      // Todo we should also make sure the DateFormatIndex can process time
      0,
      0,
      0,
      0
    );

    // todo what timezone should we give the date, at this stage. We will not know until the account is set.

    if (date.toString() === "Invalid Date") {
      // todo better handle of bad date
      return new Date();
    } else {
      return date;
    }
  }

  private setTransactionDateFromFormat(dateFormatIndex: DateFormatIndex) {
    if (this._transactionDate instanceof GlossDate) {
      return;
    }

    this.setTransactionDate();
    const transactionDate: Date = this.getTransactionDateFromFormatIndex(
      this._transactionDate,
      dateFormatIndex
    );
    this._transactionDate = transactionDate.toUTCString();
  }

  private setTransactionDate() {
    const transactionDate =
      this.getResponseProperty("_transactionDate") ||
      this.getResponseProperty("Date") ||
      this.getResponseProperty("statement date");
    this._transactionDate = transactionDate;
  }

  private setQuantityAndDirection(response: any) {
    this.direction = this.getResponseProperty("Direction");

    /** Set the initial value of the GlossQuantity */
    this.quantity = response;
    if (!this.direction && this.quantity?.actualQuantity) {
      if (this.quantity.actualQuantity.amount >= 0) {
        this.direction = TransactionDirection.In;
        this.quantity.setQuantityAmount(Number(this.quantity.actualQuantity.amount));
      } else {
        this.direction = TransactionDirection.Out;
        this.quantity.setQuantityAmount(Math.abs(Number(this.quantity.actualQuantity.amount)));
      }
    }

    // override if we have the specific in and out fields
    const inQty = this.getResponseProperty("In");
    const outQty = this.getResponseProperty("Out");
    if (inQty || outQty) {
      this.direction = inQty ? TransactionDirection.In : TransactionDirection.Out;
      this.quantity.setQuantityAmount(inQty ? Number(inQty) : Number(outQty));
    }
  }

  private setCurrencyBalances(response: any) {
    if (this.quantity.actualQuantity) {
      const actualQuantity = Number(this.quantity.actualQuantity);
      let currency = response.currency || response._currency;
      const price = response.price;
      const convrate = response.convrate || response._convrate;
      const convsym = response.convsym || response._convsym;

      let value;
      if (actualQuantity) {
        value = actualQuantity;
      }
      value = this.direction === TransactionDirection.Out ? value * -1 : value;

      if (price) {
        value = value * price;
      }
      if (convrate && convsym) {
        value = value / convrate;
        currency = convsym;
      }

      if (currency) {
        // TODO: check if account currency is applied when Account is chosen
        response.currencyBalances = {};
        response.currencyBalances[currency] = value;
      }
    }
  }
}
