<!--<div class="tabbed-nav d-flex flex-column">-->
<!--  <ul class="nav nav-tabs">-->
<!--    <ng-container *ngIf="showChangePassword">-->
<!--      <li class="nav-item">-->
<!--        <a class="nav-link" routerLink="change-password" routerLinkActive="active">-->
<!--          {{ "password" | i18n }}-->
<!--        </a>-->
<!--      </li>-->
<!--    </ng-container>-->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" routerLink="two-factor" routerLinkActive="active">-->
<!--            {{ "twoStepLogin" | i18n }}-->
<!--          </a>-->
<!--        </li>-->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link" routerLink="security-keys" routerLinkActive="active">-->
<!--            {{ "keys" | i18n }}-->
<!--          </a>-->
<!--        </li>-->
<!--  </ul>-->
<!--</div>-->
<router-outlet (activate)="onActivate($event)"></router-outlet>
