import { interestCreation } from "@bitwarden/web-vault/app/models/types/estimate-action.types";
import {
  EstimateActionProperties,
  ScenarioHelpInfo,
  ScenarioPermutation,
} from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { InterestRateScenarioOptionUtils } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/ScenarioOptions/interest-rate-scenario-option.utils";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";

export class CurrentInterestRate extends InterestRateScenarioOptionUtils {
  /**
   * createPermutations - Create the different possible sets of permutations to run
   *                      By default, this should only run the ones from blobby so that it will
   *                      work for user generated scenarios eventually
   */
  async createPermutations() {
    this.permutations = [];
    const accounts = [];
    const accountBalance = [];
    const helpInfo: ScenarioHelpInfo = {};

    const scenarioPermutation: ScenarioPermutation = { estimateActions: [] };
    // The symbol this scenario is interested in
    const symbol = this.scenarioGroup.symbol;
    if (this.startingAccountBalances) {
      const dataRepositoryService = this.injector.get(DataRepositoryService);
      for (const accountID in this.startingAccountBalances) {
        // check for the symbol in the balance greater than 0
        const balance = this.startingAccountBalances[accountID];
        const amount = balance.balance.runningTotalValue?.[symbol]?.symbolAmount?.amount;
        //TODO @Sinan@Michelle: they want to see the negative balance rates as well. aka they want to see the zero rates transactions on the current interest rate scenario
        const account = await dataRepositoryService.getBookById(accountID);
        const accountUrl = await this.getAccountUrl(account);
        const accountInstitutionName = await this.getInstitutionName(account);
        // check for the account type being one with interest rates
        let interestRates = await this.getInterestRates(account, symbol);

        if (
          (!interestRates || interestRates.length === 0) &&
          this.startingAccountBalances[account.id]?.balance.runningTotalValue?.[symbol]
            ?.symbolAmount?.amount > 0
        ) {
          interestRates = [
            {
              rate: 0,
              symbol: symbol,
              banded: false,
              range: -1,
              last_updated: "",
              condition_intrinsic_desc: "",
              condition_action_desc: "",
            },
          ];
        }
        if (interestRates && interestRates.length > 0) {
          accountBalance.push(amount);
          accounts.push({
            accountName: account.name,
            rate: interestRates,
            accountUrl: accountUrl,
            accountBank: accountInstitutionName,
          });
          const parameters = this.createInterestParameters(account, interestRates);
          const permutation: EstimateActionProperties = {
            parameters: parameters,
            estimateActionType: interestCreation,
          };
          scenarioPermutation.estimateActions.push(permutation);
        }
      }
    }
    if (scenarioPermutation.estimateActions.length > 0) {
      helpInfo.existingAccounts = accounts;
      helpInfo.totalBalance = accountBalance.reduce((a, b) => a + b, 0);
      scenarioPermutation.scenarioHelpInfo = helpInfo;
      this.permutations.push(scenarioPermutation);
    }
  }
}
