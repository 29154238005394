export enum BlobbyDataTypeEnum {
  Transaction = "Transaction",
  SourceTransaction = "SourceTransaction",
  SourceBook = "SourceBook",
  SourceCategory = "SourceCategory",
  Book = "Book",
  Category = "Category",
  Classification = "Classification",
  Connector = "Connector",

  Estimate = "Estimate",
  EstimateGroup = "EstimateGroup",
  Scenario = "Scenario",
  ScenarioGroup = "ScenarioGroup",

  Institution = "Institution",
  ImportHandler = "ImportHandler",

  Symbol = "Symbol",
  Preference = "Preference",
  ReferenceData = "ReferenceData",
  VaultFile = "VaultFile",
  UserLocation = "UserLocation",
  Wizard = "Wizard",
}

export enum BlobbyDataTypeNameEnum {
  Transaction = "Transaction",
  SourceTransaction = "Source Transaction",
  SourceBook = "SourceBook",
  Book = "Book",
  Category = "Category",
  Classification = "Classification",
  Institution = "Institution",
  ImportHandler = "Import Handler",
  Estimate = "Estimate",
  Symbol = "Symbol",
  Preference = "Preference",
  EstimateGroup = "Estimate Group",
  ReferenceData = "ReferenceData",
  Scenario = "Scenario",
  ScenarioGroup = "Scenario Group",
  SourceCategory = "Source Category",
  VaultFile = "VaultFile",
  UserLocation = "UserLocation",
  Wizard = "Wizard",
}
