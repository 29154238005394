<app-spinner [showSpinner]="transactionForm.loading"></app-spinner>
<div class="modal-container">
  <modal-header [showBackButton]="false" [title]="transactionForm.title"></modal-header>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <form [formGroup]="transactionForm.formGroup" name="accountForm">
      <div class="tw-mb-6 tw-flex tw-flex-col tw-gap-4">
        <div class="tw-flex tw-justify-between tw-gap-4 tb:tw-flex-col">
          <app-gloss-input
            [options]="transactionForm.amountInputOptions"
            class="tw-w-full"
          ></app-gloss-input>
          <app-currencies
            (selectedCurrency)="transactionForm.selectCurrency($event)"
            [label]="transactionForm.i18nService.t('symbol')"
            class="tw-w-full"
          ></app-currencies>
        </div>
        <div class="tw-flex tw-justify-between tw-gap-4 sm:tw-gap-6 tb:tw-flex-col">
          <app-date-picker
            [label]="transactionForm.i18nService.t('date')"
            [isRequired]="true"
            [hasError]="transactionForm.formGroup.get('date').invalid"
            (changeDateEvent)="transactionForm.onDateChange($event)"
            class="tw-w-full"
          >
          </app-date-picker>
          <app-gloss-input
            [options]="transactionForm.timeInputOptions"
            class="tw-w-full"
          ></app-gloss-input>
        </div>
        <app-gloss-input
          [options]="transactionForm.descriptionInputOptions"
          class="tw-w-full"
        ></app-gloss-input>
      </div>
      <app-gloss-button
        [options]="transactionForm.saveButtonOptions"
        class="tw-w-full"
      ></app-gloss-button>
    </form>
  </div>
</div>
