<div class="modal-container" (keydown.enter)="$event.preventDefault(); $event.stopPropagation()">
  <modal-header [title]="'findInstitutionBank' | i18n" (onBackClicked)="onBackClicked()">
  </modal-header>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <form class="tw-flex tw-flex-col">
      <app-countries (selectedCountry)="setCountry($event)"></app-countries>
      <br />
      <app-institutions [country]="selectedCountry" (selectedInstitution)="setInstitution($event)">
      </app-institutions>
      <modal-footer>
        <app-gloss-button [options]="nextButton" class="tw-w-full"></app-gloss-button>
      </modal-footer>
    </form>
  </div>
</div>
