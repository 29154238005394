import { Injector } from "@angular/core";
import { Validators } from "@angular/forms";

import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { TransactionData } from "@bitwarden/web-vault/app/models/types/transaction.types";

import { InputTypes } from "../../../../models/enum/inputTypes.enum";
import { PreferenceType } from "../../../../models/enum/preferenceType";
import { TransactionStatusEnum } from "../../../../models/enum/transactionType";
import { UserLocationCurrencyEnum } from "../../../../models/enum/user-location.enum";
import { FormInterface } from "../../../../models/interfaces/form.interface";
import { GlossInputOptions } from "../../../../models/types/general-types";
import { TransactionService } from "../../../../services/DataService/transaction/transaction.service";
import { BaseForm } from "../../../../shared/form/BaseForm";
import { HelperNumericInput } from "../../../../shared/utils/helper-numeric-input";
import {
  actionButtonOptions,
  amountInputOptions,
  defaultInputOptions,
} from "../accounts-add-edit/component.options";

export class TransactionForm extends BaseForm implements FormInterface {
  private transactionService: TransactionService;

  private data: TransactionData;
  amountInputOptions: GlossInputOptions;
  timeInputOptions: GlossInputOptions;
  descriptionInputOptions: GlossInputOptions;
  saveButtonOptions = new ActionButton({
    ...actionButtonOptions,
    onClick: this.submit.bind(this),
  });

  constructor(injector: Injector, data: TransactionData) {
    super(injector);
    this.data = data;

    this.title = this.i18nService.t("enterAccountData");
    this.transactionService = injector.get(TransactionService);
    this.initialize();
  }

  async initialize() {
    const { AU, HK } = UserLocationCurrencyEnum;
    const userLocation = await this.preferenceService.get(PreferenceType.userLocation);
    this.currency = userLocation === "AU" ? AU : HK;

    this.formGroup = this.formBuilder.group({
      currency: [this.currency, Validators.required],
      amount: [null, [HelperNumericInput.isNumberValidator, Validators.required]],
      date: [this.dateFormat.getDateStringFromStamp(new Date().getTime()), Validators.required],
      time: [null],
      description: [null, Validators.required],
      transactionStatus: TransactionStatusEnum.transaction,
    });

    this.amountInputOptions = {
      ...amountInputOptions,
      label: this.i18nService.t("amount"),
      onInput: (event: Event) => this.onInputNumeric(event, "amount"),
      inputBlurred: () => this.clearDefaultValue("amount"),
    };

    this.timeInputOptions = {
      ...defaultInputOptions,
      label: this.i18nService.t("time"),
      placeholder: "00:00:00",
      isRequired: false,
      type: InputTypes.time,
      onInput: (event: Event) => this.onInput(event, "time"),
    };

    this.descriptionInputOptions = {
      ...defaultInputOptions,
      label: this.i18nService.t("description"),
      placeholder: this.i18nService.t("description"),
      onInput: (event: Event) => this.onInput(event, "description"),
      isRequired: true,
    };
  }

  async submit() {
    const isDateTouched = this.formGroup.controls.date.touched;
    !isDateTouched && this.onDateChange("");

    if (this.formGroup.valid) {
      this.transactionService.createNormalTransaction(
        this.data.accountView.originalBook,
        this.formGroup.value
      );
      this.data.closeDialogue();
      this.globalService.showSuccessMessage("success", "transactionAddedSuccessfully");
    } else {
      this.saveButtonOptions.enableButton(true);
    }
  }
}
