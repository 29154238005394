/**
 * Gloss number pair will be used throughout the system to represent
 * the original symbol and quantity that is tied to a normalized
 * amount in the base currency. Previously these two numbers were
 * often not aligned together, so it was more difficult to piece
 * together the origin of the normalized number.
 *
 * This also allows us to be able to extract the raw quantity of a symbol
 * or the normalized value depending on the need of the circumstance such as
 * for graphing purposes.
 *
 */
import { GlossNumber, IGlossNumber, GlossNumberUtils } from "./gloss-number";

export class NormalizedPairUtils {
  static add(pair: INormalizedPair, additionalPair: INormalizedPair) {
    pair.normalizedValue.amount = NormalizedPairUtils.addPairNormalizedAmount(pair, additionalPair);
    pair.symbolAmount.amount = NormalizedPairUtils.addPairSymbolAmount(pair, additionalPair);
  }

  static addPairSymbolAmount(pair: INormalizedPair, additionalPair: INormalizedPair): number {
    if (pair.symbolAmount.symbol === additionalPair.symbolAmount.symbol) {
      const amount = pair.symbolAmount.amount + additionalPair.symbolAmount.amount;
      return amount;
    }
    // TODO @Ronald, check with Michelle because this seems cause an issue
    // else {
    //   throw new Error(
    //     "Cannot add the symbol amount of two glossNumberPair records because the symbolAmount" +
    //       " symbols are different"
    //   );
    // }
  }

  static addPairNormalizedAmount(pair: INormalizedPair, additionalPair: INormalizedPair): number {
    if (pair.normalizedValue.symbol === additionalPair.normalizedValue.symbol) {
      const amount = pair.normalizedValue.amount + additionalPair.normalizedValue.amount;
      return amount;
    }
    // TODO @Ronald, check with Michelle because this seems cause an issue
    // else {
    //   // debugger
    //   throw new Error(
    //     "Cannot add the normalized amount of two glossNumberPair records because the normalizedValue" +
    //       " symbols are different"
    //   );
    // }
  }

  static copy(pair: INormalizedPair): NormalizedPair {
    const copyNormalizedPair = new NormalizedPair();
    copyNormalizedPair.symbolAmount = GlossNumberUtils.copy(pair.symbolAmount);
    copyNormalizedPair.normalizedValue = GlossNumberUtils.copy(pair.normalizedValue);
    return copyNormalizedPair;
  }
}

export interface INormalizedPair {
  symbolAmount: IGlossNumber;
  normalizedValue: IGlossNumber;
}

export class NormalizedPair {
  symbolAmount: GlossNumber = new GlossNumber();
  normalizedValue: GlossNumber = new GlossNumber();

  setToNormalizedPairObj(response: Record<string, any>) {
    return response instanceof NormalizedPair
      ? this.setFromObject(response)
      : this.setFromQueryString(response);
  }

  private setFromObject(normalisedPair: NormalizedPair) {
    return normalisedPair;
  }

  private setFromQueryString(response: Record<string, any>) {
    if (response.symbolAmount) {
      this.symbolAmount = new GlossNumber().setToGlossNumberObj(response.symbolAmount);
    } else if (response._symbolAmount) {
      this.symbolAmount = new GlossNumber().setToGlossNumberObj(response._symbolAmount);
    }

    if (response.normalizedValue) {
      this.normalizedValue = new GlossNumber().setToGlossNumberObj(response.normalizedValue);
    } else if (response._normalizedValue) {
      this.normalizedValue = new GlossNumber().setToGlossNumberObj(response._normalizedValue);
    }
    return this;
  }

  add(additionalPair: NormalizedPair) {
    NormalizedPairUtils.add(this, additionalPair);
  }

  addPairSymbolAmount(additionalPair: NormalizedPair): number {
    return NormalizedPairUtils.addPairSymbolAmount(this, additionalPair);
  }

  addPairNormalizedAmount(additionalPair: NormalizedPair): number {
    return NormalizedPairUtils.addPairNormalizedAmount(this, additionalPair);
  }

  copy(): NormalizedPair {
    const copyNormalizedPair = new NormalizedPair();
    copyNormalizedPair.symbolAmount = this.symbolAmount.copy();
    copyNormalizedPair.normalizedValue = this.normalizedValue.copy();
    return copyNormalizedPair;
  }
}
