import { Book } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/book.data";
import { Category } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/category.data";
import { Classification } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/classification.data";
import { Connector } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/connector.data";
import { Estimate } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/estimate.data";
import { Institution } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/institution.data";
import { Preference } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/preference.data";
import { ReferenceData } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/reference-data.data";
import { Scenario } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/scenario.data";
import { SourceBook } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/source-book";
import { SourceCategory } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/source-category";
import { SourceTransaction } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/source-transaction.data";
import { Transaction } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/transaction.data";
import { VaultFile } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/vault-file.data";
import { Wizard } from "../../../../../apps/ironfly-web/src/app/models/data/blobby/wizard.data";
import { ImportHandler } from "../../../../../apps/ironfly-web/src/app/models/data/import-handler.data";
import { ScenarioGroup } from "../../../../../apps/ironfly-web/src/app/models/data/scenario-group.data";
import { SymbolInfoData } from "../../../../../apps/ironfly-web/src/app/models/data/symbol-info.data";
import { UserLocation } from "../../../../../apps/ironfly-web/src/app/models/data/user-location";
import { BlobbyDataTypeEnum } from "../../../../../apps/ironfly-web/src/app/models/enum/blobbyDataTypeEnum";

import { BaseResponse } from "./base.response";

export class BlobbyResponse extends BaseResponse {
  type: string; // type of what was returned
  error: Error | null;
  transactions: Array<Transaction> = [];
  sourceTransactions: Array<SourceTransaction> = [];
  sourceBooks: Array<SourceBook> = [];
  books: Array<Book> = [];
  categories: Array<Category> = [];
  classifications: Array<Classification> = [];
  estimates: Array<Estimate> = [];
  symbols: Array<SymbolInfoData> = [];
  importHandlers: Array<ImportHandler> = [];
  preferences: Array<Preference> = [];
  estimateGroups: Array<Estimate> = [];
  institutions: Array<Institution> = [];
  references: Array<ReferenceData> = [];
  scenarios: Array<Scenario> = [];
  scenarioGroups: Array<ScenarioGroup> = [];
  sourceCategories: Array<SourceCategory> = [];
  connector: Array<Connector> = [];
  vaultFile: Array<VaultFile> = [];
  userLocation: Array<UserLocation> = [];
  wizard: Array<Wizard> = [];

  constructor(type: BlobbyDataTypeEnum, jsonResponse?: string) {
    super(jsonResponse);
    this.type = type;
    this.error = null;

    if (jsonResponse) {
      try {
        const objs = JSON.parse(jsonResponse);
        this.add(objs);
      } catch (e) {
        this.error = e;
      }
    }
  }

  add(models: object[]) {
    if (this.type === BlobbyDataTypeEnum.Transaction) {
      this.transactions = this.transactions.concat(models as Transaction[]);
    } else if (this.type === BlobbyDataTypeEnum.ReferenceData) {
      this.references = this.references.concat(models as ReferenceData[]);
    } else if (this.type === BlobbyDataTypeEnum.SourceTransaction) {
      this.sourceTransactions = this.sourceTransactions.concat(models as SourceTransaction[]);
    } else if (this.type === BlobbyDataTypeEnum.SourceBook) {
      this.sourceBooks = this.sourceBooks.concat(models as SourceBook[]);
    } else if (this.type === BlobbyDataTypeEnum.Book) {
      this.books = this.books.concat(models as Book[]);
    } else if (this.type === BlobbyDataTypeEnum.Category) {
      this.categories = this.categories.concat(models as Category[]);
    } else if (this.type === BlobbyDataTypeEnum.Classification) {
      this.classifications = this.classifications.concat(models as Classification[]);
    } else if (this.type === BlobbyDataTypeEnum.Estimate) {
      this.estimates = this.estimates.concat(models as Estimate[]);
    } else if (this.type === BlobbyDataTypeEnum.Institution) {
      this.institutions = this.institutions.concat(models as Institution[]);
    } else if (this.type === BlobbyDataTypeEnum.EstimateGroup) {
      this.estimateGroups = this.estimateGroups.concat(models as Estimate[]);
    } else if (this.type === BlobbyDataTypeEnum.Symbol) {
      this.symbols = this.symbols.concat(models as SymbolInfoData[]);
    } else if (this.type == BlobbyDataTypeEnum.ImportHandler) {
      this.importHandlers = this.importHandlers.concat(models as ImportHandler[]);
    } else if (this.type == BlobbyDataTypeEnum.Preference) {
      this.preferences = this.preferences.concat(models as Preference[]);
    } else if (this.type == BlobbyDataTypeEnum.Scenario) {
      this.scenarios = this.scenarios.concat(models as Scenario[]);
    } else if (this.type == BlobbyDataTypeEnum.ScenarioGroup) {
      this.scenarioGroups = this.scenarioGroups.concat(models as ScenarioGroup[]);
    } else if (this.type == BlobbyDataTypeEnum.SourceCategory) {
      this.sourceCategories = this.sourceCategories.concat(models as SourceCategory[]);
    } else if (this.type == BlobbyDataTypeEnum.Connector) {
      this.connector = this.connector.concat(models as Connector[]);
    } else if (this.type == BlobbyDataTypeEnum.VaultFile) {
      this.vaultFile = this.vaultFile.concat(models as VaultFile[]);
    } else if (this.type == BlobbyDataTypeEnum.UserLocation) {
      this.userLocation = this.userLocation.concat(models as UserLocation[]);
    } else if (this.type == BlobbyDataTypeEnum.Wizard) {
      this.wizard = this.wizard.concat(models as Wizard[]);
    } else {
      this.error = new Error(`BlobbyDataType not implemented`);
    }
  }
}
