import { Injector } from "@angular/core";
import { take } from "rxjs";

import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { SyncIcon } from "@bitwarden/web-vault/app/models/enum/sync.enum";
import { ISyncStore } from "@bitwarden/web-vault/app/models/interfaces/sync.interface";
import {
  BasiqConnection,
  BasiqJob,
  BasiqJobStep,
  GlossGetBasiqJobResponse,
} from "@bitwarden/web-vault/app/models/types/basiq.types";
import { AccountState, StatusPoint } from "@bitwarden/web-vault/app/models/types/general-types";
import { InstitutionGroup } from "@bitwarden/web-vault/app/models/types/institution.type";
import { SyncStatusPoints } from "@bitwarden/web-vault/app/services/api/basiq/fixture/sync-status-messages";
import { SyncStore } from "@bitwarden/web-vault/app/services/syncing/syncing.store";

import { BasiqAccountSync } from "./basiq.account.sync";

const DELAY_FOR_JOB_CHECKS = 5000;

export class BasiqInstitutionConnection {
  id: string;
  institutionGroup: InstitutionGroup;
  isCompleted = false;
  point: StatusPoint = {
    key: "started-syncing",
    icon: SyncIcon.syncAll,
    data: null,
  };
  private basiqAuth: BasiqAuth;
  private syncStore: SyncStore;
  instoConnection: BasiqConnection;

  constructor(institutionGroup: InstitutionGroup, private injector: Injector) {
    this.institutionGroup = institutionGroup;
    this.basiqAuth = this.injector.get(BasiqAuth);
    this.id = institutionGroup.institutionId;
  }

  async sync() {
    this.syncStore = this.injector.get(SyncStore);
    const isValidConnection = await this.isValidInstoConnection();
    if (!isValidConnection) {
      this.failSync(SyncStatusPoints.noConnection);
    } else {
      try {
        const refreshConnection = await this.basiqAuth.refreshConnection(this.instoConnection);
        if (!refreshConnection?.job) {
          this.failSync(SyncStatusPoints.connectionRefreshFailure);
          return;
        }

        await this.runBasiqJob(refreshConnection.job);
      } catch (e) {
        this.failSync(SyncStatusPoints.connectionRefreshFailure);
      }
    }
  }

  failSync(statusPoint: StatusPoint) {
    this.syncStore.getCurrentState.pipe(take(1)).subscribe((currentState) => {
      this.updateSync(currentState, statusPoint);
    });
  }

  updateSync(currentState: ISyncStore, statusPoint: StatusPoint) {
    const newStatus = this.getNewStatus(currentState, statusPoint);

    this.syncStore.updateAccountStatus(newStatus);
  }

  getNewStatus(currentState: ISyncStore, statusPoint: StatusPoint) {
    return currentState.accountsState.map((accountStatus) => {
      return this.getUpdatedAccountStatus(accountStatus, statusPoint);
    });
  }

  getUpdatedAccountStatus(accountStatus: AccountState, statusPoint: StatusPoint) {
    const isAccount = this.institutionGroup.accountViews.some(
      (accountView) => accountView.originalBook.id === accountStatus.accountId
    );
    if (isAccount) {
      accountStatus.point = statusPoint;
      accountStatus.isCompleted = true;
    }
    return accountStatus;
  }
  async isValidInstoConnection(): Promise<boolean> {
    try {
      const basiqConnection = await this.basiqAuth.getConnection(
        this.institutionGroup.accountViews[0]
      );
      if (basiqConnection) {
        this.instoConnection = basiqConnection;
      }

      return !!basiqConnection;
    } catch (e) {
      return false;
    }
  }

  /** Job: given by Basiq to keep track. So we keep checking until we get the success, then we start retrieving data */
  async runBasiqJob(job: BasiqJob) {
    const basiqJobResponse: GlossGetBasiqJobResponse = await this.basiqAuth.getBasiqJob(job.id);
    if (this.isJobFailed(basiqJobResponse.job)) {
      this.failSync(SyncStatusPoints.jobFetchFail);
      return;
    }

    if (this.isJobSucceeded(basiqJobResponse.job)) {
      await this.runAccountLevelSync();
      return;
    }

    this.basiqAuth.delay(DELAY_FOR_JOB_CHECKS).then(() => {
      this.runBasiqJob(basiqJobResponse.job);
    });
  }

  async runAccountLevelSync() {
    for (const accountSyncStatus of this.institutionGroup.accountViews) {
      const accountStatus = new BasiqAccountSync(accountSyncStatus, this.injector);
      accountStatus.sync().then(() => null);
    }
  }

  private isJobFailed(job: BasiqJob): boolean {
    return job.steps.some((step: BasiqJobStep) => step.status === "failed");
  }

  private isJobSucceeded(job: BasiqJob): boolean {
    return job.steps.every((step: BasiqJobStep) => step.status === "success");
  }
}
