import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { WizardResponse } from "../response/wizard.response";

export class Wizard extends ModelValidator {
  readonly __v = 1;
  private _id: string;
  private _step: { stepIndex: number; isComplete: boolean };

  get id(): string {
    return this._id;
  }

  set id(id: string) {
    this._id = id;
  }

  getStep(): { stepIndex: number; isComplete: boolean } {
    return this._step;
  }

  setStep(step: { stepIndex: number; isComplete: boolean }) {
    this._step = step;
  }

  constructor(response: WizardResponse) {
    super();
    if (response == null) {
      return;
    }
    this.checkVersion(this.__v, response.__v);
    this._id = response.id ? response.id : crypto.randomUUID();
    this.setStep(response.step);
  }
}
