import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { CipherService } from "@bitwarden/common/abstractions/cipher.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { SyncService } from "@bitwarden/common/abstractions/sync/sync.service.abstraction";
import { CipherType } from "@bitwarden/common/enums/cipherType";
import { BlobbyResponse } from "@bitwarden/common/models/response/blobby.response";
import { SymbolInfoResponse } from "@bitwarden/common/models/response/symbol-info.response";
import { CipherView } from "@bitwarden/common/models/view/cipher.view";
import { dateFormats } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/dateformats";
import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";
import {
  Preference,
  SyncStatusCollectionType,
} from "@bitwarden/web-vault/app/models/data/blobby/preference.data";
import { Wizard } from "@bitwarden/web-vault/app/models/data/blobby/wizard.data";
import { CategoryResponse } from "@bitwarden/web-vault/app/models/data/response/category.response";
import { PreferenceResponse } from "@bitwarden/web-vault/app/models/data/response/preference.response";
import {
  crudFlag,
  GlossEncryptedDataType,
} from "@bitwarden/web-vault/app/models/enum/glossEncryptedDataType";
import { ScenarioSource } from "@bitwarden/web-vault/app/models/enum/scenario-and-groups.enum";
import { BasiqUserType } from "@bitwarden/web-vault/app/models/types/basiq.types";
import { SystemDefaultScenarioGroup } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/fixture/system-default-scenario-and-scenario-group/scenario.service.test.data-scenario-group";
import { SystemDefaultScenarios } from "@bitwarden/web-vault/app/services/DataCalculationService/scenarios/fixture/system-default-scenario-and-scenario-group/scenario.service.test.data-scenarios";
import { BalanceAlignment } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/balance-alignment";
import { ItemCountService } from "@bitwarden/web-vault/app/services/DataService/state/item-count.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { CipherItemMap } from "@bitwarden/web-vault/app/services/blobby/cipher-item-map";
import { CipherItems } from "@bitwarden/web-vault/app/services/blobby/cipher-items";
import { PerformanceService } from "@bitwarden/web-vault/app/services/performance/performance.service";

import { Book } from "../../models/data/blobby/book.data";
import { Category } from "../../models/data/blobby/category.data";
import { Transaction } from "../../models/data/blobby/transaction.data";
import { SymbolInfoData } from "../../models/data/symbol-info.data";
import {
  BlobbyDataTypeEnum as BType,
  BlobbyDataTypeEnum,
} from "../../models/enum/blobbyDataTypeEnum";
import { DataTransformer } from "../dto/data-transformer";

import { BlobbyMemoryStorage } from "./blobby-memory-storage";
import { BlobbyUtils } from "./blobbyUtils";

@Injectable({
  providedIn: "root",
})
export class BlobbyService {
  ciphers: CipherView[] = [];
  private bram: BlobbyMemoryStorage;
  private initialised = false;
  private initialising = false;
  private pending: Array<any> = [];
  private _initialBlobbyWizardArray: Wizard[];

  private loadingItemReference = new BehaviorSubject<number>(0);
  loadingItemReference$ = this.loadingItemReference.asObservable();

  private inProcessItem = new BehaviorSubject<Record<string, any>>({
    type: "",
    total: 0,
  });
  inProcessItem$ = this.inProcessItem.asObservable();

  private loadingItemTransaction = new BehaviorSubject<number>(0);
  loadingItemTransaction$ = this.loadingItemTransaction.asObservable();

  private loadingItemCategory = new BehaviorSubject<number>(0);
  loadingItemCategory$ = this.loadingItemCategory.asObservable();

  private loadingItemClassification = new BehaviorSubject<number>(0);
  loadingItemClassification$ = this.loadingItemClassification.asObservable();

  private loadingItemSourceTransaction = new BehaviorSubject<number>(0);
  loadingItemSourceTransaction$ = this.loadingItemSourceTransaction.asObservable();

  totalItemsToProcess = 0;

  constructor(
    private cipherService: CipherService,
    private logService: LogService,
    private syncService: SyncService,
    private cipherItemMap: CipherItemMap,
    private cipherItems: CipherItems,
    private perfService: PerformanceService,
    private itemCountService: ItemCountService
  ) {
    this.bram = new BlobbyMemoryStorage();
  }

  getWizardData() {
    return this._initialBlobbyWizardArray;
  }

  setWizardData(wizardData: Wizard[]) {
    this._initialBlobbyWizardArray = wizardData;
  }

  startReferenceImport() {
    this.loadingItemReference = new BehaviorSubject<number>(0);
    this.loadingItemReference$ = this.loadingItemReference.asObservable();
  }

  completeReferenceProgress() {
    this.loadingItemReference.complete();
  }

  startTransactionImport() {
    this.loadingItemTransaction = new BehaviorSubject<number>(0);
    this.loadingItemTransaction$ = this.loadingItemTransaction.asObservable();
  }
  startCategoryImport() {
    this.loadingItemCategory = new BehaviorSubject<number>(0);
    this.loadingItemCategory$ = this.loadingItemCategory.asObservable();
  }
  startClassificationImport() {
    this.loadingItemClassification = new BehaviorSubject<number>(0);
    this.loadingItemClassification$ = this.loadingItemClassification.asObservable();
  }

  startSourceTransactionImport() {
    this.loadingItemSourceTransaction = new BehaviorSubject<number>(0);
    this.loadingItemSourceTransaction$ = this.loadingItemSourceTransaction.asObservable();
  }

  /** Set the value to false after log-out */
  setInitialised(value: boolean) {
    this.initialised = value;
  }

  getInitialised(): boolean {
    return this.initialised;
  }

  async refresh(skipSync = false) {
    this.initialised = false;
    if (!skipSync && this.initialising !== true) {
      await this.syncService.fullSync(false);
    }
    await this.initialise();
  }

  async initialise() {
    if (this.initialised !== true && this.initialising !== true) {
      this.initialising = true;
      this.bram.clear();

      // todo add inMemory for other data type
      const blobbyResponseTransactions = await this.fetchInVaultByType(BType.Transaction);
      this.bram.indexBramByType(BType.Transaction, blobbyResponseTransactions.transactions);
      this.itemCountService.setItemCountValue(
        BType.Transaction,
        blobbyResponseTransactions.transactions.length
      );

      const blobbyResponseReference = await this.fetchInVaultByType(BType.ReferenceData);
      this.bram.indexBramByType(BType.ReferenceData, blobbyResponseReference.references);

      const blobbyResponseAccounts = await this.fetchInVaultByType(BType.Book);
      this.bram.indexBramByType(BType.Book, blobbyResponseAccounts.books);
      this.itemCountService.setItemCountValue(BType.Book, blobbyResponseAccounts.books.length);

      const blobbyResponseClassifications = await this.fetchInVaultByType(BType.Classification);
      this.bram.indexBramByType(
        BType.Classification,
        blobbyResponseClassifications.classifications
      );

      const blobbyResponseInstitutions = await this.fetchInVaultByType(BType.Institution);
      this.bram.indexBramByType(BType.Institution, blobbyResponseInstitutions.institutions);

      const blobbyResponseCategories = await this.fetchInVaultByType(BType.Category);
      this.bram.indexBramByType(BType.Category, blobbyResponseCategories.categories);

      const blobbyResponseEstimates = await this.fetchInVaultByType(BType.Estimate);
      this.bram.indexBramByType(BType.Estimate, blobbyResponseEstimates.estimates);

      const blobbyResponseSymbols = await this.fetchInVaultByType(BType.Symbol);
      this.bram.indexBramByType(BType.Symbol, blobbyResponseSymbols.symbols);

      const blobbyResponsePreferences = await this.fetchInVaultByType(BType.Preference);
      this.bram.indexBramByType(BType.Preference, blobbyResponsePreferences.preferences);

      const blobbyResponseEstimateGroups = await this.fetchInVaultByType(BType.EstimateGroup);
      this.bram.indexBramByType(BType.EstimateGroup, blobbyResponseEstimateGroups.estimateGroups);

      const blobbyResponseSourceTransactions = await this.fetchInVaultByType(
        BType.SourceTransaction
      );
      this.bram.indexBramByType(
        BType.SourceTransaction,
        blobbyResponseSourceTransactions.sourceTransactions
      );

      const blobbyResponseSourceBooks = await this.fetchInVaultByType(BType.SourceBook);
      this.bram.indexBramByType(BType.SourceBook, blobbyResponseSourceBooks.sourceBooks);

      const blobbyResponseScenario = await this.fetchInVaultByType(BType.Scenario);
      this.bram.indexBramByType(BType.Scenario, blobbyResponseScenario.scenarios);

      const blobbyResponseScenarioGroup = await this.fetchInVaultByType(BType.ScenarioGroup);
      this.bram.indexBramByType(BType.ScenarioGroup, blobbyResponseScenarioGroup.scenarioGroups);

      const blobbyResponseSourceCategory = await this.fetchInVaultByType(BType.SourceCategory);
      this.bram.indexBramByType(
        BType.SourceCategory,
        blobbyResponseSourceCategory.sourceCategories
      );

      const blobbyResponseConnector = await this.fetchInVaultByType(BType.Connector);
      this.bram.indexBramByType(BType.Connector, blobbyResponseConnector.connector);

      const blobbyResponseVaultFile = await this.fetchInVaultByType(BType.VaultFile);
      this.bram.indexBramByType(BType.VaultFile, blobbyResponseVaultFile.vaultFile);

      const blobbyResponseWizard = await this.fetchInVaultByType(BType.Wizard);
      this.bram.indexBramByType(BType.Wizard, blobbyResponseWizard.wizard);
      this.setWizardData(blobbyResponseWizard.wizard);

      await this.initializePreferenceToDefault();
      await this.initializeSystemScenarioGroup();
      await this.cipherItemMap.mapToEachOther();

      this.initialising = false;
      this.initialised = true;
      this.itemCountService.setBlobbyInitialise(this.initialised);
      this.pending.forEach((resolve) => {
        resolve();
      });
    }
  }

  getItemCountService(): ItemCountService {
    if (this.getInitialised()) {
      return this.itemCountService;
    }
  }

  isInitialised(): Promise<void> {
    return new Promise((resolve) => {
      if (this.initialised) {
        resolve();
      } else {
        this.pending.push(() => {
          resolve();
        });
      }
    });
  }

  /**
   * @deprecated need refactor
   * @param type
   * @param items
   */
  async saveCategoriesFromTransactions(
    type: BlobbyDataTypeEnum,
    items: Array<any>
  ): Promise<BlobbyResponse> {
    // todo move that away
    const categoryNames: string[] = [];
    const categories: Category[] = [];

    items?.forEach((t) => {
      t.categories?.forEach((transCategory: string) => {
        if (!categoryNames.includes(transCategory)) {
          const c = new Category(new CategoryResponse(""));
          c.name = transCategory;
          categories.push(c);
          categoryNames.push(transCategory);
        }
      });
    });

    // Todo move that in save
    const bCategory = await this.fetchInVaultByType(BType.Category);
    bCategory.categories.forEach((items) => {
      if (!categoryNames.includes(items.name)) {
        categories.push(items);
      }
    });

    return await this.save(BlobbyDataTypeEnum.Category, categories);
  }

  async createAccount(account: Book): Promise<BlobbyResponse> {
    // load all existing accounts and append the new account to the list
    const blobbyResponseAccounts = await this.fetchInVaultByType(BType.Book);
    if (blobbyResponseAccounts && Array.isArray(blobbyResponseAccounts.books)) {
      blobbyResponseAccounts.books.push(account);
      const bResponse = this.saveAccounts(blobbyResponseAccounts.books);
      return bResponse;
    } else {
      // creating first account
      const bResponse = this.saveAccounts([account]);
      return bResponse;
    }
  }

  async saveAccount(account: Book): Promise<BlobbyResponse> {
    if (!account.id) {
      throw new Error("Invalid account details");
    }
    // assume that we are keying the account on ID by default
    // load all existing accounts, and replace the book-renderer with the matching ID
    let match = false;
    const blobbyResponseAccounts = await this.fetchInVaultByType(BType.Book);
    if (blobbyResponseAccounts && Array.isArray(blobbyResponseAccounts.books)) {
      blobbyResponseAccounts.books.forEach((existingAccount, index) => {
        if (existingAccount.id === account.id) {
          blobbyResponseAccounts.books[index] = account;
          match = true;
        }
      });
    }

    if (match) {
      const bResponse = this.saveAccounts(blobbyResponseAccounts.books);
      return bResponse;
    } else {
      throw new Error("This account could not be updated");
    }
  }

  async deleteAccount(id: string): Promise<boolean> {
    if (!id) {
      throw new Error("ID not supplied to delete account");
    }
    // assume that we are keying the account on ID by default
    // load all existing accounts, and delete the book-renderer with the matching ID
    let match = false;
    const blobbyResponseAccounts = await this.fetchInVaultByType(BType.Book);
    if (blobbyResponseAccounts && Array.isArray(blobbyResponseAccounts.books)) {
      blobbyResponseAccounts.books.forEach((existingAccount, index) => {
        if (existingAccount.id === id) {
          blobbyResponseAccounts.books.splice(index, 1);
          match = true;
        }
      });
    }
    return match;
  }

  async saveAccounts(accounts: Array<Book>): Promise<BlobbyResponse> {
    return await this.save(BlobbyDataTypeEnum.Book, accounts);
  }

  /**
   * KM: The source type function here is a temporary one that is being run in parallel with the gloss
   * transactions that are being saved.
   * Ideally what we want is for the source transactions to live completely independently to the gloss
   * ones as a separated abstraction layer.
   *
   * @param type
   * @param items
   */
  async saveSourceTransactions(
    type: BlobbyDataTypeEnum,
    items: Array<any>
  ): Promise<BlobbyResponse> {
    // load all existing source transactions, and merge it in with the accounts in items
    const blobbyResponseSourceTrans = await this.fetchInVaultByType(BType.SourceTransaction);
    if (blobbyResponseSourceTrans && Array.isArray(blobbyResponseSourceTrans.sourceTransactions)) {
      blobbyResponseSourceTrans.sourceTransactions.forEach((existingSourceTrans) => {
        const isBalAlignST =
          BalanceAlignment.isBalanceAlignmentSourceTransaction(existingSourceTrans);
        if (
          !TransactionService.isSourceTransactionExists(items, existingSourceTrans, isBalAlignST)
        ) {
          items.push(existingSourceTrans);
        }
      });
    }

    return await this.save(BlobbyDataTypeEnum.SourceTransaction, items);
  }

  async getById(type: BlobbyDataTypeEnum, id: string): Promise<BlobbyResponse> {
    const transactionsJson: string = await this.fetchInVaultItemById(id);
    if (!transactionsJson) {
      return new BlobbyResponse(BlobbyDataTypeEnum.Transaction, "{}");
    }

    const blobbyResponse = new BlobbyResponse(BlobbyDataTypeEnum.Transaction, transactionsJson);
    if (blobbyResponse.error) {
      this.logService.error(blobbyResponse.error.message);
    }
    return blobbyResponse;
  }

  async getAll(type: BlobbyDataTypeEnum): Promise<BlobbyResponse> {
    const blobbyResponse = new BlobbyResponse(type);
    if (type === BlobbyDataTypeEnum.Book) {
      blobbyResponse.add(this.bram.getAll(type));
    } else if (type === BlobbyDataTypeEnum.Transaction) {
      blobbyResponse.add(this.bram.getAll(type));
      const castTransactions: Array<Transaction> = [];
      blobbyResponse.transactions?.forEach((transaction) => {
        const [castTransaction] = DataTransformer.castToTransactionArray(transaction);
        castTransactions.push(castTransaction);
      });
      blobbyResponse.transactions = castTransactions;
    } else if (type === BlobbyDataTypeEnum.ReferenceData) {
      blobbyResponse.add(this.bram.getAll(type));
      blobbyResponse.references?.forEach((reference) => {
        DataTransformer.castToReferenceArray(reference);
      });
    } else {
      blobbyResponse.add(this.bram.getAll(type));
    }
    this.perfService.setVaultContext(this.bram.getContext());
    return blobbyResponse;
  }

  /**
   * @deprecated this will be converted in dataRepository as a query
   * @param accountId
   * @param startYMD
   * @param endYMD
   */
  getTransactionsInRange(accountId: string, startYMD: number, endYMD: number): Transaction[] {
    // load all the transactions for in account
    const blobbyResponse = new BlobbyResponse(BType.Transaction);
    blobbyResponse.add(this.bram.getAll(BType.Transaction));

    const transList: Transaction[] = [];

    if (blobbyResponse.transactions) {
      const startAsDate = BlobbyUtils.setYMDToDate(startYMD);
      const endAsDate = BlobbyUtils.setYMDToDate(endYMD);
      const transactions = DataTransformer.castToTransactionArray(blobbyResponse.transactions);

      transactions.forEach((trans) => {
        if (trans.accountId === accountId) {
          // TODO: update this hack later
          const prior = BlobbyUtils.isUpToTime(trans.transactionDate.date, startAsDate, false);
          const include = BlobbyUtils.isUpToTime(trans.transactionDate.date, endAsDate, true);
          if (!prior && include) {
            transList.push(trans);
          }
        }
      });
      return transList;
    }
    return [];
  }

  async saveSymbols(items: Array<any>) {
    const symbolIds: string[] = items.map((a) => a.symLocal);
    const symbols: SymbolInfoData[] = [];
    items?.forEach((t) => {
      const symResp = new SymbolInfoResponse(t);
      const sym = new SymbolInfoData(symResp);
      symbols.push(sym);
    });

    // load all existing symbols, and merge it in with the symbols in items
    const blobbyResponseSymbolInfo = await this.fetchInVaultByType(BType.Symbol);
    if (blobbyResponseSymbolInfo && Array.isArray(blobbyResponseSymbolInfo.symbols)) {
      blobbyResponseSymbolInfo.symbols.forEach((existingSymbol) => {
        if (!symbolIds.includes(existingSymbol.symLocal)) {
          symbols.push(existingSymbol);
        }
      });
    }

    // delete the existing cipher note
    return await this.save(BlobbyDataTypeEnum.Symbol, symbols);
  }

  async getSymbolInfo(symLocal: string): Promise<SymbolInfoData | void> {
    const blobbyResponse = new BlobbyResponse(BlobbyDataTypeEnum.Symbol);
    blobbyResponse.add(this.bram.getAll(BType.Symbol));
    const allSymbols = blobbyResponse.symbols;
    for (let i = 0; i < allSymbols.length; i++) {
      if (allSymbols[i].id === symLocal) {
        return allSymbols[i];
      }
    }
    return null;
  }

  async fetchInVaultByType(type: BlobbyDataTypeEnum): Promise<BlobbyResponse> {
    const ciphers: CipherView[] = await this.cipherService.getAllDecrypted();
    const bResponse = new BlobbyResponse(type);
    if (type === BlobbyDataTypeEnum.Transaction) {
      findAndCastToArray(this, DataTransformer.jsonObjectToTransaction);
    } else if (type === BlobbyDataTypeEnum.ReferenceData) {
      findAndCastToArray(this, DataTransformer.jsonObjectToReference);
    } else if (type === BlobbyDataTypeEnum.SourceTransaction) {
      findAndCastToArray(this, DataTransformer.castToSourceTransactionArray);
    } else if (type === BlobbyDataTypeEnum.Book) {
      findAndCastToArray(this, DataTransformer.castToBookArray);
    } else if (type === BlobbyDataTypeEnum.Category) {
      findAndCastToArray(this, DataTransformer.castToCategoryArray);
    } else if (type === BlobbyDataTypeEnum.Classification) {
      findAndCastToArray(this, DataTransformer.castToClassificationArray);
    } else if (type === BlobbyDataTypeEnum.Institution) {
      findAndCastToArray(this, DataTransformer.castToInstitutionArray);
    } else if (type === BlobbyDataTypeEnum.Estimate) {
      findAndCastToArray(this, DataTransformer.castToEstimateArray);
    } else if (type === BlobbyDataTypeEnum.EstimateGroup) {
      findAndCastToArray(this, DataTransformer.castToEstimateGroupArray);
    } else if (type === BlobbyDataTypeEnum.Symbol) {
      findAndCastToArray(this, DataTransformer.castToSymbolInfoArray);
    } else if (type === BlobbyDataTypeEnum.Preference) {
      findAndCastToArray(this, DataTransformer.castToPreferenceArray);
    } else if (type === BlobbyDataTypeEnum.SourceBook) {
      findAndCastToArray(this, DataTransformer.castToSourceBookArray);
    } else if (type === BlobbyDataTypeEnum.Scenario) {
      findAndCastToArray(this, DataTransformer.castToScenarioArray);
    } else if (type === BlobbyDataTypeEnum.ScenarioGroup) {
      findAndCastToArray(this, DataTransformer.castToScenarioGroupArray);
    } else if (type === BlobbyDataTypeEnum.SourceCategory) {
      findAndCastToArray(this, DataTransformer.castToSourceCategoryArray);
    } else if (type === BlobbyDataTypeEnum.Connector) {
      findAndCastToArray(this, DataTransformer.castToConnectorArray);
    } else if (type == BlobbyDataTypeEnum.VaultFile) {
      findAndCastToArray(this, DataTransformer.castToVaultFileArray);
    } else if (type == BlobbyDataTypeEnum.Wizard) {
      findAndCastToArray(this, DataTransformer.castToWizardArray);
    }
    return bResponse;

    function findAndCastToArray(parent: any, castToArrayFn: any) {
      for (let x = 0; x < ciphers.length; x++) {
        try {
          if (ciphers[x].name.startsWith(parent.cipherItemMap.blobbyTypeNotePrefix(type))) {
            const notes: string = ciphers[x].notes;
            const objTransactions = JSON.parse(notes);
            bResponse.add(castToArrayFn(objTransactions));
          }
        } catch (e) {
          parent.logService.error(e);
          parent.logService.info(JSON.stringify(ciphers[x], null, 2));
        }
      }
    }
  }

  // todo this got moved back to Bitwarden chipher service
  async save(
    blobbyType: BlobbyDataTypeEnum,
    items: GlossEncryptedDataType[],
    reloadInMemoryAfterSave = true
  ) {
    const bResponse = new BlobbyResponse(blobbyType);
    const batches = BlobbyUtils.getBatches(items);
    const batchName = new Date().getTime();
    let batchNumber = 1;

    for (const batchItems of batches) {
      this.totalItemsToProcess = batches.length;
      this.inProcessItem.next({ type: blobbyType, total: this.totalItemsToProcess });
      this.loadingItemReference.next(batchNumber);

      if (batchItems.length > 0) {
        this.logService.info(
          `[Blobby]:[${blobbyType}] Processing batch ${batchNumber} of ${batches.length}`
        );

        const cipherName =
          this.cipherItemMap.blobbyTypeNotePrefix(blobbyType) + "-" + batchName + "-" + batchNumber;
        await this.createCipherWithServer(blobbyType, cipherName, batchItems, bResponse);
        batchNumber++;
      }
    }

    if (reloadInMemoryAfterSave) {
      // can skip fullSync since createWithServer being called in createCipherWithServer will cache the cipher
      await this.refresh(true);
    }

    return bResponse;
  }

  async createToNewCipher(
    type: BlobbyDataTypeEnum,
    item: GlossEncryptedDataType
  ): Promise<GlossEncryptedDataType> {
    try {
      const batchName = new Date().getTime();
      const batchNumber = 1;
      const batchItems: GlossEncryptedDataType[] = [item];

      const cipherName =
        this.cipherItemMap.blobbyTypeNotePrefix(type) + "-" + batchName + "-" + batchNumber;
      await this.createCipherWithServer(type, cipherName, batchItems);
      // can skip fullSync since createWithServer being called in createCipherWithServer will cache the cipher
      await this.refresh(true);
      return item;
    } catch (e) {
      this.logService.error(e);
    }
  }

  private async createToExistingCipher(
    type: BlobbyDataTypeEnum,
    item: GlossEncryptedDataType,
    cipherView: CipherView
  ): Promise<GlossEncryptedDataType> {
    try {
      //what is already in the cipher
      const cipherItems = JSON.parse(cipherView.notes);

      //what is gonna be saved in the cipher now ... it can be output of a 'delete' , 'update' or 'create' flag
      const newCipherItems = this.cipherItems.getNewCipherItems(
        cipherItems,
        type,
        item,
        crudFlag.create
      );

      cipherView.notes = JSON.stringify(newCipherItems);

      const cipher = await this.cipherService.encrypt(cipherView);
      //update the cipher
      await this.cipherService.updateWithServer(cipher);

      //update the bram
      await this.bram.indexBramByType(type, newCipherItems);

      //Update item-cipher maps
      await this.cipherItemMap.resetMapByType(type);
      return item;
    } catch (e) {
      // TODO handle error a better way !!
      this.logService.error(e);
    }
  }

  // TODO some parts of the create , update and even delete method seems repeating . it can be better !!
  async create(
    type: BlobbyDataTypeEnum,
    item: GlossEncryptedDataType
  ): Promise<GlossEncryptedDataType> {
    try {
      // TODO I think we should make this.blobbyTypeNotePrefix(type) an enum it would be easier to reach it
      const lastCipherOfType = await this.cipherService.getLastCipherViewByPrefix(
        this.cipherItemMap.blobbyTypeNotePrefix(type)
      );

      const existingItems = lastCipherOfType ? JSON.parse(lastCipherOfType.notes) : [];
      // we name it possible because we might need to add this item to a new cipher or add it to this last cipher
      const possibleNewItemsArray = [...existingItems, item];

      const existingBatches = BlobbyUtils.getBatches(existingItems);
      const newBatches = BlobbyUtils.getBatches(possibleNewItemsArray);

      // TODO this number should be set somewhere and retrieved from there
      if (existingBatches.length !== newBatches.length || !lastCipherOfType) {
        return await this.createToNewCipher(type, item);
      } else {
        return await this.createToExistingCipher(type, item, lastCipherOfType);
      }
    } catch (e) {
      this.logService.error("somethingWentWrong");
    }
  }

  private async updateCipherView(
    cipherView: CipherView,
    newCipherItems: GlossEncryptedDataType[],
    blobbyType: BlobbyDataTypeEnum,
    item: GlossEncryptedDataType
  ) {
    cipherView.notes = JSON.stringify(newCipherItems);
    // encrypt cipherView to cipher
    const cipher = await this.cipherService.encrypt(cipherView);
    cipher.edit = true;
    //update the cipher
    await this.cipherService.updateWithServer(cipher);

    //update the bram
    this.bram.indexBramByType(blobbyType, newCipherItems);
    await this.cipherItemMap.resetMapByType(blobbyType);
    // can skip fullSync since updateWithServer will cache the cipher
    await this.refresh(true);
  }
  async updateItemWithAnotherCipher(
    blobbyType: BlobbyDataTypeEnum,
    item: GlossEncryptedDataType,
    cipherId: string
  ) {
    const ciphersOfType = await this.cipherService.getCipherViewsByPrefix(
      this.cipherItemMap.blobbyTypeNotePrefix(blobbyType)
    );
    const notProcessedCipherViews = ciphersOfType.filter((view) => view.id !== cipherId);
    let isUpdated = false;
    for (const cipherView of notProcessedCipherViews) {
      const cipherItems = JSON.parse(cipherView.notes);

      //get updated items array to save in the cipher
      const newCipherItems = this.cipherItems.getNewCipherItems(
        cipherItems,
        blobbyType,
        item,
        crudFlag.update
      );

      const existingBatches = BlobbyUtils.getBatches(cipherItems);
      const newBatches = BlobbyUtils.getBatches(newCipherItems);

      // TODO this number should be set somewhere and retrieved from there
      if (existingBatches.length === newBatches.length) {
        await this.updateCipherView(cipherView, newCipherItems, blobbyType, item);
        isUpdated = true;
        break;
      }
    }

    if (!isUpdated) {
      await this.create(blobbyType, item);
    }
  }
  async update(
    blobbyType: BlobbyDataTypeEnum,
    item: GlossEncryptedDataType
  ): Promise<GlossEncryptedDataType> {
    try {
      // get the cipher id from itemToCipherMap
      const itemToCipherMap: Map<string, string> = await this.cipherItemMap.getItemToCipherMap();
      const cipherId: string = itemToCipherMap.get(item.id);
      // get the cipherView the item belongs
      const cipherView = await this.cipherService.getCipherView(cipherId);

      //get items from cipher and turn into array
      const cipherItems = JSON.parse(cipherView.notes);

      //get updated items array to save in the cipher
      const newCipherItems = JSON.parse(
        JSON.stringify(
          this.cipherItems.getNewCipherItems(cipherItems, blobbyType, item, crudFlag.update)
        )
      );

      const existingBatches = BlobbyUtils.getBatches(cipherItems);
      const newBatches = BlobbyUtils.getBatches(newCipherItems);

      // TODO this number should be set somewhere and retrieved from there
      if (existingBatches.length < newBatches.length) {
        const cipherItemsWithoutItem: any = newCipherItems.filter(
          (cipherItem: any) => !(cipherItem.id && cipherItem.id === item.id)
        );
        await this.updateCipherView(cipherView, cipherItemsWithoutItem, blobbyType, item);

        await this.updateItemWithAnotherCipher(blobbyType, item, cipherId);
      } else {
        await this.updateCipherView(cipherView, newCipherItems, blobbyType, item);
        return item;
      }
    } catch (e) {
      this.logService.error("somethingWentWrong");
    }
  }

  /**
   * Delete All data for a collection
   * @param blobbyType
   */
  async purge(blobbyType: BlobbyDataTypeEnum): Promise<boolean> {
    try {
      const cipherViews: CipherView[] = await this.cipherService.getCipherViewsByPrefix(
        this.cipherItemMap.blobbyTypeNotePrefix(blobbyType)
      );

      for (const cipherView of cipherViews) {
        await this.cipherService.deleteWithServer(cipherView.id);
      }

      return true;
    } catch (e) {
      this.logService.error("somethingWentWrong");
    }
  }

  async bulkDelete(
    blobbyType: BlobbyDataTypeEnum,
    items: GlossEncryptedDataType[],
    toKeepItems: GlossEncryptedDataType[],
    processedCipherIds: string[]
  ): Promise<void> {
    if (items.length === 0) {
      if (toKeepItems.length > 0) {
        await this.save(blobbyType, toKeepItems);
      } else {
        await this.refresh();
      }
      return;
    }
    const cipherId: string = this.getCipherId(items[0]);
    const cipherItems = await this.getCipherNotes(cipherId);

    const toKeepItemsOfCipher = cipherItems.filter((cipherItem: any) => {
      const inItem = items.find((item: GlossEncryptedDataType) => item.id === cipherItem._id);

      if (!inItem) {
        return cipherItem;
      } else {
        items.splice(items.indexOf(inItem), 1);
      }
    });

    await this.cipherService.deleteWithServer(cipherId);
    toKeepItems.push(...toKeepItemsOfCipher);
    return await this.bulkDelete(blobbyType, items, toKeepItems, processedCipherIds);
  }

  private getCipherId(item: GlossEncryptedDataType): string {
    const itemToCipherMap: Map<string, string> = this.cipherItemMap.getItemToCipherMap();
    return itemToCipherMap.get(item.id);
  }
  private async getCipherNotes(cipherId: string): Promise<any> {
    // get the cipherView the item belongs
    const cipherView = await this.cipherService.getCipherView(cipherId);

    //get items from cipher and turn into array
    return JSON.parse(cipherView.notes);
  }

  async delete(blobbyType: BlobbyDataTypeEnum, item: GlossEncryptedDataType): Promise<boolean> {
    try {
      const cipherId: string = this.getCipherId(item);
      const cipherView = await this.cipherService.getCipherView(cipherId);
      //get items from cipher and turn into array
      const cipherItems = await this.getCipherNotes(cipherId);

      //get updated items array to save in the cipher
      const newCipherItems = this.cipherItems.getNewCipherItems(
        cipherItems,
        blobbyType,
        item,
        crudFlag.delete
      );
      if (newCipherItems.length === 0) {
        //delete the whole cipher cuz there is no element in it anymore
        await this.cipherService.deleteWithServer(cipherId);
      } else {
        cipherView.notes = JSON.stringify(newCipherItems);

        // encrypt cipherView to cipher
        const cipher = await this.cipherService.encrypt(cipherView);

        //update the cipher
        await this.cipherService.updateWithServer(cipher);
      }
      //update the bram
      this.bram.deleteItem(blobbyType, item);
      return true;
    } catch (e) {
      this.logService.error("somethingWentWrong");
    }
  }

  private async fetchInVaultItemById(id: string): Promise<string | null> {
    const cipherItem: CipherView = await this.cipherService.getCipherView(id);
    if (cipherItem == null) {
      return "";
    } else {
      return cipherItem.notes || "";
    }
  }

  private async initializePreferenceToDefault(): Promise<void> {
    const preferenceResponse = await this.getAll(BlobbyDataTypeEnum.Preference);
    const preference = preferenceResponse.preferences;
    if (!preference || preference.length === 0) {
      await this.setPreferencesToDefault();
      await this.syncService.fullSync(false);
      const blobbyResponsePreferences = await this.fetchInVaultByType(BType.Preference);
      this.bram.indexBramByType(BType.Preference, blobbyResponsePreferences.preferences);
    }
  }

  async initializeSystemScenarioGroup(): Promise<void> {
    const scenarioGroupsResponse = await this.getAll(BlobbyDataTypeEnum.ScenarioGroup);
    const systemScenarioGroup = scenarioGroupsResponse.scenarioGroups.find(
      (scenarioGroup) => scenarioGroup.source === ScenarioSource.system
    );
    if (!systemScenarioGroup || systemScenarioGroup.scenarios.length === 0) {
      await this.setDefaultSystemScenarioGroup();
      await this.syncService.fullSync(false);
      const blobbyResponseScenarioGroup = await this.fetchInVaultByType(BType.ScenarioGroup);
      this.bram.indexBramByType(BType.ScenarioGroup, blobbyResponseScenarioGroup.scenarioGroups);
    }
  }

  // todo need to clean that logic. The default object should be in the preference service
  //
  async setPreferencesToDefault(): Promise<void> {
    let basiqUserFromLocalStorage: BasiqUserType;
    try {
      const localUser = localStorage.getItem("basiqUser");
      basiqUserFromLocalStorage =
        localUser && localUser !== "undefined" ? JSON.parse(localUser) : null;
    } catch (e) {
      basiqUserFromLocalStorage = null;
    }

    const defaults = {
      basiqUser: basiqUserFromLocalStorage,
      weekDayStart: { Monday: 1 },
      YearMonthStart: { January: 1 },
      monthDayStart: 1,
      baseCurrency: GLOBAL_BASE_CURRENCY,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      dateFormat: dateFormats[0],
      mode: "Basic",
      syncStatus: {
        fixer: {
          startDate: null,
          endDate: null,
          symbols: [],
        },
        plaid: {
          startDate: null,
          endDate: null,
          symbols: [],
        },
        basiq: {
          startDate: null,
          endDate: null,
          symbols: [],
        },
        instrumentStore: {
          startDate: null,
          endDate: null,
          symbols: [],
        },
      } as SyncStatusCollectionType,
    };

    const defaultPreferences = new Preference(new PreferenceResponse(defaults));
    await this.create(BlobbyDataTypeEnum.Preference, defaultPreferences);
  }

  async setDefaultSystemScenarioGroup(): Promise<void> {
    await this.save(BlobbyDataTypeEnum.ScenarioGroup, [SystemDefaultScenarioGroup], false);
    await this.save(BlobbyDataTypeEnum.Scenario, SystemDefaultScenarios, true);
  }

  private createCipherView(
    type: BlobbyDataTypeEnum,
    cipherName: string,
    batchItems: any
  ): CipherView {
    const cipherView = new CipherView();
    cipherView.name = cipherName;
    // todo use something more compact
    cipherView.notes = JSON.stringify(batchItems);
    cipherView.folderId = BlobbyUtils.getFolderId(type);
    cipherView.collectionIds = [] as string[];
    cipherView.type = CipherType.SecureNote;
    cipherView.favorite = false;
    return cipherView;
  }

  private async createCipherWithServer(
    blobbyType: BlobbyDataTypeEnum,
    cipherName: string,
    batchItems: any,
    bResponse?: BlobbyResponse
  ) {
    try {
      const cipherView = this.createCipherView(blobbyType, cipherName, batchItems);
      this.logService.info(`UnEnc Note length ${String(cipherView.notes).length}`);
      const encCipher = await this.cipherService.encrypt(cipherView);
      this.logService.info(
        `Enc ${encCipher.id} Note length ${String(encCipher.notes.data).length}`
      );
      // since the cipher always has collectionIds so we can do it like this and createWithServer will cache the response
      await this.cipherService.createWithServer(encCipher);

      this.logService.info(
        `Enc ratio  ${String(cipherView.notes).length / String(encCipher.notes.data).length}`
      );

      this.logService.info(`Batch vault id ${encCipher.id}`);

      // todo update items with maybe generated stuff and so
      if (bResponse) {
        await bResponse.add(batchItems);
      }
    } catch (e) {
      //todo if error is 10000 char + reduce batch size and retry the batch
      this.logService.error(e);
    }
  }
}
