<div
  class="tw-inset-0 tw-flex tw-overflow-visible"
  role="dialog"
  aria-modal="true"
  aria-labelledby="removeBasiqConsentTitle"
>
  <div class="tw-relative tw-mx-auto tw-w-full" role="document">
    <form class="tw-flex tw-flex-col tw-gap-6 tw-bg-white">
      <div class="tw-flex md:tw-justify-between">
        <h1 class="tw-text-xl" id="removeBasiqConsentTitle">{{ "removeBasiqConsent" | i18n }}</h1>
      </div>
      <div class="tw-flex tw-flex-col tw-gap-6">
        <p class="tw-mb-0 tw-text-justify">{{ "removeBasiqConsentDesc" | i18n }}</p>
        <div
          class="tw-flex tw-h-auto tw-w-full tw-min-w-[350px] tw-flex-col tw-gap-2 tw-rounded-lg tw-border-[2px] tw-border-solid tw-border-danger tw-py-2 tw-px-4 md:tw-w-full md:tw-min-w-[300px]"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <h6 class="tw-mb-0">
              <svg class="tw-h-5 tw-w-5">
                <use xlink:href="images/sprite.svg#info-danger" />
              </svg>
            </h6>
            <div class="">
              <h6 class="tw-mb-0 tw-whitespace-nowrap tw-font-semibold tw-text-danger">
                {{ "warning" | i18n }}
              </h6>
            </div>
          </div>
          <div>
            <p class="tw-mb-0 tw-text-justify">{{ "removeBasiqConsentWarning" | i18n }}</p>
          </div>
        </div>

        <div
          *ngIf="!keepDataIn"
          class="tw-flex tw-h-auto tw-w-full tw-min-w-[350px] tw-flex-col tw-gap-2 tw-rounded-lg tw-border-[2px] tw-border-solid tw-border-danger tw-py-2 tw-px-4 md:tw-w-full md:tw-min-w-[300px]"
        >
          <div class="tw-flex tw-items-center tw-gap-2">
            <h6 class="tw-mb-0">
              <svg class="tw-h-5 tw-w-5">
                <use xlink:href="images/sprite.svg#info-danger" />
              </svg>
            </h6>
            <div class="">
              <h6 class="tw-mb-0 tw-whitespace-nowrap tw-font-semibold tw-text-danger">
                {{ "warning" | i18n }}
              </h6>
            </div>
          </div>
          <div>
            <p class="tw-mb-0 tw-text-justify">{{ "deleteBasiqDataWarning" | i18n }}</p>
          </div>
        </div>

        <app-user-verification [(ngModel)]="masterPassword" ngDefaultControl name="secret">
        </app-user-verification>
      </div>
      <div class="tw-w-full" id="keep-basiq-data-cb">
        <mat-checkbox [(ngModel)]="keepDataIn" name="keepDataIn" class="red-checkbox">
          {{ "keepExistingDataFromBasiq" | i18n }}
        </mat-checkbox>
      </div>
      <div class="tw-flex tw-justify-around tw-gap-6 md:tw-flex-col md:tw-gap-4">
        <app-gloss-button
          class="tw-w-full"
          [options]="{
            text: 'removeBasiqConsent' | i18n,
            type: 'submit',
            class: 'danger-invert',
            onClick: submit.bind(this),
            isEnabled: !deleting
          }"
        ></app-gloss-button>

        <app-gloss-button
          type="button"
          class="tw-w-full"
          [options]="{
            text: 'close' | i18n,
            class: 'neutral',
            isEnabled: true,
            onClick: closeForm.bind(this)
          }"
        ></app-gloss-button>
      </div>
    </form>
  </div>
</div>
