import { ModelValidator } from "@bitwarden/web-vault/app/models/data/blobby/model.validator";

import { ConnectionInfoType } from "../../types/connector.type";
import { AccountState, Origin } from "../../types/general-types";
import { ConnectorResponse } from "../response/connector.response";

import { Institution } from "./institution.data";

export class Connector extends ModelValidator {
  private readonly __v = 1;
  private _id: string;
  private _name: string;
  private _origin: Origin;
  private _institution: Institution[];
  private _connectionInfo: ConnectionInfoType;
  accountStatus: AccountState[] = [];

  constructor(connectorObj: ConnectorResponse) {
    super();
    if (connectorObj == null) {
      return;
    }
    this.checkVersion(this.__v, connectorObj.__v);
    this._id = connectorObj.id ? connectorObj.id : crypto.randomUUID();
    this._name = connectorObj.name;
    this._origin = <Origin>connectorObj.origin;
    this._institution = connectorObj.institution;
    this._connectionInfo = connectorObj.connectionInfo;
    this.accountStatus = connectorObj.accountStatus;
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
  }

  get origin(): Origin {
    return this._origin;
  }
  set origin(value: Origin) {
    this._origin = value;
  }

  get institution(): Institution[] {
    return this._institution;
  }

  get connectionInfo(): ConnectionInfoType {
    return this._connectionInfo;
  }
  set connectionInfo(value: ConnectionInfoType) {
    this._connectionInfo = value;
  }
}
