export enum BasiqLocalStorage {
  basiqAccessToken = "basiqAccessToken",
  basiqUserToken = "basiqUserAccessToken",
  basiqUser = "basiqUser",
}

export enum BasiqConsentEnum {
  active = "active",
  revoked = "revoked",
  expired = "expired",
}
