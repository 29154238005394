<div
  class="tw-mb-[5px] tw-flex tw-h-[70px] tw-items-center tw-justify-between tw-rounded-xl tw-bg-white tw-px-10 xs:tw-px-5"
>
  <button *ngIf="showBackButton" (click)="handleBack()" class="btn-icon" data-testid="back-button">
    <svg class="tw-h-7 tw-w-7">
      <use xlink:href="images/sprite.svg#left-arrow" />
    </svg>
  </button>

  <app-help-tooltip
    [ngClass]="{ 'tw-hidden': !enableTooltip }"
    [showHelpTooltip]="tooltip.showHelpTooltip"
    [tooltipInfo]="tooltipInfo"
    [pointer]="'modal-header'"
    (onTooltipClose)="tooltip.close()"
  ></app-help-tooltip>

  <div
    class="tw-flex tw-items-center"
    [ngClass]="{ 'tw-w-full tw-justify-center': !showBackButton }"
  >
    <span class="tw-text-2xl xs:tw-text-lg">
      {{ title }}
    </span>
    <div *ngIf="enableTooltip" title="help" (click)="tooltip.show($event)">
      <tooltip-icon
        [isClicked]="tooltip.isClicked"
        [size]="4"
        id="help-info-button-modal"
      ></tooltip-icon>
    </div>
  </div>
  <close-button [class]="'icon-button'"></close-button>
</div>
