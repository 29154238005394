export class HelperPagination {
  /**
   * A Pagination helper function to determine if pagination is enabled.
   */
  isPaginationEnabled() {
    return this.enabledPaginationDevice();
  }

  /**
   * Enable the pagination for desktop and tablet devices.
   * Always true for now.
   */
  private enabledPaginationDevice() {
    return true;
  }
}
