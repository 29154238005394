<label
  class="tw-mb-1 tw-block tw-w-auto tw-flex-auto tw-items-center tw-font-medium tw-text-neutral-800"
  [attr.for]="input.labelForId"
>
  <ng-content select="bit-label"></ng-content>
  <span *ngIf="input.required" class="tw-text-base tw-font-medium"> *</span>
</label>
<div class="tw-relative tw-flex">
  <div *ngIf="prefixChildren.length" class="tw-flex">
    <ng-content select="[bitPrefix]"></ng-content>
  </div>
  <ng-content></ng-content>
  <div
    *ngIf="suffixChildren.length"
    class="tw-absolute tw-right-0 tw-z-20 tw-h-full tw-border-none tw-bg-transparent"
  >
    <ng-content select="[bitSuffix]"></ng-content>
  </div>
</div>
<ng-container [ngSwitch]="input.hasError">
  <ng-content select="bit-hint" *ngSwitchCase="false"></ng-content>
  <bit-error [error]="input.error" *ngSwitchCase="true"></bit-error>
</ng-container>
