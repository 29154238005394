import { Component, Input } from "@angular/core";
type CloseButtonType = "plain" | "square";

@Component({
  selector: "close-button",
  templateUrl: "./close-button.component.html",
})
export class CloseButtonComponent {
  @Input() closeButtonType: CloseButtonType;
}
