<div class="tw-flex tw-h-4 tw-overflow-hidden tw-rounded-full tw-text-xs tw-leading-none">
  <div
    class="tw-bg-primary {{
      color
    }} tw-flex tw-flex-col tw-justify-center tw-overflow-hidden tw-text-white tw-text-center tw-whitespace-nowrap tw-transition-width tw-duration-600 tw-eas"
    role="progressbar"
    [ngStyle]="{ width: scoreWidth + '%' }"
    attr.aria-valuenow="{{ scoreWidth }}"
    aria-valuemin="0"
    aria-valuemax="100"
  >
    <ng-container *ngIf="showText && text">
      {{ text }}
    </ng-container>
  </div>
</div>
