import { GlobalService } from "@bitwarden/common/src/services/global/global.service";

import { IFileValidator } from "../../models/interfaces/file-validator.interface";

import { FileValidationRule } from "./file-validation-rule";

export class FileValidator implements IFileValidator {
  private rules: FileValidationRule;
  errorMessage: string | null = null;
  fileName = "";

  constructor(private globalService: GlobalService) {
    this.rules = new FileValidationRule();
  }

  validate(file: File): boolean {
    if (!this.rules.isValidType(file)) {
      this.fileName = "";
      this.errorMessage = "fileTypeNotAllowed";
      return false;
    }

    if (!this.rules.isValidSize(file)) {
      this.globalService.showErrorMessage("errorOccurred", "fileSizeExceedsLimit");
      return false;
    }

    return true;
  }
}
