import { Injectable } from "@angular/core";

import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { GlossBalance } from "@bitwarden/web-vault/app/models/data/shared/gloss-balance";
import { InstitutionAccount } from "@bitwarden/web-vault/app/models/types/institution.type";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";

/**
 * @name ScenarioMessagesService
 * @description This service is responsible for setting the messages for the scenario when there are no rows to display.
 * @class ScenarioMessagesService
 * */
@Injectable({
  providedIn: "root",
})
export class ScenarioMessagesService {
  private _accounts: Book[] = [];
  private _institutions: Institution[] = [];
  private _institutionAccounts: InstitutionAccount[] = [];
  private _isInitialized: boolean;
  private baseCurrency: string;
  constructor(
    private bookService: BookService,
    private institutionService: InstitutionService,
    private dashboardService: DashboardService,
    private transactionService: TransactionService,
    private preferencesService: PreferenceService
  ) {}

  set isInitialized(isInitialized: boolean) {
    this._isInitialized = isInitialized;
  }

  get isInitialized() {
    return this._isInitialized;
  }

  set accounts(accounts: Book[]) {
    this._accounts = accounts;
  }

  get accounts() {
    return this._accounts;
  }

  set institutions(institutions: Institution[]) {
    this._institutions = institutions;
  }

  get institutions() {
    return this._institutions;
  }

  private addInstitution(institution: Institution) {
    const exist = this.institutions.find((inst) => inst.id === institution.id);
    if (!exist) {
      this._institutions.push(institution);
    }
  }

  set institutionAccounts(institutionAccounts: InstitutionAccount[]) {
    this._institutionAccounts = institutionAccounts;
  }

  get institutionAccounts() {
    return this._institutionAccounts;
  }

  private addInstitutionAccount(institutionAccount: InstitutionAccount) {
    const exist = this.institutionAccounts.find((inst) => inst.id === institutionAccount.id);
    if (!exist) {
      this._institutionAccounts.push(institutionAccount);
    }
  }

  private async initialize() {
    const preference = this.preferencesService.getPreferenceInstance();
    this.baseCurrency = preference.baseCurrency;
    this.accounts = await this.bookService.getAll();
    this.isInitialized = true;
  }

  /**
   * @description This method is responsible for resetting the institutions, institution accounts and accounts after message is set.
   * */
  reset() {
    this.institutions = [];
    this.institutionAccounts = [];
    this.accounts = [];
    this.isInitialized = false;
  }

  /**
   * @description Checks if there are any Interest Rates in the system, so we can calculate teh earnings on.
   * */
  async hasInterestRate() {
    if (!this.isInitialized) {
      await this.initialize();
    }

    for (const account of this.accounts) {
      const { institutionId, institutionAccountId } = account.institutionLink || {};
      const institution = await this.institutionService.getInstitutionById(institutionId);
      this.addInstitution(institution);
      const institutionAccount = await this.institutionService.filterInstitutionAccountById(
        institutionAccountId,
        institution
      );
      this.addInstitutionAccount(institutionAccount);
      if (institutionAccount?.interestRates.length > 0) {
        return true;
      }
    }

    return false;
  }

  /**
   * @description This method is responsible for getting the predefined message to fill the message box when user clicks 'Notify us' button.
   * @returns {string} Returns the predefined message.
   * @memberof ScenarioMessagesService
   * */
  getPredefinedMessage() {
    let accountsAndInstitutionsPart = "";
    for (const institutionAccount of this.institutionAccounts) {
      const institution = this.institutions.find((inst) =>
        inst.availableAccounts.find((acc) => acc.id === institutionAccount.id)
      );

      accountsAndInstitutionsPart += `Account : ${institutionAccount?.name} & Institution : ${institution?.name},\n`;
    }

    return `I have an issue of interest rates for the following accounts & institutions:\n${accountsAndInstitutionsPart} Please help me to add the interest rates.`;
  }

  /**
   *  @description Given a balance grouping, this method checks if the balance grouping has interest earnings.
   *  @param {BalanceGrouping} balanceGrouping The balance grouping to check for interest earnings.
   *  @returns {boolean} Returns true if the balance grouping has interest earnings, otherwise false.
   * */
  hasInterestEarnings(balanceGrouping: BalanceGrouping) {
    const lastMonthKey = Object.keys(balanceGrouping.granularity["month"]).pop();
    const firstMonthKey = Object.keys(balanceGrouping.granularity["month"])[0];
    const monthGranularity = balanceGrouping.granularity["month"];
    const lastMonthBalance = monthGranularity[lastMonthKey].balance.getActualAmountOfSymbol(
      this.baseCurrency
    );
    const firstMonthBalance = monthGranularity[firstMonthKey].balance.getActualAmountOfSymbol(
      this.baseCurrency
    );

    return lastMonthBalance > firstMonthBalance;
  }

  // TODO: Clean this bit after talking to Michelle
  async getAccountsCount(
    balanceGrouping: BalanceGrouping
  ): Promise<{ positiveAccountsCount: number; accountsCount: number }> {
    let positiveAccountsCount = 0;
    let accountsCount = 0;
    const scenarioEndDateKey = Object.keys(balanceGrouping.granularity["day"]).pop();
    const accountNodes = balanceGrouping.granularity["day"][scenarioEndDateKey].children["account"];
    for (const accountNodesKey in accountNodes) {
      if (this.dashboardService.anchorPointBalance) {
        if (this.dashboardService.anchorPointBalance[accountNodesKey]) {
          const accountStartingBalance: GlossBalance =
            this.dashboardService.anchorPointBalance[accountNodesKey].balance;
          // since we try to move money around for the best option, we can not get the balance from BalanceGrouping as it gets the updated version.
          const accountCurrentBalance: number = accountStartingBalance.getActualAmountOfSymbol(
            this.baseCurrency
          );
          accountsCount++;
          if (accountCurrentBalance > 0) {
            positiveAccountsCount++;
          }
        }
      } else {
        const account = await this.bookService.get(accountNodesKey);
        if (account) {
          // since we try to move money around for the best option, we can not get the balance from BalanceGrouping as it gets the updated version.
          const accountCurrentBalance = await this.transactionService.getAccountBalance(account);
          accountsCount++;
          if (accountCurrentBalance[this.baseCurrency] > 0) {
            positiveAccountsCount++;
          }
        }
      }
    }

    return { positiveAccountsCount, accountsCount };
  }
}
