import { Component, Input } from "@angular/core";

@Component({
  selector: "app-gloss-callout",
  templateUrl: "./gloss-callout.component.html",
})
export class GlossCalloutComponent {
  @Input() title = "";
  @Input() body = "";
}
