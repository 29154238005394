import { Component, DoCheck, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSidenav } from "@angular/material/sidenav";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { StepModel } from "@bitwarden/web-vault/app/components/account-wizard-stepper/step.model";
import { WizardService } from "@bitwarden/web-vault/app/components/account-wizard-stepper/wizard-stepper-service";
import { EditBalanceInWizardComponent } from "@bitwarden/web-vault/app/components/edit-balance-in-wizard/edit-balance-in-wizard.component";
import { Wizard } from "@bitwarden/web-vault/app/models/data/blobby/wizard.data";
import { BlobbyService } from "@bitwarden/web-vault/app/services/blobby/blobby.service";
import { SideMenuService } from "@bitwarden/web-vault/app/services/menu/side-menu.service";
import {
  DeviceDetection,
  DeviceDetector,
} from "@bitwarden/web-vault/app/shared/utils/device-detector";

@Component({
  selector: "app-user-layout",
  templateUrl: "user-layout.component.html",
})
export class UserLayoutComponent implements OnInit, DoCheck, OnDestroy {
  accountBalanceRef: MatDialogRef<EditBalanceInWizardComponent>;
  isGlossMenuOpen$ = this.glossMenuService.isGlossMenuOpen$;
  @ViewChild("glossMenu") glossMenu: MatSidenav;
  private ngUnsubscribe = new Subject<void>();
  deviceDetector: DeviceDetection;
  helpOpen = false;
  steps: StepModel[] = [];
  hasNoAccounts = false;
  hasNoTransaction = false;
  isWizardInitialise = false;
  isOpenWizard = false;
  private destroy$ = new Subject<void>();
  constructor(
    private glossMenuService: SideMenuService,
    private blobbyService: BlobbyService,
    private router: Router,
    private wizardService: WizardService,
    public dialog: MatDialog
  ) {
    this.deviceDetector = new DeviceDetector();
  }
  async ngOnInit() {
    document.body.classList.remove("layout_frontend");

    this.wizardService
      .getIsWizardComplete()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: (isWizardCompleted) => {
          if (this.isWizardInitialise) {
            this.isOpenWizard = !isWizardCompleted;
          }
        },
      });

    this.glossMenuService.shouldRunWizard$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(async ({ shouldRunWizard, resetBalance }) => {
        if (shouldRunWizard && !resetBalance) {
          this.router.navigate(["/dashboard"]).then(async () => {
            this.wizardService.restartWizardInProgress();
            this.isWizardInitialise = false;
            await this.openWizard(false);
          });
        } else if (shouldRunWizard && resetBalance) {
          await this.openReBalanceForm();
        }
      });
  }

  private async openReBalanceForm() {
    this.accountBalanceRef = this.dialog.open(EditBalanceInWizardComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialog: () => this.accountBalanceRef.close(),
      },
      disableClose: true,
    });
    this.accountBalanceRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.wizardService.endWizard();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.wizardService.resetWizardService();
  }

  async ngDoCheck() {
    if (this.blobbyService.getInitialised() && !this.isWizardInitialise) {
      const itemCountInstance = this.blobbyService.getItemCountService().getItemCountInstance();
      this.hasNoTransaction = itemCountInstance.getTotalTransaction() === 0;
      this.hasNoAccounts = itemCountInstance.getTotalAccount() === 0;

      this.isWizardInitialise = true;
      await this.openWizard(true);
    }
  }

  handleHelpBoxOpen($event: boolean) {
    this.helpOpen = $event;
  }

  //TODO @Brian - will convert it to reactive flag
  async openWizard(isFirst: boolean) {
    let wizardMemory: Wizard[];
    if (isFirst) {
      wizardMemory = this.blobbyService.getWizardData();
    } else {
      wizardMemory = await this.wizardService.getBlobbyWizardArray();
    }
    if (this.hasNoAccounts || this.hasNoTransaction || wizardMemory.length > 0) {
      this.wizardService.setIsWizardInProgress(true);
      this.isOpenWizard = true;
    } else {
      this.isOpenWizard = false;
    }
  }
}
