import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ICellRendererParams } from "ag-grid-community";

@Component({
  selector: "app-transaction-date-grouping-cell-renderer",
  templateUrl: "./transaction-date-cell-renderer.component.html",
})
export class TransactionDateCellRender implements ICellRendererAngularComp {
  params!: ICellRendererParams;
  paddingLeft!: number;
  isGroup!: boolean;
  rotation!: string;
  info!: string;

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.paddingLeft = params.node.level * 15;
    this.isGroup = !!params.node.group;
    this.rotation = "";
    this.info = params.node.key;
    this.params.node.addEventListener("expandedChanged", this.onExpand);
  }

  refresh(params: ICellRendererParams) {
    return false;
  }

  destroy() {
    this.params.node.removeEventListener("expandedChanged", this.onExpand);
  }

  onClick() {
    this.params.node.setExpanded(!this.params.node.expanded);
  }

  onExpand = () => {
    this.rotation = this.params.node.expanded ? "rotate(90deg)" : "rotate(0deg)";
  };
}
