import { ConfirmationData } from "../../models/types/general-types";

export const confirmationMapper: ConfirmationData = {
  deletingVaultFile: {
    title: "areYouSureYouWannaDeleteThisFile",
    message: "deleteFileDesc",
    type: "warning",
  },

  usingWeakPassword: {
    title: "weakMasterPassword",
    message: "weakMasterPasswordDesc",
    type: "info",
  },

  deletingAccountWithGeneratedMessage: {
    title: "deleteAccount",
    message: "$GENERATED$",
    type: "warning",
  },

  deletingAccount: {
    title: "deleteAccount",
    message: "deleteAccountConfirmMessageNoTransactions",
    type: "warning",
  },

  deletingTransaction: {
    title: "areYouSureToDeleteTransactions",
    message: "",
    type: "warning",
  },

  usingDefaultInstitution: {
    title: "fileNotBelongingInstitution",
    message: "",
    type: "warning",
  },

  givingFirstConsent: {
    title: "wouldYouLikeToMakeAFirstConsent",
    message: "",
    type: "info",
  },

  havingNoChanges: {
    title: "noChangesWereMade",
    message: "$GENERATED$",
    type: "warning",
  },

  updatingBalance: {
    title: "wouldYouLikeToUpdateYouBalance",
    message: "$GENERATED$",
    type: "warning",
  },

  deletingEstimate: {
    title: "areYouSureYouWannaDeleteThisEstimate",
    message: "",
    type: "warning",
  },

  deletingCategory: {
    title: "areYouSureYouWannaDeleteThisCategory",
    message: "",
    type: "warning",
  },

  deletingClassification: {
    title: "areYouSureYouWannaDeleteThisClassification",
    message: "",
    type: "warning",
  },

  deletingInstitution: {
    title: "areYouSureYouWannaDeleteThisInstitution",
    message: "",
    type: "warning",
  },

  askingForEarlyAccess: {
    title: "wouldYouLikeToGetEarlyAccess",
    message: "stillInDevelopmentFeature",
    type: "info",
  },

  removingBasiqDataOfRevokedConsent: {
    title: "consentRevoked",
    message: "wouldYouLikeToDeleteRevokedConsentData",
    type: "warning",
  },

  askingForReConsentOfExpired: {
    title: "consentExpired",
    message: "wouldYouLikeToReConsentOfExpired",
    type: "warning",
  },

  askingForReConsentOfRevoked: {
    title: "consentRevoked",
    message: "wouldYouLikeToReConsentOfRevoked",
    type: "warning",
  },

  removingBasiqDataOfExpiredConsent: {
    title: "consentExpired",
    message: "wouldYouLikeToDeleteExpiredConsentData",
    type: "warning",
  },

  importError: {
    title: "importError",
    message: "importErrorDesc",
    type: "error",
  },

  logOutConfirmation: {
    title: "logOut",
    message: "logOutConfirmation",
    type: "warning",
  },
};
