import { Injectable, Injector } from "@angular/core";
import { defer, Observable } from "rxjs";

import { LogService } from "@bitwarden/common/abstractions/log.service";
import { ReferenceData } from "@bitwarden/web-vault/app/models/data/blobby/reference-data.data";
import { ReferenceDataResponse } from "@bitwarden/web-vault/app/models/data/response/reference-data-response";
import { TransactionResponse } from "@bitwarden/web-vault/app/models/data/response/transaction-response";
import { GlossQuantity } from "@bitwarden/web-vault/app/models/data/shared/gloss-quantity";
import { DateFormatPipe } from "@bitwarden/web-vault/app/pipes/date-format.pipe";
import { ReferenceDataCalculationService } from "@bitwarden/web-vault/app/services/DataCalculationService/reference-data/reference-data.calculation.service";
import { TransactionNormalizeService } from "@bitwarden/web-vault/app/services/DataCalculationService/transaction/transaction.normalize.service";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { MarketDataService } from "@bitwarden/web-vault/app/services/DataService/market-data/market-data.service";
import { StateManagementService } from "@bitwarden/web-vault/app/services/DataService/state-management/state-management-service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { PerformanceService } from "@bitwarden/web-vault/app/services/performance/performance.service";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper-preference";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

import { Transaction } from "../../../models/data/blobby/transaction.data";
import { Valuation } from "../../../models/data/valuation.data";
import { TransactionDirection } from "../../../models/enum/transactionDirection";
import { StringToNumber } from "../../../models/types/market-data-types";
import { SymbolService } from "../../DataService/symbol/symbol.service";
import { BlobbyService } from "../../blobby/blobby.service";
import { AllocationService } from "../allocation/allocation.service";
import { CalculationServiceAbstraction } from "../calculation.service.abstraction";

@Injectable({
  providedIn: "root",
})
export class RevaluationService implements CalculationServiceAbstraction {
  projectedRevals: Array<Transaction>;

  baseCurrency: string;
  private _inProcessTransaction: Transaction;
  private _nextSymbolTransaction: Transaction;
  private _quantityBalanceRegistry: StringToNumber = {};
  private _quantityRegistry: StringToNumber = {};
  private _transactionSymbolMarketData: ReferenceData[];
  private _inProcessMarketData: ReferenceData;
  private _inProcessMarketDataIndex: number;
  private _tillDate: string;
  private _previousTransactionsRegistry: Record<string, Transaction> = {};
  private stateManagementService: StateManagementService;

  private set tillDate(tillDate: string) {
    this._tillDate = tillDate;
  }
  private get tillDate(): string {
    return this._tillDate;
  }
  private set nextSymbolTransaction(transaction: Transaction) {
    this._nextSymbolTransaction = transaction;
  }

  private get nextSymbolTransaction(): Transaction {
    return this._nextSymbolTransaction;
  }

  private set quantityBalanceRegistry(quantityBalanceRegistry: StringToNumber) {
    this._quantityBalanceRegistry = quantityBalanceRegistry;
  }

  private get quantityBalanceRegistry(): StringToNumber {
    return this._quantityBalanceRegistry;
  }

  private set quantityRegistry(quantityRegistry: StringToNumber) {
    this._quantityRegistry = quantityRegistry;
  }

  private get quantityRegistry(): StringToNumber {
    return this._quantityRegistry;
  }

  private get transactionSymbolMarketData(): ReferenceData[] {
    return this._transactionSymbolMarketData;
  }

  private set transactionSymbolMarketData(marketData: ReferenceData[]) {
    this._transactionSymbolMarketData = marketData;
  }

  private set inProcessMarketDataIndex(index: number) {
    this._inProcessMarketDataIndex = index;
  }

  private get inProcessMarketDataIndex(): number {
    return this._inProcessMarketDataIndex;
  }

  private set previousTransactionsRegistry(registry: Record<string, Transaction>) {
    this._previousTransactionsRegistry = registry;
  }

  private get previousTransactionsRegistry(): Record<string, Transaction> {
    return this._previousTransactionsRegistry;
  }

  private set inProcessTransaction(transaction: Transaction) {
    this._inProcessTransaction = transaction;
  }

  private get inProcessTransaction(): Transaction {
    return this._inProcessTransaction;
  }

  private set inProcessMarketData(marketData: ReferenceData) {
    this._inProcessMarketData = marketData;
  }

  private get inProcessMarketData(): ReferenceData {
    return this._inProcessMarketData;
  }

  private dateFormat: DateFormat;
  private transactionNormalizeService: TransactionNormalizeService;
  /**
   * @param transactionService
   * @param blobbyService
   * @param symbolService
   * @param allocationService
   * @param bookService
   * @param marketDataService
   * @param referenceDataCalculationService
   * @param helperPreference
   * @param injector
   * @param logger
   * @param perfService
   */
  constructor(
    private transactionService: TransactionService,
    /** @deprecated **/ private blobbyService: BlobbyService,
    private symbolService: SymbolService,
    private allocationService: AllocationService,
    private bookService: BookService,
    private marketDataService: MarketDataService,
    private referenceDataCalculationService: ReferenceDataCalculationService,
    private helperPreference: HelperPreference,
    private injector: Injector,
    private logger: LogService,
    private perfService: PerformanceService
  ) {}

  resetRegistries() {
    this.quantityRegistry = {};
    this.quantityBalanceRegistry = {};
    this.previousTransactionsRegistry = {};
    this.tillDate = undefined;
  }

  /** generates an array of transactions that has all the original transactions and their revaluation transactions */
  async generateRevaluations(
    ofTransactions?: Transaction[],
    tillDate?: string
  ): Promise<Transaction[]> {
    this.perfService.mark("RevaluationService::generateRevaluations");
    /** This tells the reference service to call reference service at least one time to get the most updated references*/
    this.referenceDataCalculationService.triggerRefresh();
    const transactions =
      ofTransactions && ofTransactions.length
        ? ofTransactions
        : await this.transactionService.getAll();

    const symbols = await this.marketDataService.getCurrenciesInSystem();
    if (symbols.length < 2) {
      return transactions;
    }
    this.dateFormat = this.injector.get(DateFormat);
    if (tillDate && this.dateFormat.isDateStringValid(tillDate)) {
      this.tillDate = tillDate;
    }
    await this.setDefaultCurrency();

    let revaluations: Transaction[] = [];

    for (const transaction of transactions) {
      this.inProcessTransaction = transaction;
      this.nextSymbolTransaction = this.getNextTransactionOfSymbol(transactions);
      this.setSymbolQuantityRegistry();
      this.setPreviousTransaction();
      const transactionRevaluations = await this.getTransactionRevaluations();

      /** Add all the revaluations have been generated for the transaction to the revaluations array */
      revaluations = [...revaluations, ...transactionRevaluations];
      /** Todo @Sinan : check to combine quantityBalanceRegistry with quantityRegistry. It will make the code simpler */
      const isFirstReval = this.transactionSymbolMarketData.length === 0;
      if (isFirstReval) {
        this.setSymbolQuantityBalanceRegistry();
      }
    }

    this.resetRegistries();
    return revaluations;
  }

  private async setDefaultCurrency() {
    const prefBase = await this.helperPreference.getBaseCurrency();
    if (this.baseCurrency !== prefBase) {
      this.baseCurrency = prefBase;
    }
  }

  private getNextTransactionOfSymbol(transactions: Transaction[]) {
    const currentTransaction = this.inProcessTransaction;
    const currentTransactionDate = currentTransaction.transactionDate.date.getTime();
    let currentIndex: number = null;
    return transactions.find((transaction, index) => {
      const transactionDate = transaction.transactionDate.date.getTime();
      /** condition for multiple(>2) transaction of the same date */
      if (currentTransaction.id === transaction.id) {
        currentIndex = index;
      }
      return (
        transactionDate >= currentTransactionDate &&
        transaction.quantity.actualQuantity.symbol ===
          currentTransaction.quantity.actualQuantity.symbol &&
        transaction.id !== currentTransaction.id &&
        currentIndex !== null &&
        currentIndex < index
      );
    });
  }

  /** Set the total quantity of a symbol up to the point of the transaction in process before processed */
  /**Todo @Sinan combine this with updateQuantityBalanceRegistry */
  private setSymbolQuantityRegistry() {
    const { amount, symbol } = this.inProcessTransaction.quantity.actualQuantity;
    const newRegister = { ...this.quantityRegistry };
    const symbolInRegister = this.quantityRegistry[symbol] !== undefined;
    if (!symbolInRegister) {
      newRegister[symbol] = amount;
    } else {
      if (this.inProcessTransaction.direction === TransactionDirection.Out) {
        newRegister[symbol] = newRegister[symbol] - amount;
      } else {
        newRegister[symbol] = newRegister[symbol] + amount;
      }
    }

    this.quantityRegistry = newRegister;
  }

  /**Todo @Sinan combine this with setQuantityRegistry */
  private setSymbolQuantityBalanceRegistry() {
    const transaction = this.inProcessTransaction;
    const symbol = transaction.quantity.actualQuantity.symbol;
    const direction = transaction.direction === TransactionDirection.In ? 1 : -1;
    const newBalanceRegistry = { ...this.quantityBalanceRegistry };
    if (this.quantityBalanceRegistry[symbol]) {
      newBalanceRegistry[symbol] =
        newBalanceRegistry[symbol] + direction * transaction.quantity.actualQuantity.amount;
    } else {
      newBalanceRegistry[symbol] = direction * transaction.quantity.actualQuantity.amount;
    }

    this.quantityBalanceRegistry = newBalanceRegistry;
  }

  private getCorrectPreviousRate(previousTransaction: Transaction) {
    /** Todo @Alex @Sinan @Michelle When the quantity.consym is not base currency the currency rate is being saved as 1/conversionRate... This normal ?*/
    const isFlipped = previousTransaction.quantity.convsym !== this.baseCurrency;
    const previousNrmRate = previousTransaction.valuation.normalizingRate;
    const isCreatedRate = previousTransaction.revalTransaction;
    if (isCreatedRate) {
      return previousNrmRate;
    } else {
      if (!isFlipped) {
        return previousNrmRate;
      } else {
        return 1 / previousNrmRate;
      }
    }
  }

  private getCurrencyCarryOverRevaluation() {
    this.perfService.mark("RevaluationService::getCurrencyCarryOverRevaluation");

    try {
      const { symbols } = this.inProcessMarketData;
      const previousTransaction = this.getActivePreviousTransaction(true);
      const transaction = this._inProcessTransaction;
      const currency = previousTransaction.quantity.currency;
      const symbol = previousTransaction.quantity.actualQuantity.symbol;
      const carryOverQuantity = this.quantityBalanceRegistry[symbol];
      const currentRate = symbols[currency];
      const previousRate = this.getCorrectPreviousRate(previousTransaction);
      const valuationPrice = !this.isSymbolACurrency(transaction)
        ? previousTransaction.valuation.symbolValue
        : 1;
      const revaluatedAmount =
        carryOverQuantity * valuationPrice * (1 / currentRate - 1 / previousRate);
      const directionMultiplier = carryOverQuantity > 0 ? 1 : -1;
      const direction =
        revaluatedAmount * directionMultiplier > 0
          ? TransactionDirection.In
          : TransactionDirection.Out;

      const valuation = new Valuation();
      valuation.setValueAmountSymbol(0, currency);
      // valuation.setValueSymbol(currency);
      valuation.symbolValue = currentRate;
      valuation.symbolValuePrior = previousRate;
      valuation.setNormalizedAmountSymbol(revaluatedAmount, this.baseCurrency);
      valuation.normalizingRate = currentRate;
      valuation.normalizingRatePrior = previousRate;

      const transactionData = {
        ...previousTransaction,
        _id: crypto.randomUUID(),
        _transactionDate: this.transactionSymbolMarketData.length
          ? this.transactionSymbolMarketData[this.inProcessMarketDataIndex].date.date
          : this.inProcessMarketData.date.date,
        description: `End of day Revaluation ${currency}/${this.baseCurrency}@${valuation.normalizingRate}`,
        Direction: direction,
        valuationPrice: currentRate,
        // _balance: balance,
        _quantity: {
          _symbol: symbol,
          _currency: currency,
          _actualQuantity: 0, // revals have zero quantity,
          _convrate: symbols[currency],
          _convsym: this.baseCurrency,
        },
        _valuation: valuation,
      };
      const ccyReval = new Transaction(new TransactionResponse(transactionData));
      ccyReval.setGlossBalance();
      ccyReval.revalTransaction = true;

      return [ccyReval];
    } catch (e) {
      this.logger.error(e);
    } finally {
      this.perfService.markEnd();
    }
  }

  /** CarryOverRevaluation :The revaluation for the total quantity of the symbol that has been passed down to the date but not revaluated yet */
  private async getSymbolCarryOverRevaluation(): Promise<Transaction[]> {
    this.perfService.mark("RevaluationService::getSymbolCarryOverRevaluation");

    const { base, symbols, date } = this.inProcessMarketData;
    const previousTransaction = this.getActivePreviousTransaction(true);
    const currency = this.inProcessTransaction.quantity.currency;
    const symbol = this.inProcessTransaction.quantity.actualQuantity.symbol;
    const carryOverQuantity = this.quantityBalanceRegistry[symbol];
    const currentSymbolValue = base === symbol ? symbols[currency] : symbols[symbol];
    const previousSymbolValue = previousTransaction.valuation.symbolValue;
    const previousRate = this.getCorrectPreviousRate(previousTransaction);
    const directionMultiplier = carryOverQuantity > 0 ? 1 : -1;
    const revaluatedAmount =
      (carryOverQuantity * (currentSymbolValue - previousSymbolValue)) / previousRate;
    const direction =
      revaluatedAmount * directionMultiplier > 0
        ? TransactionDirection.In
        : TransactionDirection.Out;
    const valuation = new Valuation();

    valuation.setValueAmountSymbol(0, currency);
    // valuation.setValueSymbol(currency);
    valuation.symbolValue = currentSymbolValue;
    valuation.symbolValuePrior = previousSymbolValue;
    valuation.setNormalizedAmountSymbol(revaluatedAmount, this.baseCurrency);
    valuation.normalizingRate = previousRate;
    valuation.normalizingRatePrior = previousTransaction.valuation.normalizingRatePrior;

    const transactionData = {
      ...previousTransaction,
      _id: crypto.randomUUID(),
      description: `End of day Revaluation ${symbol}/${currency}@${valuation.symbolValue}`,
      Direction: direction,
      valuationPrice: currentSymbolValue,
      // _balance: balance,
      _quantity: {
        _symbol: symbol,
        _currency: currency,
        _actualQuantity: 0, // revals have zero quantity,
        _convrate: previousRate,
        _convsym: symbol,
      },
      _valuation: valuation,
      _transactionDate: previousTransaction.transactionDate.dateString,
    };

    const symRevaluation = new Transaction(new TransactionResponse(transactionData));
    symRevaluation.setGlossBalance();
    symRevaluation.revalTransaction = true;
    const ccyMarketData = await this.getCurrencyMarketData(previousTransaction, date.date);

    let ccyRevaluation: Transaction[] = [];
    if (ccyMarketData.length) {
      if (ccyMarketData[0].date.date === date.date) {
        this.setPreviousTransaction([symRevaluation]);
        this.inProcessMarketData = ccyMarketData[0];
        ccyRevaluation = this.getCurrencyCarryOverRevaluation();
      }
    }
    this.perfService.markEnd();
    return [symRevaluation, ...ccyRevaluation];
  }

  /** Todo @Sinan check if instead of dividing into two methods you can use just one method for both types of revaluations*/
  private async getCarryOverRevaluations(): Promise<Transaction[]> {
    if (this.isSymbolACurrency(this.inProcessTransaction)) {
      return this.getCurrencyCarryOverRevaluation();
    } else {
      return await this.getSymbolCarryOverRevaluation();
    }
  }

  /** Sets previous transaction into its registry */
  private setPreviousTransaction(transactionRevaluations?: Transaction[]) {
    const transaction = this.inProcessTransaction;
    const { symbol } = transaction.quantity.actualQuantity;
    const newRegistry = { ...this.previousTransactionsRegistry };
    if (!this.previousTransactionsRegistry[symbol]) {
      newRegistry[symbol] = transaction;
    } else {
      if (transactionRevaluations) {
        newRegistry[symbol] = transactionRevaluations[transactionRevaluations.length - 1];
      }
    }

    this.previousTransactionsRegistry = newRegistry;
  }

  private async getMarketDataForCarryOver() {
    return await this.getMarketData(this.inProcessTransaction);
  }
  private async getTransactionRevaluations(): Promise<Transaction[]> {
    let transactionRevaluations: Transaction[] = [this._inProcessTransaction];
    let dailyRevaluations: Transaction[] = [];
    this.transactionSymbolMarketData = await this.getMarketData(this.inProcessTransaction);

    if (this.transactionSymbolMarketData.length > 0) {
      for (const [index, dailyMarketData] of this.transactionSymbolMarketData.entries()) {
        if (
          dailyMarketData.symbols[this.inProcessTransaction.quantity.actualQuantity.symbol] === -1
        ) {
          dailyMarketData.symbols[this.inProcessTransaction.quantity.actualQuantity.symbol] =
            this.inProcessTransaction.valuation.symbolValue;
        }
        this.inProcessMarketDataIndex = index;
        this.inProcessMarketData = dailyMarketData;
        dailyRevaluations = await this.getTransactionDailyRevaluation();
        transactionRevaluations = [...transactionRevaluations, ...dailyRevaluations];
        /** Todo @Sinan : check to combine quantityBalanceRegistry with quantityRegistry. It will make the code simpler */
        const isFirstReval = this.inProcessMarketDataIndex === 0;
        if (isFirstReval) {
          this.setSymbolQuantityBalanceRegistry();
        }
      }
    } else {
      if (this.isCarryOver()) {
        const carryOverMarketData = await this.getMarketDataForCarryOver();
        if (carryOverMarketData.length > 0) {
          this.inProcessMarketData = carryOverMarketData[0];

          const carryOverSymReval = await this.getCarryOverRevaluations();
          transactionRevaluations = [...transactionRevaluations, ...carryOverSymReval];
          this.setPreviousTransaction(transactionRevaluations);
          this.inProcessMarketData =
            this.transactionSymbolMarketData[this.inProcessMarketDataIndex];
        }
      }
    }
    return this.getNonZeroRevaluations(transactionRevaluations);
  }

  private getNonZeroRevaluations(transactionRevaluations: Transaction[]): Transaction[] {
    //return transactionRevaluations;
    return transactionRevaluations.filter((reval) => reval.valuation.normalizedValue.amount !== 0);
  }

  /** If a transaction's symbol and currency are the same that transaction is a currency transaction , meaning the symbol is a currency */
  private isSymbolACurrency(transaction: Transaction): boolean {
    return transaction.quantity.actualQuantity.symbol === transaction.quantity.currency;
  }

  private isNextTransactionAfterTillDate(
    nextSymbolTransactionStamp: number,
    tillDateStamp: number
  ) {
    return nextSymbolTransactionStamp > tillDateStamp;
  }

  private getEndDateForMarketData() {
    if (
      this.transactionSymbolMarketData?.length === 0 &&
      this.inProcessTransaction.transactionDate === this.nextSymbolTransaction?.transactionDate
    ) {
      return (
        new Date(
          new DateFormatPipe().transform(this.inProcessTransaction.transactionDate.date)
        ).getTime() + 1
      );
    }

    const nextSymbolTransactionStamp = new Date(
      new DateFormatPipe().transform(this.nextSymbolTransaction?.transactionDate.date)
    ).getTime();
    if (this.dateFormat.isDateStringValid(this.tillDate)) {
      const tillDate = new Date(this.tillDate);
      const tillDateStamp = new Date(new DateFormatPipe().transform(tillDate)).getTime();
      if (this.nextSymbolTransaction) {
        return this.isNextTransactionAfterTillDate(nextSymbolTransactionStamp, tillDateStamp)
          ? tillDateStamp
          : nextSymbolTransactionStamp;
      } else {
        return tillDateStamp;
      }
    } else {
      return this.nextSymbolTransaction ? nextSymbolTransactionStamp : 0;
    }
  }

  private async getMarketData(transaction: Transaction): Promise<Array<ReferenceData>> {
    /*TODO grab market data here */
    const glossQuantity = new GlossQuantity().setToQuantityObj(transaction);
    const symbol = glossQuantity.actualQuantity.symbol;
    let base = glossQuantity.currency;
    const ymd = transaction.transactionDate.date.getTime();
    const endDate = this.getEndDateForMarketData();
    const isSymbolACurrency: boolean = this.isSymbolACurrency(transaction);

    if (isSymbolACurrency) {
      base = this.baseCurrency;
    }

    let referenceData: Array<ReferenceData> = [];

    if (symbol === base) {
      return referenceData;
    }

    if (isSymbolACurrency) {
      referenceData = await this.referenceDataCalculationService.getCurrencyReferenceData(
        symbol,
        base,
        ymd,
        endDate
      );
    } else {
      referenceData = await this.referenceDataCalculationService.getReferenceAfterDate(
        symbol,
        base,
        ymd,
        endDate,
        this.baseCurrency
      );
    }

    return referenceData;
  }

  private async getCurrencyMarketData(
    transaction: Transaction,
    currencyDate: Date
  ): Promise<Array<ReferenceData>> {
    this.perfService.mark("RevaluationService::getCurrencyMarketData");
    /*TODO grab market data here */
    const symbol = transaction.quantity.currency;
    const base = this.baseCurrency;
    const ymd = new Date(new DateFormatPipe().transform(currencyDate)).getTime();

    /** assumed to have only one value in */
    const referenceData = await this.referenceDataCalculationService.getReferenceData(
      symbol,
      base,
      ymd
    );

    if (!referenceData.length) {
      return [];
    }
    if (referenceData[0]?.date?.date) {
      const referenceDataDate = new Date(
        new DateFormatPipe().transform(referenceData[0].date.date)
      );
      // if the returned reference data date is before the requested date, then ignore it
      // this check was added because the referenceData Service will return the last known market data reference
      // if nothing can be found for that date
      if (ymd > referenceDataDate.getTime()) {
        return [];
      }
    }

    this.perfService.markEnd();
    return referenceData;
  }

  private thereIsCarryOverBalance(quantityRegistry: StringToNumber) {
    const transaction = this.inProcessTransaction;
    const { symbol } = transaction.quantity.actualQuantity;
    return (
      this.quantityBalanceRegistry[symbol] &&
      this.quantityBalanceRegistry[symbol] !== quantityRegistry[symbol]
    );
  }

  private isTransactionAtTheGranularityEdge() {
    let isTransactionAtTheGranularityEdge = true;
    if (this.nextSymbolTransaction && this.inProcessMarketData) {
      const nextTransactionDate = this.nextSymbolTransaction.transactionDate.date;
      const marketDataDate = new Date(this.inProcessMarketData.date.date);
      if (nextTransactionDate.getTime() <= marketDataDate.getTime()) {
        isTransactionAtTheGranularityEdge = false;
      } else {
        if (
          this.transactionSymbolMarketData.length === 1 &&
          this.inProcessTransaction.transactionDate.date !== this.inProcessMarketData.date.date
        ) {
          isTransactionAtTheGranularityEdge = false;
        }
      }
    }
    return isTransactionAtTheGranularityEdge;
  }

  /** Check If carryover is needed */
  private isCarryOver() {
    let isCarryOver = false;
    const isFirstMarketDataForRevalOfTransaction = this.inProcessMarketDataIndex === 0;

    if (!isFirstMarketDataForRevalOfTransaction) {
      return isCarryOver;
    }
    const thereIsCarryOverBalance = this.thereIsCarryOverBalance(this.quantityRegistry);
    if (thereIsCarryOverBalance) {
      isCarryOver = true;
      const isTransactionAtTheGranularityEdge = this.isTransactionAtTheGranularityEdge();
      if (!isTransactionAtTheGranularityEdge) {
        isCarryOver = false;
      }
    }
    return isCarryOver;
  }

  private async generateCurrencyEffectOnSymbolRevaluation(revaluations: Transaction[]) {
    this.perfService.mark("RevaluationService::generateCurrencyEffectOnSymbolRevaluation");

    const { symbols, date } = this.inProcessMarketData;
    const previousTransaction = this.getActivePreviousTransaction(true);
    /** the date that currency data is gonna be retrieved from Market Data*/
    const currencyDate = date.date;
    const dateFormat = new Date(currencyDate);
    const dateStamp = dateFormat.getTime();
    /** At the top level it is symbol data here wee need currency data of the date on the run */

    const currencyMarketData: ReferenceData[] =
      this.referenceDataCalculationService.getSingleClosestCurrencyData(
        previousTransaction.quantity.currency,
        this.baseCurrency,
        dateStamp,
        dateStamp + 1
      );

    if (currencyMarketData[0]) {
      /** if getSingleClosestCurrencyData gets the closest date rather than the date itself then we have to set its date to processing date so it belongs to its date*/
      const newCurrencyMD = new ReferenceData(new ReferenceDataResponse(currencyMarketData[0]));
      newCurrencyMD.date = date;
      /** generate currency revaluation based on currency data */
      const currencyRevaluation: Transaction = await this.getCurrencyRevaluation(
        this.inProcessTransaction,
        newCurrencyMD
      );
      currencyRevaluation.revalTransaction = true;
      revaluations.push(currencyRevaluation);
      this.setPreviousTransaction([currencyRevaluation]);
    } else {
      if (
        symbols[this.inProcessTransaction.quantity.actualQuantity.symbol] ===
        previousTransaction.valuation.symbolValue
      ) {
        revaluations.pop();
      }
    }
    this.perfService.markEnd();
  }

  private isRevalNeeded() {
    return this.inProcessTransaction.quantity.actualQuantity.symbol !== this.baseCurrency;
  }

  /** generate a transactions array of at most two Transactions . First element is always symbolRevaluation and if there , the second one is currency revaluation */
  private async getTransactionDailyRevaluation(): Promise<Transaction[]> {
    const transaction = this.inProcessTransaction;

    /** holds the revaluations */
    let revaluations: Transaction[] = [];

    /** Transaction symbol is the same as the transaction currency... If you buy some USD with AUD the symbol and currency is AUD */
    const isCurrency = this.isSymbolACurrency(transaction);

    /** If a simple transaction don't do revaluations */
    if (!this.isRevalNeeded()) {
      return [];
    }

    if (!isCurrency) {
      if (this.isCarryOver()) {
        const carryOverSymReval = await this.getCarryOverRevaluations();
        revaluations = [...revaluations, ...carryOverSymReval];
        this.setPreviousTransaction(revaluations);
        this.inProcessMarketData = this.transactionSymbolMarketData[this.inProcessMarketDataIndex];
      }
      const symbolRevaluation: Transaction = await this.getSymbolRevaluation();
      symbolRevaluation.revalTransaction = true;
      revaluations.push(symbolRevaluation);
      this.setPreviousTransaction([symbolRevaluation]);
    }

    if (!isCurrency) {
      await this.generateCurrencyEffectOnSymbolRevaluation(revaluations);
    } else {
      if (this.isCarryOver()) {
        const carryOverSymReval = await this.getCarryOverRevaluations();
        revaluations = [...revaluations, ...carryOverSymReval];
        this.setPreviousTransaction(revaluations);
        this.inProcessMarketData = this.transactionSymbolMarketData[this.inProcessMarketDataIndex];
      }

      /** generate currency revaluation based on currency data */
      const currencyOnlyRevaluation: Transaction = await this.getCurrencyOnlyRevaluation();
      currencyOnlyRevaluation.revalTransaction = true;
      revaluations.push(currencyOnlyRevaluation);
    }

    /** update the symbol's previous transaction to the last transaction in daily revaluations. Because that is the last time that symbol was revaluated at this point*/
    this.setPreviousTransaction(revaluations);

    return revaluations;
  }

  /** generate single revaluation that is based on the transaction symbol market data*/
  private async getSymbolRevaluation(): Promise<Transaction> {
    const revaluationResponseData = await this.createSymbolRevaluationResponseData();
    const revaluationTransaction = new Transaction(revaluationResponseData);
    revaluationTransaction.setGlossBalance();
    return revaluationTransaction;
  }

  private async getCurrencyRevaluation(
    transaction: Transaction,
    currencyMarketData: ReferenceData
  ): Promise<Transaction> {
    const revaluationData = await this.createCurrencyRevaluationResponseData(
      transaction,
      currencyMarketData
    );
    const revaluationTransaction = new Transaction(new TransactionResponse(revaluationData));
    revaluationTransaction.setGlossBalance();
    return revaluationTransaction;
  }

  private async getCurrencyOnlyRevaluation(): Promise<Transaction> {
    const revaluationResponseData = await this.createCurrencyOnlyRevaluationResponseData();
    const revaluationTransaction = new Transaction(revaluationResponseData);
    revaluationTransaction.setGlossBalance();
    return revaluationTransaction;
  }

  private getSymbolValue() {
    const symbolMarketData = this.inProcessMarketData;
    const currency = this.inProcessTransaction.quantity.currency;
    const symbol = this.inProcessTransaction.quantity.actualQuantity.symbol;

    if (symbol === symbolMarketData.base) {
      return symbolMarketData.symbols[currency];
    }

    return symbolMarketData.symbols[symbol];
  }

  private getRevalDirection(normalizedValue: number) {
    return normalizedValue > 0 ? TransactionDirection.In : TransactionDirection.Out;
  }

  /** generate revaluation data for the revaluation transaction of the symbol*/
  private async createSymbolRevaluationResponseData(): Promise<TransactionResponse> {
    const { currency, convrate } = this.inProcessTransaction.quantity;
    const symbol = this.inProcessTransaction.quantity.actualQuantity.symbol;
    const previousTransaction = this.getActivePreviousTransaction();
    const valuation = await this.getSymbolValuation();
    let revalNormalizedValue = 0;
    if (currency !== this.baseCurrency) {
      revalNormalizedValue = this.getSymbolNormalizedValue(previousTransaction);
    } else {
      revalNormalizedValue = this.getNormalizedDiff();
    }
    const direction = this.getRevalDirection(revalNormalizedValue);

    const valuationPrice = this.getSymbolValue();
    const { date } = this.inProcessMarketData;
    return new TransactionResponse({
      ...previousTransaction,
      _id: crypto.randomUUID(),
      _transactionDate: date,
      description: `Revaluation ${symbol}/${currency}@${valuation.symbolValue}`,
      Account: previousTransaction.accountId,
      Direction: direction,
      _quantity: {
        _symbol: symbol,
        _currency: currency,
        _actualQuantity: 0, // revals have zero quantity,
        _convrate: convrate,
        _convsym: this.baseCurrency,
      },
      // _balance: balance,
      valuationPrice: valuationPrice,
      _valuation: valuation,
    });
  }

  private async createCurrencyRevaluationResponseData(
    transaction: Transaction,
    currencyMarketData: ReferenceData
  ): Promise<TransactionResponse> {
    const previousTransaction = this.getActivePreviousTransaction(true);
    const valuation = await this.getCurrencyValuation(transaction, currencyMarketData);
    const normalizedValue = await this.getCurrencyNormalizedValue(transaction, currencyMarketData);
    const direction = this.getRevalDirection(normalizedValue);

    return new TransactionResponse({
      ...transaction,
      _id: crypto.randomUUID(),
      _transactionDate: currencyMarketData.date,
      description: `Revaluation ${previousTransaction.quantity.currency}/${this.baseCurrency}@${valuation.normalizingRate}`,
      Account: transaction.accountId,
      Direction: direction,
      _quantity: {
        _symbol: transaction.quantity.currency,
        _currency: transaction.quantity.currency,
        _actualQuantity: 0,
        _convrate: previousTransaction.valuation.normalizingRate,
        _convsym: this.baseCurrency,
      },
      _actualQuantity: 0, // revals have zero quantity,
      // _balance: balance,
      valuationPrice: previousTransaction.valuationPrice,
      _valuation: valuation,
    });
  }

  private async createCurrencyOnlyRevaluationResponseData(): Promise<TransactionResponse> {
    const { currency, convrate } = this.inProcessTransaction.quantity;
    const symbol = this.inProcessTransaction.quantity.actualQuantity.symbol;
    const { date } = this.inProcessMarketData;
    const previousTransaction = this.getActivePreviousTransaction();
    const valuation = await this.getCurrencyOnlyValuation();
    const normalizedValue = await this.getCurrencyOnlyNormalizedValue();
    const direction = this.getRevalDirection(normalizedValue);

    return new TransactionResponse({
      ...previousTransaction,
      _id: crypto.randomUUID(),
      _transactionDate: date.date,
      description: `Revaluation ${previousTransaction.quantity.currency}/${this.baseCurrency}@${valuation.normalizingRate}`,
      Account: previousTransaction.accountId,
      Direction: direction,
      _quantity: {
        _symbol: symbol,
        _currency: currency,
        _actualQuantity: 0, // revals have zero quantity,
        _convrate: convrate,
        _convsym: this.baseCurrency,
      },
      _actualQuantity: 0, // revals have zero quantity,
      // _balance: balance,
      valuationPrice: previousTransaction.valuationPrice,
      _valuation: valuation,
    });
  }

  private getSymbolNormalizedValue(previousTransaction: Transaction): number {
    const normalizedDiff = this.getNormalizedDiff();
    return normalizedDiff / Number(this.getCorrectPreviousRate(previousTransaction));
  }

  private async getCurrencyNormalizedValue(
    transaction: Transaction,
    currencyMarketData: ReferenceData
  ): Promise<number> {
    const previousTransaction = this.getActivePreviousTransaction(true);
    const { currency } = transaction.quantity;
    const { amount, symbol } = this.inProcessTransaction.quantity.actualQuantity;
    const { symbolValue, normalizingRate } = previousTransaction.valuation;
    const quantity = this.inProcessMarketDataIndex === 0 ? amount : this.quantityRegistry[symbol];
    const currentRate = currencyMarketData.symbols[currency];
    const previousRate =
      previousTransaction.quantity.convsym === this.baseCurrency
        ? normalizingRate
        : 1 / normalizingRate;

    return quantity * symbolValue * (1 / currentRate - 1 / previousRate);
  }

  private async getCurrencyOnlyNormalizedValue(): Promise<number> {
    const transaction = this.inProcessTransaction;
    const previousTransaction = this.getActivePreviousTransaction();
    const { symbols } = this.inProcessMarketData;
    const glossQuantity = new GlossQuantity().setToQuantityObj(transaction.quantity);
    const quantity = previousTransaction.revalTransaction
      ? this.quantityBalanceRegistry[glossQuantity.actualQuantity.symbol]
      : transaction.quantity.actualQuantity.amount;
    const currentRate = symbols[glossQuantity.currency];
    const previousRate =
      previousTransaction.quantity.convsym === this.baseCurrency
        ? previousTransaction.valuation.normalizingRate
        : 1 / previousTransaction.valuation.normalizingRate;

    return quantity * (1 / currentRate - 1 / previousRate);
  }

  /** returns the difference amount in terms of transaction symbol . what it is worth - what it was worth */
  private getNormalizedDiff(): number {
    const { amount, symbol } = this.inProcessTransaction.quantity.actualQuantity;
    const previousTransaction = this.getActivePreviousTransaction();
    const quantity = !previousTransaction.revalTransaction ? amount : this.quantityRegistry[symbol];
    const previousSymbolValue = previousTransaction.valuationPrice;
    const currentSymbolValue = this.getSymbolValue();
    // const direction: number = transaction.direction === "OUT" ? -1 : 1;
    return quantity * (currentSymbolValue - previousSymbolValue);
  }

  private getActivePreviousTransaction(isCarryOver?: boolean): Transaction {
    let previousTransaction =
      this.previousTransactionsRegistry[this.inProcessTransaction.quantity.actualQuantity.symbol];
    if (isCarryOver) {
      return previousTransaction;
    }

    if (this.inProcessMarketDataIndex === 0) {
      previousTransaction = this.inProcessTransaction;
    }

    return previousTransaction;
  }

  private getSymbolNormalizingRate() {
    const previousTransaction = this.getActivePreviousTransaction();
    /** if we can get the normalized value by multiplication that means it is the original transaction and its rate has been flipped
     * so we have to flip it back for revaluations */
    if (previousTransaction.quantity.convsym !== this.baseCurrency) {
      return 1 / previousTransaction.valuation.normalizingRate;
    }

    return previousTransaction.valuation.normalizingRate;
  }

  /** Return the symbol valuation of a Reval Transaction*/
  private async getSymbolValuation(): Promise<Valuation> {
    const { currency } = this.inProcessTransaction.quantity;
    const previousTransaction = this.getActivePreviousTransaction();
    const valuation = new Valuation();
    const symbolNormalizingRate = this.getSymbolNormalizingRate();
    const normalizedValue = this.getNormalizedDiff();

    valuation.setNormalizedSymbol(this.baseCurrency);
    valuation.setValueAmountSymbol(normalizedValue, previousTransaction.quantity.currency);
    // valuation.setValueSymbol(previousTransaction.quantity.currency);
    valuation.symbolValue = this.getSymbolValue();
    valuation.symbolValuePrior = previousTransaction.valuationPrice;

    if (currency !== this.baseCurrency) {
      valuation.setNormalizedAmount(this.getSymbolNormalizedValue(previousTransaction));
      valuation.normalizingRate = symbolNormalizingRate;
    } else {
      valuation.setNormalizedAmount(normalizedValue);
      valuation.normalizingRate = 1;
    }

    return valuation;
  }

  private async getCurrencyValuation(
    transaction: Transaction,
    symbolMarketData: ReferenceData
  ): Promise<Valuation> {
    const valuation = new Valuation();
    const previousTransaction = this.getActivePreviousTransaction(true);
    const normalizedValue = await this.getCurrencyNormalizedValue(transaction, symbolMarketData);

    valuation.setValueAmountSymbol(0, transaction.quantity.currency);
    // valuation.setValueSymbol(transaction.quantity.currency);
    valuation.symbolValue = previousTransaction.valuationPrice;
    valuation.symbolValuePrior = previousTransaction.valuationPrice;
    valuation.setNormalizedAmountSymbol(normalizedValue, this.baseCurrency);

    valuation.normalizingRate = symbolMarketData.symbols[transaction.quantity.currency];
    valuation.normalizingRatePrior = previousTransaction.valuation.normalizingRate;

    return valuation;
  }

  private async getCurrencyOnlyValuation(): Promise<Valuation> {
    const valuation = new Valuation();
    const transaction = this.inProcessTransaction;
    const previousTransaction = this.getActivePreviousTransaction();
    const { symbols } = this.inProcessMarketData;
    const normalizedValue = await this.getCurrencyOnlyNormalizedValue();

    // valuation.setValueSymbol(transaction.quantity.currency); // todo I think for currency only revaluations this should be baseCurrency
    valuation.setValueAmountSymbol(0, transaction.quantity.currency);
    valuation.symbolValue = previousTransaction.valuationPrice
      ? previousTransaction.valuationPrice
      : 1;
    valuation.symbolValuePrior = previousTransaction.valuationPrice
      ? previousTransaction.valuationPrice
      : 1;

    valuation.setNormalizedAmountSymbol(normalizedValue, this.baseCurrency);
    valuation.normalizingRate = symbols[transaction.quantity.currency];
    valuation.normalizingRatePrior = previousTransaction.valuation.normalizingRate;

    return valuation;
  }

  private async setTransactions(transactions: Transaction[] = []) {
    return transactions.length > 0 ? transactions : await this.transactionService.getAll();
  }

  createRevaluations$(): Observable<Transaction[]> {
    this.stateManagementService = new StateManagementService(this.injector);

    return defer(() => {
      // TODO: have modified this functions to do nothing because it was breaking blobby
      // The bram was being cleared while other services were trying to load data
      return this.transactionService.getAll().then((transactions) => {
        /* return transactions.length > 0
          ? this.stateManagementService.processRevaluations(transactions)
          : [];

         */
        return [];
      });
    });
  }
}
