<h1
  mat-dialog-title
  [ngClass]="{ 'tw-text-accent': data.type === 'warning', 'tw-text-primary': data.type === 'info' }"
>
  {{ data.title | i18n }}
</h1>
<div mat-dialog-content>
  <ng-container *ngIf="data && data.message">
    <p *ngIf="isMessageKey">{{ data.message | i18n }}</p>
    <p *ngIf="!isMessageKey" [innerHTML]="data.message"></p>
  </ng-container>
</div>
<div class="tw-mt-5 tw-flex tw-w-full tw-justify-between tw-gap-6">
  <app-gloss-button
    class="tw-w-full"
    mat-dialog-close
    aria-label="Close dialog"
    [options]="closeBtnOptions"
  ></app-gloss-button>
  <app-gloss-button
    class="tw-w-full"
    [mat-dialog-close]="true"
    [options]="confirmBtnOptions"
  ></app-gloss-button>
</div>
