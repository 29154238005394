<div class="modal-container">
  <modal-header
    [showBackButton]="true"
    [title]="'editBalance' | i18n"
    (onBackClicked)="closeDialog()"
  ></modal-header>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <form name="editBalanceForm" (ngSubmit)="updateBalance()">
      <div class="tw-flex tw-flex-col">
        <app-gloss-input [options]="balanceInputOptions"></app-gloss-input>
        <br />
      </div>
      <app-gloss-button [options]="saveButtonOptions" class="tw-w-full"></app-gloss-button>
    </form>
  </div>
</div>
