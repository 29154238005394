import { Component, Inject, Injector } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { TransactionForm } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/transaction/transaction-form";
import { TransactionData } from "@bitwarden/web-vault/app/models/types/transaction.types";

@Component({
  selector: "account-transaction",
  templateUrl: "./transaction.component.html",
})
export class TransactionComponent {
  transactionForm: TransactionForm;
  data: TransactionData;

  constructor(
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    data: TransactionData
  ) {
    this.data = data;
    this.transactionForm = new TransactionForm(this.injector, this.data);
  }
}
