import {
  Component,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { Route, Routes } from "@angular/router";
import { Store } from "@ngrx/store";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { RouterService } from "@bitwarden/web-vault/app/core";
import { SideMenu } from "@bitwarden/web-vault/app/layouts/menu/side-menu";
import { SideMenuActions } from "@bitwarden/web-vault/app/layouts/menu/state/side-menu.action";
import { getSideMenu } from "@bitwarden/web-vault/app/layouts/menu/state/side-menu.reducer";
import { UserLayoutComponent } from "@bitwarden/web-vault/app/layouts/user-layout.component";
import { NavItems } from "@bitwarden/web-vault/app/models/enum/navItems";
import { GlossRouteEnum } from "@bitwarden/web-vault/app/models/enum/navigation.enum";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import {
  StateManagement,
  StoreState,
} from "@bitwarden/web-vault/app/services/DataService/state-management/state.management";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper-common";
import { HelperRoute } from "@bitwarden/web-vault/app/shared/utils/helper-route";

import "./side-menu.scss";
@Component({
  selector: "side-menu",
  templateUrl: "./side-menu.component.html",
  styles: ["side-menu.scss"],
})
export class SideMenuComponent extends SideMenu implements OnInit, OnDestroy {
  @Output() changeHelpOpen = new EventEmitter<boolean>();
  @Input() currentUrl = "primary-dashboard";

  protected readonly RoleScope = RoleScope;
  protected readonly GlossRouteEnum = GlossRouteEnum;
  protected readonly HelperCommon = HelperCommon;
  protected readonly process = process;
  protected readonly EventEmitter = EventEmitter;
  private helperRoute = new HelperRoute();
  private destroy$: Subject<string> = new Subject<string>();
  private currentUrlSubject = new BehaviorSubject<string>("/primary-dashboard");
  private currentUrl$ = this.currentUrlSubject.asObservable();

  navItems = NavItems;
  menuRoutes: Route = {};
  glossSettingsRoute: Route = {};
  glossSettings: Routes = [];
  sideMenu$: Observable<SideMenu>;
  sideMenu: SideMenu;
  stateManagement: StateManagement;
  isExpanded: Record<string, any> = {
    navigation: true,
    settingPanel: false,
    transactionPanel: false,
    referenceDataPanel: false,
  };
  settingsTab = [
    "/settings",
    "/settings/account",
    "/settings/security",
    "/settings/preferences",
    "/settings/access",
    "/settings/security/change-password",
    "/settings/danger-zone",
  ];

  constructor(
    private store: Store<StoreState>,
    private routerService: RouterService,
    private injector: Injector
  ) {
    super();
    this.stateManagement = this.injector.get(StateManagement);
    this.helperRoute.currentRoute(this.currentUrlSubject);
  }

  ngOnInit() {
    this.sideMenu$ = this.store.select(getSideMenu);
    this.sideMenu = this.getSideMenuState(this.stateManagement, this.sideMenu$);
    this.menuRoutes = this.routerService
      .getRoutes()
      .find((route) => route.component === UserLayoutComponent);
    this.glossSettingsRoute = this.menuRoutes.children.find(
      (child) => child.path === "gloss-settings"
    );
    this.glossSettings = this.glossSettingsRoute.children.slice(1);
    this.getActiveTab();
  }

  @HostListener("click", ["$event"])
  onClickEvt(event: any) {
    event.preventDefault();
    const elementId = this.getSubMenuId(event);

    if (elementId) {
      this.loadSideMenuState();
      const sideMenu = this.resetSideMenu(this.sideMenu) as SideMenu;

      SideMenuActions.toggle({ sideMenu });
      this.isExpanded = sideMenu;
    }
  }

  handleToggleNavigation() {
    //TODO this is commented out as per request for having a static sidebar for now
    /*    this.loadSideMenuState();
    const sideMenu = this.toggleNavigation(this.sideMenu) as SideMenu;
    this.store.dispatch(SideMenuActions.toggle({ sideMenu }));

    this.isExpanded = {
      ...this.isExpanded,
      navigation: sideMenu.navigation,
    };*/
  }

  private loadSideMenuState() {
    this.sideMenu$ = this.store.select(getSideMenu);
    this.sideMenu = this.getSideMenuState(this.stateManagement, this.sideMenu$);
  }

  getActiveTab() {
    this.currentUrl$.pipe(takeUntil(this.destroy$)).subscribe((currentUrl) => {
      if (this.currentUrl !== currentUrl) {
        this.currentUrl = currentUrl;
      }
    });
  }

  isActive(item: any): boolean {
    return (
      (this.settingsTab.includes(this.currentUrl) &&
        this.settingsTab.includes(item.routerLink.join("/"))) ||
      this.currentUrl === item.currentUrl
    );
  }

  getSelectedIcon(item: any): string {
    return this.isActive(item) ? item.selectedIcon : item.icon;
  }

  ngOnDestroy(): void {
    this.destroy$.next("");
  }
}
