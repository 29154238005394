export class AppState {
  private _isInitialized = false;
  private _isExistingUser = false;

  getIsInitialized(): boolean {
    return this._isInitialized;
  }

  setIsInitialized(value: boolean) {
    this._isInitialized = value;
  }

  setIsExistingUser(value: boolean) {
    this._isExistingUser = value;
  }

  getIsExistingUser(): boolean {
    return this._isExistingUser;
  }
}
