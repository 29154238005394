import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";

export const getIcon = (icon: string) => {
  return `<svg class="tw-h-7 tw-w-7">
      <use xlink:href="images/sprite.svg#${icon}" />
    </svg>`;
};

export const getContextMenu = (params: any) => {
  const parent = params.context.componentParent;
  const accountView: AccountView = params.node.data;
  const { i18nService } = parent;
  const isManual = accountView.origin === Origin.manual;
  return [
    {
      name: i18nService.t("addBalance"),
      action: function () {
        parent.addBalance(accountView);
      },
      icon: getIcon("add-entry"),
      disabled: !isManual,
    },
    {
      name: i18nService.t("addTransaction"),
      action: function () {
        parent.addTransaction(accountView);
      },
      icon: getIcon("add"),
      disabled: !isManual,
    },
    {
      name: i18nService.t("edit"),
      action: function () {
        parent.editRight(accountView);
      },
      icon: getIcon("edit"),
      disabled: !isManual,
    },
    {
      name: i18nService.t("delete"),
      action: function () {
        parent.deleteRight(accountView);
      },
      icon: getIcon("trash"),
    },
  ];
};
