import { Component, Input } from "@angular/core";

@Component({
  selector: "tooltip-icon",
  templateUrl: "./tooltip-icon.component.html",
})
export class TooltipIconComponent {
  @Input() isClicked: boolean;
  @Input() class = "";
  @Input() size = 6;
}
