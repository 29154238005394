import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { Wizard } from "@bitwarden/web-vault/app/models/data/blobby/wizard.data";
import { WizardResponse } from "@bitwarden/web-vault/app/models/data/response/wizard.response";
import { WizardStep } from "@bitwarden/web-vault/app/models/enum/wizard.enum";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { MainProgressBar } from "@bitwarden/web-vault/app/services/progress-bar/main-progress-bar";

import { StepModel } from "./step.model";

const STEPS: StepModel[] = [
  { stepIndex: 1, isComplete: false },
  { stepIndex: 2, isComplete: false },
  { stepIndex: 3, isComplete: false },
];

@Injectable({
  providedIn: "root",
})
export class WizardService {
  private steps$: BehaviorSubject<StepModel[]> = new BehaviorSubject<StepModel[]>(STEPS);
  private currentStep$: BehaviorSubject<StepModel> = new BehaviorSubject<StepModel>(null);
  private renewStep$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private syncedAccount$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private buttonClickSubject = new BehaviorSubject<boolean>(false);
  buttonClick$ = this.buttonClickSubject.asObservable();
  private isWizardInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private isWizardCompleted$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _blobbyWizard: Wizard;
  private _blobbyWizardArray: Wizard[];

  constructor(
    private dataRepositoryService: DataRepositoryService,
    private mainProgressBar: MainProgressBar
  ) {
    this.getBlobbyWizardArrayInitial();
  }

  resetWizardService() {
    this.steps$ = new BehaviorSubject<StepModel[]>(STEPS);
    this.currentStep$ = new BehaviorSubject<StepModel>(null);
    this.isWizardInProgress$.next(false);
    this.isWizardCompleted$.next(false);
    this.buttonClickSubject.next(false);
    this._blobbyWizardArray = null;
  }

  getBlobbyWizardArrayInitial() {
    this._blobbyWizardArray = this.dataRepositoryService.getWizardInitial();
    return this._blobbyWizardArray;
  }

  async getBlobbyWizardArray() {
    this._blobbyWizardArray = await this.dataRepositoryService.getWizard();
    return this._blobbyWizardArray;
  }

  setIsWizardComplete(isWizardCompleted: boolean) {
    this.isWizardCompleted$.next(isWizardCompleted);
  }

  getIsWizardComplete() {
    return this.isWizardCompleted$;
  }

  setIsWizardInProgress(isWizardInProgress: boolean) {
    this.isWizardInProgress$.next(isWizardInProgress);
  }

  getIsWizardInProgress() {
    return this.isWizardInProgress$;
  }

  async startWizard(): Promise<void> {
    if (this._blobbyWizardArray && this._blobbyWizardArray.length > 0) {
      this._blobbyWizard = this._blobbyWizardArray[0];
      const localWizardStepper = this._blobbyWizard.getStep();
      for (let i = 0; i < localWizardStepper.stepIndex - 1; i++) {
        this.steps$.value[i] = { stepIndex: i + 1, isComplete: true };
      }
      this.steps$.value[localWizardStepper.stepIndex - 1] = localWizardStepper;
      this.currentStep$.next(localWizardStepper);
    } else {
      this.currentStep$.next(this.steps$.value[0]);
      await this.createWizard(this.steps$.value[0].stepIndex, this.steps$.value[0].isComplete);
    }
  }

  async restartWizardAndSetStepTo(stepIndex: number, isComplete: boolean): Promise<void> {
    await this.createWizard(stepIndex, isComplete);

    if (stepIndex < this.steps$.value.length + 1) {
      for (let i = 0; i < stepIndex - 1; i++) {
        this.steps$.value[i] = { stepIndex: i + 1, isComplete: true };
      }
    }
    const newStep = { stepIndex: stepIndex, isComplete: isComplete };
    this.steps$.value[stepIndex - 1] = { stepIndex: stepIndex, isComplete: isComplete };
    this.currentStep$.next(newStep);
  }

  resetSteps(): void {
    this.steps$.next(STEPS.map((step) => ({ ...step, isComplete: false })));
    const initialStep: StepModel = { ...STEPS[0], isComplete: false };
    this.currentStep$.next(initialStep);
  }

  getCurrentStep() {
    return this.currentStep$.getValue();
  }

  getSteps(): Observable<StepModel[]> {
    return this.steps$.asObservable();
  }

  getStepsValue() {
    return this.steps$.value;
  }

  async completeLastStep() {
    const curStep = this.getCurrentStep();
    if (curStep.stepIndex === WizardStep.viewDashboard) {
      await this.currentStepComplete(curStep);
    }
  }

  async endWizard() {
    localStorage.removeItem("inWizard");
    await this.completeLastStep();
    this.emitButtonClick(true);
    this.setIsWizardInProgress(false);
    await this.delete();
    this.setIsWizardComplete(true);
    this.mainProgressBar.reset();
  }

  async currentStepComplete(curStep: StepModel): Promise<void> {
    const newStep = { stepIndex: curStep.stepIndex, isComplete: true };
    this.steps$.value[newStep.stepIndex - 1] = newStep;
    this.currentStep$.next(newStep);

    const nextStep = { stepIndex: curStep.stepIndex + 1, isComplete: false };
    await this.setNewStepAsCurrentStep(nextStep);
  }

  async setNewStepAsCurrentStep(newStep: StepModel): Promise<void> {
    if (newStep.stepIndex > this.steps$.value.length) {
      this.steps$.complete();
    } else {
      if (
        newStep.stepIndex !== this.currentStep$.value.stepIndex &&
        newStep.stepIndex < this.steps$.value.length + 1
      ) {
        for (let i = 0; i < newStep.stepIndex - 1; i++) {
          this.steps$.value[i] = { stepIndex: i + 1, isComplete: true };
        }
      }
      this.steps$.value[newStep.stepIndex - 1] = newStep;
      this.currentStep$.next(newStep);

      await this.update(newStep);
    }
  }

  getRenewStep() {
    return this.renewStep$.asObservable();
  }

  restartWizardInProgress() {
    this.steps$ = new BehaviorSubject<StepModel[]>(STEPS);
    this.currentStep$ = new BehaviorSubject<StepModel>(null);
    this._blobbyWizardArray = null;
  }

  restartWizard() {
    this.renewStep$.next(true);
  }

  restartWizardCompleted() {
    this.renewStep$.next(false);
  }

  isSyncedAccount() {
    return this.syncedAccount$.asObservable();
  }

  setSyncedAccount(isSyncedAccount: boolean) {
    this.syncedAccount$.next(isSyncedAccount);
  }

  emitButtonClick(value: boolean) {
    this.buttonClickSubject.next(value);
  }

  async update(newStep: StepModel): Promise<void> {
    const newBlobbyWizard = new Wizard(
      new WizardResponse({ ...this._blobbyWizard, _step: { ...newStep } })
    );
    await this.dataRepositoryService.updateWizard(newBlobbyWizard);
  }

  async delete(): Promise<void> {
    await this.dataRepositoryService.deleteWizard(this._blobbyWizard);
  }

  async createWizard(stepIndex: number, isComplete: boolean) {
    this._blobbyWizard = await this.dataRepositoryService.createWizard(
      new Wizard(new WizardResponse({ _step: { stepIndex, isComplete } }))
    );
  }
}
