import { Category } from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import { Classification } from "@bitwarden/web-vault/app/models/data/blobby/classification.data";

type SortingValueType = string | Classification | Category;

export const commonDefs = {
  suppressHeaderMenuButton: true,
  mainMenuItems: [
    "sortAscending",
    "sortDescending",
    "autoSizeThis",
    {
      name: "Autosize All Columns",
      action: (params: any) => {
        if (params.api) {
          params.api.sizeColumnsToFit();
        }
      },
    },
    "columnChooser",
    {
      name: "Reset Columns",
      action: (params: any) => {
        if (params.api) {
          params.api.sizeColumnsToFit();
          params.api.resetColumnState();
        }
      },
    },
  ],
};

export const amountComparator = (valueA: number, valueB: number) => {
  return valueA - valueB;
};

export const amountValueGetter = (params: any) => {
  if (params.data?.quantity) {
    return params.data.quantity.actualQuantity.amount;
  }
};

export const sortingComparator = (valueA: SortingValueType, valueB: SortingValueType) => {
  if (typeof valueA === "string" && typeof valueB === "string") {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  }

  if (typeof valueA === "object" && typeof valueB === "object") {
    const valueAName = getObjVal(valueA)?.name || "";
    const valueBName = getObjVal(valueB)?.name || "";
    return valueAName.toLowerCase().localeCompare(valueBName.toLowerCase());
  }
};

export const granularityGroupingFormatter = (type: string) => {
  return (params: any) => {
    const date = params.data?.glossDate.date;
    const day = date.getDate();
    const week = params.data?.glossDate.WeekNumber;
    const month = date.toLocaleString("en-US", { month: "short" });
    const quarter = params.data?.glossDate.getQuarter();
    const year = date.getFullYear();

    switch (type) {
      case "day":
        return granularityFormatter(month, day, year, true);
      case "week":
        return granularityFormatter("W", week, year);
      case "month":
        return granularityFormatter("", month, year, true);
      case "quarter":
        return granularityFormatter("Q", quarter, year);
      default:
        return year;
    }
  };
};

const granularityFormatter = (prefix: string, time: number, data: string, spaceNeeded = false) => {
  return spaceNeeded ? `${prefix} ${time} ${data}` : `${prefix}${time} ${data}`;
};

const getObjVal = (value: Record<string, any>) => {
  return value[0];
};
