import { DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { MatDatepicker } from "@angular/material/datepicker";

import { addAsterisk } from "@bitwarden/web-vault/app/shared/utils/helper-string";

type BorderType = "left" | "right";
const DEFAULT_DATE_TYPE = "yyyy-MM-dd";

@Component({
  selector: "app-date-picker",
  templateUrl: "./date-picker.component.html",
})
export class DatePickerComponent implements OnInit {
  // todo start remove
  @Input() inputId: string;
  @Input() controlName: string;
  @Input() dateValue: string;
  @Input() labelKey: string;
  @Input() picker: string;
  @Input() isDisabled: boolean;
  @Input() maxDate: Date | null = null;
  // todo end remove

  @Input() label: string;
  @Input() isRequired: boolean;
  @Input() hasIcon = true;

  @Input() hasError: boolean; // todo remove
  @Input() dateFormat = DEFAULT_DATE_TYPE;
  @Input() borderType: BorderType;
  @Output() changeDateEvent: EventEmitter<any> = new EventEmitter();
  @ViewChild("datePicker") datePicker: MatDatepicker<any>;
  dateText: string;
  dateLabel: string;

  constructor(private datePipe: DatePipe) {}

  ngOnInit() {
    this.dateLabel = addAsterisk(this.label, this.isRequired);
  }

  onDateChange(event: any) {
    const date = this.datePipe.transform(event.value, this.dateFormat);
    this.setDateText(date);
    //this.datePicker.select(date);debug why it bubbles up to context menu
    this.changeDateEvent.emit(date);
  }

  setDateText(date: any) {
    this.dateText = date;
  }
}
