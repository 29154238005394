import { Injector } from "@angular/core";

import { Classification } from "../../models/data/blobby/classification.data";
import { Transaction } from "../../models/data/blobby/transaction.data";
import { SplitClassificationType } from "../../models/types/split-classification-type";
import { ClassificationService } from "../../services/DataService/classification/classification.service";

import { HelperCommon } from "./helper-common";

export class HelperClassification extends HelperCommon {
  protected injector: Injector;

  constructor(injector: Injector) {
    super(injector);
    this.injector = injector;
  }

  protected async updateTransactionClassificationToDefault(deletedClassification: Classification) {
    const classificationService = this.injector.get(ClassificationService);
    const defaultClassification: Classification =
      await classificationService.getGeneralDefaultClassification();
    const transactions = await this.transactionService.getAll(HelperClassification.RUN_VALUATION);
    const transactionsByClassification = this.filterTransactionsByClassification(
      transactions,
      deletedClassification
    );

    for (const transaction of transactionsByClassification) {
      for (let i = 0; i < transaction.classifications.length; i++) {
        const transClass = transaction.classifications[i];

        if (transClass.classificationId === deletedClassification.id) {
          if (
            !this.isDefaultClassificationExist(
              transaction.classifications,
              defaultClassification.id
            )
          ) {
            await this.setToDefault(transClass, defaultClassification);
            await this.transactionService.update(transaction);
          } else {
            /** If default classification exist just delete the classification from transaction */
            transaction.classifications.splice(i, 1);
          }
        }
      }
    }
  }

  private filterTransactionsByClassification(
    transactions: Transaction[],
    classification: Classification
  ) {
    return transactions.filter((transaction) => {
      return transaction.classifications.some(
        (transClassification) => transClassification.classificationId === classification.id
      );
    });
  }

  private async setToDefault(
    classification: SplitClassificationType,
    defaultClassification: Classification
  ) {
    classification.classificationId = defaultClassification.id;
    classification.name = defaultClassification.name;
    classification.roundingDefault = true;
    classification.weight = 1;

    return classification;
  }

  private isDefaultClassificationExist(
    classifications: SplitClassificationType[],
    defaultId: string
  ) {
    let isExist = false;

    for (const classification of classifications) {
      if (classification.classificationId === defaultId) {
        isExist = true;
        break;
      }
    }
    return isExist;
  }
}
