<div
  class="tw-w-full tw-cursor-pointer tw-flex-col tw-items-center tw-justify-start tw-gap-2"
  (click)="datePicker.open()"
>
  <div
    class="tw-relative tw-z-10 tw-flex tw-w-full tw-items-center tw-whitespace-nowrap tw-font-medium tw-text-neutral-600 xs:tw-scale-90"
  >
    {{ dateLabel }}
  </div>
  <div
    [ngClass]="{ 'input-error-border': hasError && isRequired }"
    class="{{
      borderType ? borderType + '-round-border' : 'tw-rounded-lg'
    }} tw-relative tw-flex tw-h-auto tw-min-h-[48px] tw-w-full tw-items-center tw-justify-between tw-gap-3 tw-bg-neutral-50 tw-px-6 tw-py-2 tw-shadow-button xs:tw-gap-0 xs:tw-px-2"
  >
    <div class="tw-relative tw-flex tw-items-center tw-justify-center">
      <mat-datepicker #datePicker></mat-datepicker>
      <input
        matInput
        [matDatepicker]="datePicker"
        (dateChange)="onDateChange($event)"
        class="tw-pointer-events-none tw-absolute tw-opacity-0"
      />
      <div
        class="tw-relative tw-flex tw-w-full tw-min-w-[6rem] tw-justify-center tw-whitespace-nowrap tw-text-neutral-800"
        [ngClass]="{ 'tw-opacity-60': !dateText }"
      >
        {{ dateText || "YYYY-MM-DD" }}
      </div>
    </div>
    <div class="tw-overflow-hidden tw-rounded" *ngIf="hasIcon">
      <svg class="tw-h-7 tw-w-7">
        <use xlink:href="images/sprite.svg#calendar" />
      </svg>
    </div>
  </div>
  <mat-error class="error-inline input-error tw-mt-1" *ngIf="hasError && isRequired">
    {{ "inputRequired" | i18n }}
  </mat-error>
</div>
