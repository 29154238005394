import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { BasiqAccountSync } from "@bitwarden/web-vault/app/services/syncing/basiq.account.sync";

@Component({
  selector: "app-sync-action-buttons-cell-renderer",
  templateUrl: "./sync-action-buttons-cell-renderer.component.html",
})
export class SyncActionButtonsCellRenderer implements ICellRendererAngularComp {
  origin: Origin;
  params: any;
  buttons: {
    retry: boolean;
    connect: boolean;
    consent: boolean;
    merge: boolean;
  };

  syncBasiqConsentButtonOptions = new ActionButton({
    text: this.i18nService.t("consent"),
    class: "neutral",
    isEnabled: true,
    fullClass: "tw-h-10 tw-leading-3 tw-w-full tw-px-6 tw-rounded-full tw-border-none",
    onClick: this.consent.bind(this),
    testId: "syncBasiqConsentButton",
  });

  syncBasiqConnectButtonOptions = new ActionButton({
    text: this.i18nService.t("connect"),
    class: "neutral",
    fullClass: "tw-h-10 tw-leading-3 tw-w-full tw-px-6 tw-rounded-full tw-border-none ",
    isEnabled: true,
    onClick: this.connect.bind(this),
    testId: "syncBasiqConnectButton",
  });

  syncBasiqRetryButtonOptions = new ActionButton({
    text: this.i18nService.t("retry"),
    class: "neutral",
    isEnabled: true,
    fullClass: "tw-h-10 tw-leading-3 tw-w-full tw-px-6 tw-rounded-full tw-border-none",
    onClick: this.retry.bind(this),
    testId: "syncBasiqRetryButton",
  });

  constructor(private i18nService: I18nService) {}

  agInit(params: { data: BasiqAccountSync }): void {
    this.params = params;
    this.buttons = {
      merge: params.data.point.data?.actions?.includes("merge"),
      connect: params.data.point.data?.actions?.includes("connect"),
      consent: params.data.point.data?.actions?.includes("consent"),
      retry: params.data.point.data?.actions?.includes("retry"),
    };
  }

  refresh(): boolean {
    return false; // No need to refresh since data fetch is already handled
  }

  merge(): void {
    this.params.context.componentParent.merge(this.params.node.data);
  }

  connect(): void {
    this.params.context.componentParent.connect(this.params.node.data);
  }

  consent(): void {
    this.params.context.componentParent.consent(this.params.node.data);
  }

  retry(): void {
    this.params.context.componentParent.retry(this.params.node.data);
  }

  protected readonly Origin = Origin;
}
