import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

require("./scss/styles.scss");
require("./scss/tailwind.css");

import { AppModule } from "./app/app.module";

if (process.env.NODE_ENV === "production") {
  enableProdMode();
}

function loadServiceWorker(): void {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker
      .register("./service-worker.js")
      // eslint-disable-next-line no-console
      .catch((err) => console.error("Service worker registration failed with:", err));
  }
}
platformBrowserDynamic()
  .bootstrapModule(AppModule, { preserveWhitespaces: true })
  .then(() => loadServiceWorker())
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));
