<app-spinner [showSpinner]="showSpinner" [diameter]="50"></app-spinner>
<div id="dashboard-selector" class="tw-flex">
  <div>
    <app-help-tooltip
      [showHelpTooltip]="tooltip.showHelpTooltip"
      [tooltipInfo]="tooltipInfo"
      [pointer]="'modal'"
      (onTooltipClose)="tooltip.close()"
    ></app-help-tooltip>
  </div>
  <app-select
    labelKey="Dashboard"
    [selectedValue]="dashboardID"
    selectElementId="dashboardType"
    [itemList]="dashboardTypes"
    (selectEvent)="updateDashboardSelection($event)"
    valueKey="dashboardID"
    displayKey="display"
  ></app-select>

  <div
    class="tw-ml-1 tw-flex tw-h-12 tw-cursor-pointer tw-items-center tw-rounded-lg tw-p-1 xs:tw-items-end"
    title="{{ 'dashboardHelpTitle' | i18n }}"
    (click)="tooltip.show($event)"
  >
    <tooltip-icon [isClicked]="tooltip.isClicked" id="help-info-button"></tooltip-icon>
  </div>
</div>
