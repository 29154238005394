export class HelperTooltip {
  isClicked = false;
  showHelpTooltip = false; // for showing actual tooltip content

  close() {
    this.showHelpTooltip = false;
    this.isClicked = false;
  }

  show(e: MouseEvent) {
    e.stopPropagation();
    this.isClicked = !this.isClicked;
    this.showHelpTooltip = true;
  }
}
