import {
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
  ChangeDetectionStrategy,
} from "@angular/core";

type RadioOption = {
  label: string;
  value: string;
  defaultSelected: boolean;
};

@Component({
  selector: "radio-button",
  templateUrl: "./radio-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent implements OnInit {
  @Input() options: RadioOption[] = [];
  @Output() onClick: EventEmitter<string> = new EventEmitter<string>();
  selectedValue: string;

  ngOnInit() {
    this.selectedValue = this.options.find((option) => option.defaultSelected)?.value;
  }

  onInputClick(value: string) {
    this.setInputValue(value);
    this.onClick.emit(value);
  }

  setInputValue(value: string) {
    this.selectedValue = value;
  }

  isChecked(option: RadioOption) {
    return this.selectedValue === option.value;
  }
}
