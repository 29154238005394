<app-spinner [showSpinner]="loading"></app-spinner>
<div class="modal-container" (keydown.enter)="$event.preventDefault(); $event.stopPropagation()">
  <modal-header
    [title]="title"
    [showBackButton]="!isEditMode"
    (onBackClicked)="onBackClicked()"
  ></modal-header>

  <div class="m-body tw-p-10 xs:tw-px-5">
    <form name="accountForm">
      <div class="tw-flex tw-flex-col">
        <app-gloss-input [options]="accountInputOptions"></app-gloss-input>
        <br />
        <app-gloss-input [options]="currencyInputOptions"></app-gloss-input>
        <br />
        <app-gloss-input [options]="institutionInputOptions"></app-gloss-input>
        <br />

        <app-institutional-accounts
          [institution]="selectedInstitution"
          (selectedInstitutionAccount)="onSelectAccountType($event)"
          [preselectedInstitutionalAccount]="selectedAccountType"
        ></app-institutional-accounts>

        <modal-footer [isFullWidth]="isEditMode">
          <app-gloss-button [options]="saveButtonOptions" class="tw-w-full"></app-gloss-button>
        </modal-footer>
      </div>
    </form>
  </div>
</div>
