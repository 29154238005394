<div class="spinner-container" *ngIf="showSpinner">
  <div class="gloss-spinner">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
