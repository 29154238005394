import { Component, Inject, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import _ from "lodash";
import { Subject, takeUntil } from "rxjs";

import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { AccountAddEditComponent } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/accounts-add-edit/account-add-edit.component";
import { timezones } from "@bitwarden/web-vault/app/gloss/settings/manage-preferences/timezones";
import { GLOBAL_BASE_CURRENCY } from "@bitwarden/web-vault/app/models/constants/global.constants";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { UnrecognizedAccount } from "@bitwarden/web-vault/app/models/types/account.types";
import { InstitutionAccountLink } from "@bitwarden/web-vault/app/models/types/institution.type";
import { SystemCurrenciesString } from "@bitwarden/web-vault/app/models/types/system-currencies.type";
import { BookService } from "@bitwarden/web-vault/app/services/DataService/book/book.service";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper-preference";
import {
  potentialTransaction,
  validationResult,
} from "@bitwarden/web-vault/app/validators/base-validator";
import { CsvValidator } from "@bitwarden/web-vault/app/validators/csv-validator";

@Component({
  selector: "app-linking-accounts",
  templateUrl: "./linking-accounts.component.html",
})
export class LinkingNewAccountsComponent implements OnInit {
  private accountAddEditComponentMatDialogRef: MatDialogRef<AccountAddEditComponent>;
  /** to hold all the accounts*/
  accounts: Book[];
  /** to hold all the accounts that belongs to the selected institution */
  instoAccounts: Book[];

  /** holds the accounts that are not yet in the system */
  unrecognizedAccounts: UnrecognizedAccount[];
  /** to handle memory leaks */
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  /** holds the name for the transactions whose account does not exist, ie empty string */
  emptyAccountName: string;

  /** holds the time zones as an array of strings */
  /*todo currently it is hardcoded should be set another way maybe*/
  timeZones = timezones;

  /** following form controller are used when selecting or resetting a select option such as currency or time zone for each new account*/
  currencyFormControls: FormControl[] = [];
  timeZoneFormControls: FormControl[] = [];
  linkAccountFormControls: FormControl[] = [];
  linkInstitutionAccountFormControls: FormControl[] = [];
  emptyAccountNameControl: FormControl = new FormControl<Book>(null);

  /** holds a register of each new account and how each one is going to be treated
   * link : {new_account_name : linked_account_id}
   * linkedBook : {new_account_name : linked_account as a Book}
   * rename : {new_account_name : typed_rename}
   * default : {new_account_name : new_account_name}
   * */
  newBookNames: any = {
    link: {},
    linkedBook: {},
    rename: {},
    default: {},
  };

  /** holds a register of time zone , currency and account for each new account */
  newBooks: any = {
    timeZone: {},
    currency: {},
    linkAccount: {},
    linkInstitutionAccount: {},
  };

  /** keep a registry of which new account is linked to which existing account */
  accountLinks = new Map<string, string>();
  /** keep a registry of which new account is renamed*/
  accountRenames = new Map<string, string>();
  /** store the default currency to assign to transactions without a supplied currency */
  defaultCurrency: string;
  /**  keep a registry of which new account section has linking selection on the view.*/
  isLinkMap = new Map<string, boolean>();
  /** keep a registry of which new account section has renaming input on the view. */
  isNameMap = new Map<string, boolean>();
  /** all the currencies to show if the account does not have any currency field coming from transaction */
  allCurrencies: string[] = [...SystemCurrenciesString];

  /** holds the base currency so we can check against */
  baseCurrency: string = GLOBAL_BASE_CURRENCY;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      records: validationResult;
      noCurrencyTransactions: Transaction[];
      noSymbolTransactions: Transaction[];
      actionSucceeded: CallableFunction;
      institution: Institution;
    },
    private bookService: BookService,
    private globalService: GlobalService,
    private helperPreference: HelperPreference,
    public dialog: MatDialog,
    private csvValidator: CsvValidator
  ) {}

  async ngOnInit(): Promise<void> {
    this.baseCurrency = await this.helperPreference.getBaseCurrency();
    this.accounts = await this.bookService.getAll();
    /** deep clone the new accounts, so it does not affect the original ones */
    this.unrecognizedAccounts = _.cloneDeep(this.data.records.newAccounts);

    /** deep clone the new accounts, so it does not affect the original ones */
    this.instoAccounts = this.accounts.filter((account) => {
      if (account.institutionLink) {
        /*TODO : here we get the accounts that are linked to selected institutions or accounts that do not have a link to any institutions
           so if we select an account that does not have an institution link we should update that account(s) accordingly when import is taking place
        * */
        return (
          account.institutionLink.institutionId === this.data.institution.id ||
          !account.institutionLink
        );
      }

      return false;
    });
    /** at the beginning set the no-name account's name to the name of the first account from institution*/
    if (this.instoAccounts.length) {
      this.emptyAccountNameControl.setValue(this.instoAccounts[0]);
    }
    /** set initial values for currency , account name and time zones for each new account*/
    this.unrecognizedAccounts.forEach((na) => {
      this.newBookNames.rename[na.source] = na.source;
      this.newBookNames.linkedBook[na.source] = this.accounts.length > 0 ? this.accounts[0] : null;
      this.newBooks.timeZone[na.source] = na.selectedTimeZone;
      this.newBooks.currency[na.source] = na.selectedCurrency;

      /** set form controller for each selectable element such as currency and time zone*/
      const currencyControl = new FormControl(this.newBooks.currency[na.source]);
      const timeZoneControl = new FormControl(this.newBooks.timeZone[na.source]);
      const linkAccountFormControl = new FormControl(this.newBooks.linkAccount[na.source]);
      const linkInstitutionAccountFormControl = new FormControl(
        this.newBooks.linkInstitutionAccount[na.source]
      );

      this.currencyFormControls.push(currencyControl);
      this.timeZoneFormControls.push(timeZoneControl);
      this.linkAccountFormControls.push(linkAccountFormControl);
      this.linkInstitutionAccountFormControls.push(linkInstitutionAccountFormControl);

      /** Display rename element first . when link button is clicked the screen changes to link element */
      this.isNameMap.set(na.source, true);

      if (this.accounts.length > 0) {
        for (const linkAccountFormControl of this.linkAccountFormControls) {
          linkAccountFormControl.setValue(this.accounts[0]);
          this.accountLinks.set(na.source, this.accounts[0].name);
          this.defaultCurrency = this.accounts[0].currency;
          this.newBookNames.link[na.source] = this.accounts[0].id;
          this.newBookNames.linkedBook[na.source] = this.accounts[0];
        }
      }
    });
  }

  /** If there is a row in csv file of transactions , that does not have an account attached,  then it will ask user to select an account for that/those
   * transactions... so when the user select the account we have to re-match the records to check if there are any duplicates or not
   * */
  async recalculateRecords() {
    const newTransactions = this.data.records.preview.map((transaction) => {
      if (this.isEmptyAccountName(transaction.accountId)) {
        transaction.accountId = this.emptyAccountNameControl.value.name;
      }

      return transaction;
    });

    this.data.records = await this.csvValidator.matchTransactionRecord(newTransactions);

    await this.submit();
  }

  isEmptyAccount(): boolean {
    return this.data.records.newAccounts.some((account) => account.accountId === "");
  }

  async submit() {
    if (this.emptyAccountNameControl.value === null && this.isEmptyAccount()) {
      this.globalService.showErrorMessage("errorOccurred", "pleaseCreateAnAccountForNonDetected");
    }
    /** if there are transactions without account name then we have to set it out. */
    const emptyAccountNamesExist = this.data.records.preview.some((transaction) =>
      this.isEmptyAccountName(transaction.accountId)
    );
    if (emptyAccountNamesExist) {
      await this.recalculateRecords();
      return;
    }

    for (const r of this.data.records.preview) {
      /** if the link section is on the vies get link data  */
      if (this.isLinkMap.get(r.accountId) && this.accountLinks.has(r.accountId)) {
        const modifiedAccountName = this.accountLinks.get(r.accountId);
        if (modifiedAccountName !== "") {
          r.accountId = modifiedAccountName;
        }
      }

      /** if the rename section is on the vies get rename data */
      if (this.isNameMap.get(r.accountId) && this.accountRenames.has(r.accountId)) {
        const modifiedAccountName = this.accountRenames.get(r.accountId);
        if (modifiedAccountName !== "") {
          r.accountId = modifiedAccountName;
        }
      }
      // assign the default currency to the transaction if one is not given
      const isNoCurrencyTransaction = this.data.noCurrencyTransactions.some(
        (noCurrencyTransaction) => noCurrencyTransaction.id === r.id
      );
      if (isNoCurrencyTransaction) {
        r.quantity.currency = await this.getProperCurrency(r);
      }
      // assign the default currency to the transaction if one is not given
      const isNoSymbolTransaction = this.data.noSymbolTransactions.some(
        (noCurrencyTransaction) => noCurrencyTransaction.id === r.id
      );
      if (isNoSymbolTransaction) {
        r.quantity.setQuantitySymbol(r.quantity.currency);
      }
    }

    /** update records based on changes */
    this.data.records.newAccounts = this.unrecognizedAccounts;

    /** pass the updated records to preview component*/
    this.data.actionSucceeded(this.data.records);
  }

  async createNewAccount() {
    await this.openNewAccountForm();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  async openNewAccountForm() {
    const dialogRef = await this.dialog.open(AccountAddEditComponent, {
      width: "800px",
      disableClose: true,
      data: {
        institution: this.data.institution,
        actionSucceeded: this.actionSucceeded.bind(this),
      },
    });

    this.accountAddEditComponentMatDialogRef = dialogRef;

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((instoAccounts: Book[]) => {
        /** set the no-name account value to the one that just created */
        if (instoAccounts && instoAccounts.length) {
          this.emptyAccountNameControl.setValue(instoAccounts[instoAccounts.length - 1]);
        }
      });
  }

  async actionSucceeded(actionKey: string) {
    this.instoAccounts = await this.bookService.getAllInstitutionBooks(this.data.institution);
    this.accountAddEditComponentMatDialogRef?.close(this.instoAccounts);
  }
  onEmptyAccountNameChange(event: MatSelectChange, newAccount: UnrecognizedAccount) {
    const book: Book = event.value;
    this.emptyAccountName = book.name;
  }
  /*todo what happens if the account does not have a currency ? is that even a case ?*/
  /** get the proper currency - account selected currency -  for the transaction that does not have a currency */
  async getProperCurrency(r: potentialTransaction) {
    const newAccount = this.unrecognizedAccounts.find(
      (account) => account.accountId === r.accountId
    );
    if (newAccount) {
      return newAccount.selectedCurrency;
    } else {
      const account = await this.bookService.getByName(r.accountId);
      return account.currency;
    }
  }
  /** make adjustments when linking account changes for one of the new accounts */
  onAccountChange(materialBook: any, newAccount: string, newBookNameIndex: number) {
    const book = materialBook.value;
    this.accountLinks.set(newAccount, book.name);
    this.defaultCurrency = book.currency;
    for (const na of this.unrecognizedAccounts) {
      if (na.source === newAccount) {
        na.accountId = book.id;
        na.action = "link";
        na.selectedCurrency = book.currency;
        this.newBookNames.link[newAccount] = book.id;
        this.newBookNames.linkedBook[newAccount] = book;
        this.currencyFormControls[newBookNameIndex].setValue(book.currency);

        break;
      }
    }
  }

  /** make adjustments when linking account changes for one of the new accounts */
  onInstitutionAccountChange(
    institutionAccount: any,
    newAccount: string,
    newBookNameIndex: number
  ) {
    const account = institutionAccount.value;
    const institutionAccountLink: InstitutionAccountLink = {
      institutionAccountId: account.id,
      institutionId: this.data.institution.id,
    };
    for (const na of this.unrecognizedAccounts) {
      if (na.source === newAccount) {
        na.institutionAccountLink = institutionAccountLink;
        break;
      }
    }
  }

  /** make adjustments when currency changes for one of the new accounts */
  onAccountCurrencyChange(currency: any, newAccount: UnrecognizedAccount) {
    for (const na of this.unrecognizedAccounts) {
      if (na.source === newAccount.source) {
        na.selectedCurrency = currency.value;
        this.defaultCurrency = currency.value;
        break;
      }
    }
  }

  /** make adjustments when time zone changes for one of the new accounts */
  onAccountTimeZoneChange(timeZone: any, newAccount: UnrecognizedAccount) {
    for (const na of this.unrecognizedAccounts) {
      if (na.source === newAccount.source) {
        na.selectedTimeZone = timeZone.value;
        this.newBooks.timeZone[newAccount.source] = timeZone.value;

        break;
      }
    }
  }

  /** display linking element for the new account */
  showLinking(newAccount: string, newBookNameIndex: number) {
    this.isLinkMap.set(newAccount, true);
    this.isNameMap.set(newAccount, false);
    this.accounts.forEach((account) => {
      this.unrecognizedAccounts[newBookNameIndex].currencies.push(account.currency);
    });

    for (const na of this.unrecognizedAccounts) {
      if (na.source === newAccount) {
        const account = this.linkAccountFormControls[newBookNameIndex].value;
        this.currencyFormControls[newBookNameIndex].setValue(account.currency);

        na.action = "link";
        na.selectedCurrency = account.currency;
        na.accountId = this.newBookNames.link[newAccount];
        break;
      }
    }
  }

  /** display renaming element for the new account */
  showRenaming(newAccount: string) {
    this.isLinkMap.set(newAccount, false);
    this.isNameMap.set(newAccount, true);
    for (const na of this.unrecognizedAccounts) {
      if (na.source === newAccount) {
        na.action = "rename";
        na.accountId = this.newBookNames.rename[newAccount];
        break;
      }
    }
  }

  /** make adjustments when usr rename a new account */
  renameTheAccount(name: string, newAccount: string) {
    this.accountRenames.set(newAccount, name);
    for (const na of this.unrecognizedAccounts) {
      if (na.source === newAccount) {
        if (name === "") {
          na.accountId = newAccount;
          na.action = "default";
          delete this.newBookNames.rename[newAccount];
          break;
        } else {
          na.accountId = name;
          na.action = "rename";
          this.newBookNames.rename[newAccount] = name;
          break;
        }
      }
    }
  }

  /** clear whatever user typed for renaming and make appropriate adjustments*/
  clearRename(event: Event, newAccountName: string) {
    event.preventDefault();
    for (const na of this.unrecognizedAccounts) {
      if (na.source === newAccountName) {
        na.accountId = newAccountName;
        na.action = "default";
        this.newBookNames.rename[newAccountName] = "";
        break;
      }
    }
  }

  /** reset all the values altered for a new account to base values*/
  resetAccount(event: Event, newAccountName: string) {
    event.preventDefault();
    for (const [index, na] of this.unrecognizedAccounts.entries()) {
      if (na.source === newAccountName) {
        const baseAccount = this.data.records.newAccounts.find(
          (newAccount) => newAccount.source === newAccountName
        );
        this.newBookNames.rename[newAccountName] = baseAccount.source;
        this.newBooks.timeZone[newAccountName] = baseAccount.selectedTimeZone;
        this.newBooks.currency[newAccountName] = baseAccount.selectedCurrency;

        this.currencyFormControls[index].setValue(baseAccount.selectedCurrency);
        this.timeZoneFormControls[index].setValue(baseAccount.selectedTimeZone);
        this.linkAccountFormControls[index].setValue(this.accounts[0]);

        na.action = "default";
        na.accountId = baseAccount.source;
        na.selectedTimeZone = baseAccount.selectedTimeZone;
        na.selectedCurrency = baseAccount.selectedCurrency;
      }
    }
  }

  /** get user back to the file selection page */
  closeDialogue() {
    this.data.actionSucceeded(null);
  }

  isEmptyAccountName(rawString: string): boolean {
    return rawString.replace(/\s/g, "") === "";
  }
  isInstoAccounts(): boolean {
    return !!(this.instoAccounts && this.instoAccounts.length);
  }
}
