<div
  [ngClass]="{ 'mobile-btn-container': isMobile, 'none-mobile-btn-container': !isMobile }"
  id="cabinet-buttons-container"
>
  <button
    class="tw-flex tw-cursor-pointer tw-items-center tw-gap-2 tw-rounded-lg tw-border-none tw-bg-transparent tw-p-2 tw-font-medium tw-text-neutral-800"
    type="button"
    (click)="uploadNewFile()"
  >
    {{ "uploadNewFile" | i18n }}
    <svg class="tw-h-8 tw-w-8">
      <use [attr.xlink:href]="'images/sprite.svg#add'" />
    </svg>
  </button>
</div>

<div class="content">
  <div *ngIf="vaultFiles" class="table-container tw-mb-[100px] tw-flex tw-h-[500px] tw-flex-col">
    <app-spinner [showSpinner]="isDeleting" class="tw-m-auto"></app-spinner>
    <ag-grid-angular
      class="ag-theme-quartz"
      style="height: 100%"
      [columnDefs]="colDefs"
      [rowData]="vaultFileViews"
      [autoSizeStrategy]="autoSizeStrategy"
      [getContextMenuItems]="getContextMenuItems.bind(this)"
      (gridReady)="onGridReady($event)"
      (cellClicked)="onCellClicked($event)"
    ></ag-grid-angular>
  </div>
</div>
