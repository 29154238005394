import { Component, Inject, Injector } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";

import { BalanceForm } from "@bitwarden/web-vault/app/gloss/settings/manage-accounts/account-balance/balance-form";
import { TransactionData } from "@bitwarden/web-vault/app/models/types/transaction.types";

@Component({
  selector: "app-account-balance",
  templateUrl: "./account-balance.component.html",
})
export class AccountBalanceComponent {
  balanceForm: BalanceForm;
  data: TransactionData;
  constructor(
    private injector: Injector,
    @Inject(MAT_DIALOG_DATA)
    data: TransactionData
  ) {
    this.data = data;
    this.balanceForm = new BalanceForm(this.injector, this.data);
  }
}
