import { Injector } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { HelperNumericInput } from "@bitwarden/web-vault/app/shared/utils/helper-numeric-input";
import DateFormat from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format";

export abstract class BaseForm {
  formGroup: FormGroup;
  formBuilder: FormBuilder;

  preferenceService: PreferenceService;
  i18nService: I18nService;
  globalService: GlobalService;

  title: string;
  loading = false;
  currency: string;
  dateFormat = new DateFormat();

  constructor(injector: Injector) {
    this.preferenceService = injector.get(PreferenceService);
    this.globalService = injector.get(GlobalService);
    this.i18nService = injector.get(I18nService);
    this.formBuilder = injector.get(FormBuilder);
  }

  selectCurrency(selectedCurrency: string) {
    this.formGroup.controls.currency.setValue(selectedCurrency);
  }

  onInputNumeric(event: Event, controlName: string) {
    HelperNumericInput.onInput(event, this.formGroup.controls[controlName]);
    const target = event.target as HTMLInputElement;
    this.formGroup.get(controlName)?.setValue(target.value);
  }

  onInput(event: Event, controlName: string) {
    const target = event.target as HTMLInputElement;
    this.formGroup.get(controlName)?.setValue(target.value);
  }

  clearDefaultValue(controlName: string) {
    HelperNumericInput.clearDefaultValue(this.formGroup.controls[controlName]);
  }

  onDateChange(date: string) {
    this.formGroup.controls.date.setValue(date);
    this.formGroup.controls.date.markAsTouched();
  }
}
