<cdk-virtual-scroll-viewport [itemSize]="itemSize" class="table-viewport" scrollWindow>
  <table mat-table #table [dataSource]="dataSource" matSort>
    <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
      <th
        mat-header-cell
        *matHeaderCellDef
        class="preview-table-title"
        mat-sort-header
        [ngSwitch]="column.columnDef"
      >
        <span *ngSwitchCase="'select'">
          <mat-checkbox
            [color]="'primary'"
            (change)="$event ? helperListTable.masterToggle() : null"
            [checked]="helperListTable.selection.hasValue() && helperListTable.isAllSelected()"
            [indeterminate]="
              helperListTable.selection.hasValue() &&
              !helperListTable.isAllSelected() &&
              helperListTable.isAnySelected()
            "
          >
          </mat-checkbox>
        </span>
        <span *ngSwitchCase="'accountId'">
          {{ "account" | i18n | uppercase }}
        </span>
        <span *ngSwitchCase="'_transactionDate'">
          {{ "transactionDate" | i18n | uppercase }}
        </span>
        <span *ngSwitchDefault>{{ column.header | uppercase }}</span>
      </th>
      <td mat-cell *matCellDef="let row; let i = index">
        <div [ngSwitch]="column.columnDef">
          <span *ngSwitchCase="'select'">
            <mat-checkbox
              [color]="'primary'"
              (click)="$event.stopPropagation()"
              (change)="$event ? helperListTable.selection.toggle(row) : null"
              [checked]="helperListTable.selection.isSelected(row)"
            >
            </mat-checkbox>
          </span>

          <span *ngSwitchCase="'actions'">
            <ng-container *ngIf="helperListTable.editIndex !== i; else editRow">
              <button
                mat-icon-button
                color="primary"
                matTooltip="Edit"
                matTooltipPosition="right"
                (click)="helperListTable.startEdit(i)"
              >
                <svg class="tw-h-6 tw-w-6">
                  <use [attr.xlink:href]="'images/sprite.svg#edit'" />
                </svg>
              </button>
              <button
                mat-icon-button
                color="warn"
                matTooltip="Delete"
                matTooltipPosition="right"
                (click)="helperListTable.deleteRow(row)"
              >
                <svg class="tw-h-6 tw-w-6">
                  <use [attr.xlink:href]="'images/sprite.svg#trash'" />
                </svg>
              </button>
            </ng-container>
            <ng-template #editRow>
              <div [formGroup]="helperListTable.editForm">
                <button
                  mat-icon-button
                  color="primary"
                  matTooltip="Save Changes"
                  matTooltipPosition="right"
                  (click)="helperListTable.saveEdit()"
                >
                  <mat-icon>check_circle</mat-icon>
                </button>
                <button
                  mat-icon-button
                  color="warn"
                  matTooltip="Discard Changes"
                  matTooltipPosition="right"
                  (click)="helperListTable.cancelEdit()"
                >
                  <mat-icon>cancel</mat-icon>
                </button>
              </div>
            </ng-template>
          </span>
          <span *ngSwitchCase="'categories'">
            <ng-container *ngIf="helperListTable.editIndex !== i; else editRow">
              {{ helperListTable.formattedCategories(row["categories"]) }}
            </ng-container>
            <ng-template #editRow>
              <div [formGroup]="helperListTable.editForm">
                <mat-form-field>
                  <input matInput [formControlName]="'categories'" />
                  <button
                    *ngIf="row['categories']"
                    matSuffix
                    mat-icon-button
                    aria-label="Clear"
                    tabindex="-1"
                    (click)="helperListTable.clearInput(helperListTable.editForm.get('categories'))"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
            </ng-template>
          </span>

          <span *ngSwitchCase="'definition'">
            <ng-container>
              <div class="form-group mb-4 custom-select">
                <div class="form-control">
                  <mat-select
                    [formControl]="selectControls[i]"
                    (selectionChange)="onSelectionChange($event, column, row, i)"
                  >
                    <mat-option *ngFor="let item of alignmentOptions" [value]="item">
                      {{ item.displayAs }}
                    </mat-option>
                  </mat-select>
                </div>
              </div>
            </ng-container>
          </span>
          <span *ngSwitchCase="'_transactionDate'">{{
            GlossDate.extractDate(row) | dateFormat
          }}</span>
          <span *ngSwitchDefault>{{ column.cell(row) }}</span>
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      [ngClass]="helperListTable.getRowColor(row)"
    ></tr>
  </table>
  <mat-paginator
    [length]="transactions.length"
    [pageSize]="10"
    [pageIndex]="0"
    [pageSizeOptions]="[10, 25, 50, 100]"
    (page)="onPageChange($event)"
  >
  </mat-paginator>
</cdk-virtual-scroll-viewport>
