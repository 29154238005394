<div class="sync-status-container">
  <span class="key">{{ key }}</span>
  <span class="status-icon-container">
    <svg
      *ngIf="icon"
      class="tw-h-5 tw-w-5 {{ icon === SyncIcon.syncAll && 'tw-animate-spin-slow' }}"
    >
      <use [attr.xlink:href]="'images/sprite.svg#' + icon"></use>
    </svg>
  </span>
</div>
