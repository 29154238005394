import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { formatDistanceToNow } from "date-fns";

import {
  DateAtomRegExType,
  DateFormatIndexKeyType,
  PermutationListType,
  regexPermutationList,
  separator1Regex,
  separator2Regex,
  TripletType,
} from "@bitwarden/web-vault/app/shared/utils/helper.date/date-format-regex";
import {
  amPmRegex,
  Time12HrFormatPatterns,
  Time24HrFormatPatterns,
  TimePermutationListType,
} from "@bitwarden/web-vault/app/shared/utils/helper.date/time-format-regex";
import {
  tzRegexOOOO,
  tzRegexZZZZ,
  tzRegexZ,
  tzRegexxxx,
} from "@bitwarden/web-vault/app/shared/utils/helper.date/tz-format-regex";

import { DateFormatIndex, PossibleDateIndices } from "../../../models/types/general-types";

type MatchedTime = {
  hours: string;
  minutes: string;
  seconds?: string;
  amPm?: string;
};

const setDateFormatIndexMap = (
  dfi: DateFormatIndex,
  group: DateFormatIndexKeyType,
  index: number
) => {
  if (group === "day") {
    dfi.day = index;
  } else if (group === "month") {
    dfi.month = index;
  } else if (group === "year") {
    dfi.year = index;
  }

  return dfi;
};
@Injectable({
  providedIn: "root",
})
export default class DateFormat {
  constructor(private datePipe?: DatePipe) {}
  /**
   * @deprecated
   * @param possibleIndices
   * @param separator
   */
  static getPossibleDateFormats(possibleIndices: PossibleDateIndices, separator: string) {
    const mapArray: Map<number, string>[] = [];
    const possibleFormatsArr: string[] = [];
    possibleIndices.forYear.forEach((yearIndex) => {
      const mIndices = possibleIndices.forMonth.filter((mi) => mi !== yearIndex);
      mIndices.forEach((monthIndex) => {
        const dIndices = possibleIndices.forDay.filter(
          (di) => di !== yearIndex && di !== monthIndex
        );
        dIndices.forEach((dayIndex) => {
          if (yearIndex !== monthIndex && monthIndex !== dayIndex) {
            const indexMap = new Map<number, string>();
            indexMap.set(yearIndex, "YYYY");
            indexMap.set(monthIndex, "MM");
            indexMap.set(dayIndex, "DD");
            mapArray.push(indexMap);
          }
        });
      });
    });

    mapArray.forEach((indexMap) => {
      const possibleFormat = `${indexMap.get(0)}${separator}${indexMap.get(
        1
      )}${separator}${indexMap.get(2)}`;
      possibleFormatsArr.push(possibleFormat);
    });

    return possibleFormatsArr;
  }

  static getPossibleTimeFormatsFromRegEx(dateTimeString: string): string | null {
    const amPmMatch = new RegExp(amPmRegex.regex).exec(dateTimeString);

    const patterns: TimePermutationListType =
      amPmMatch && amPmMatch.groups?.amPm ? Time12HrFormatPatterns : Time24HrFormatPatterns;

    for (const pattern of patterns) {
      // const regString = timeSeparatorRegex + pattern.map((regexGroup) => `(${regexGroup.regex})`).join(":");
      const regString = pattern.map((regexGroup) => `(${regexGroup.regex})`).join(":");
      const regex = new RegExp(regString);
      const match = regex.exec(dateTimeString);

      if (match) {
        const matchedTime: MatchedTime = {
          hours: match.groups?.hours || "",
          minutes: match.groups?.minutes || "",
          seconds: match.groups?.seconds || "",
          amPm: amPmMatch?.groups?.amPm,
        };

        const { hours, minutes, seconds, amPm } = matchedTime;
        let formattedTime = `${hours}:${minutes}`;

        if (seconds) {
          formattedTime += `:${seconds}`;
        }

        if (amPm) {
          formattedTime += ` ${amPm}`;
        }

        return formattedTime;
      }
    }

    return null;
  }

  static getPossibleTimeZoneFormatsFromRegEx(dateTimeString: string): string | null {
    const tzMatchZ = new RegExp(tzRegexZ.regex).exec(dateTimeString);
    const tzMatchZZZZ = new RegExp(tzRegexZZZZ.regex).exec(dateTimeString);
    const tzMatchOOOO = new RegExp(tzRegexOOOO.regex).exec(dateTimeString);
    const tzMatchxxx = new RegExp(tzRegexxxx.regex).exec(dateTimeString);

    if (tzMatchZ) {
      return tzMatchZ.groups?.timezone;
    } else if (tzMatchZZZZ) {
      return tzMatchZZZZ.groups?.timezone;
    } else if (tzMatchOOOO) {
      return tzMatchOOOO.groups?.timezone;
    } else if (tzMatchxxx) {
      return tzMatchxxx.groups?.timezoneOffset;
    }

    return null;
  }

  static getPossibleDateFormatsFromRegEx(dateStrings: string[]) {
    // todo fix the cross-product
    const permutation = this.getDateFormatPermutation(regexPermutationList);

    const possibleFormat: Record<string, number> = {};
    const dateFormatIndexMap: Record<string, DateFormatIndex> = {};

    /* Test all the permutation on the list of date string. Compile the match count */
    for (const candidate of permutation) {
      const regString = `${candidate[0].regex}${separator1Regex}${candidate[1].regex}${separator2Regex}${candidate[2].regex}`;
      const regex = new RegExp(regString, "ig");

      // todo optimise and break loop if there is a clear winner
      for (const dateString of dateStrings) {
        const match = regex.exec(dateString);
        if (match) {
          if (match.groups["separator1"] === match.groups["separator2"]) {
            const separator = match.groups["separator1"];
            const format = `${candidate[0].matchedFormat}${separator}${candidate[1].matchedFormat}${separator}${candidate[2].matchedFormat}`;

            if (possibleFormat[format]) {
              possibleFormat[format]++;
            } else {
              /* Generate Mapping*/
              possibleFormat[format] = 1;
              const dateFormatIndex: DateFormatIndex = {
                year: undefined,
                day: undefined,
                month: undefined,
                separator: match.groups["separator1"],
                format,
              };
              setDateFormatIndexMap(dateFormatIndex, candidate[0].datePart, 0);
              setDateFormatIndexMap(dateFormatIndex, candidate[1].datePart, 1);
              setDateFormatIndexMap(dateFormatIndex, candidate[2].datePart, 2);
              dateFormatIndexMap[format] = dateFormatIndex;
            }
          }
        }
      }
    }

    if (Object.keys(dateFormatIndexMap).length === 0) {
      /* Test all the permutation on the list of date string. Compile the match count */
      for (const candidate of permutation) {
        const candidateWithoutYear = candidate.filter((c) => !c.matchedFormat.includes("Y"));
        const regString = `${candidateWithoutYear[0].regex}${separator1Regex}${candidateWithoutYear[1].regex}`;
        const regex = new RegExp(regString, "ig");

        // todo optimise and break loop if there is a clear winner
        for (const dateString of dateStrings) {
          const match = regex.exec(dateString);
          if (match) {
            const separator = match.groups["separator1"];
            const format = `${candidateWithoutYear[0].matchedFormat}${separator}${candidateWithoutYear[1].matchedFormat}`;

            if (possibleFormat[format]) {
              possibleFormat[format]++;
            } else {
              /* Generate Mapping*/
              possibleFormat[format] = 1;
              const dateFormatIndex: DateFormatIndex = {
                year: undefined,
                day: undefined,
                month: undefined,
                separator: match.groups["separator1"],
                format,
              };
              setDateFormatIndexMap(dateFormatIndex, candidateWithoutYear[0].datePart, 0);
              setDateFormatIndexMap(dateFormatIndex, candidateWithoutYear[1].datePart, 1);
              //setDateFormatIndexMap(dateFormatIndex, candidate[2].datePart, 2);
              dateFormatIndexMap[format] = dateFormatIndex;
            }
          }
        }
      }
    }
    /* For the highest match, get all the given format for that and return*/
    const highestMatchFormats: Array<string> = [];

    const countPerFormat = Object.values(possibleFormat);
    countPerFormat.sort((a, b) => {
      return a - b;
    });
    const bestMatchCount = countPerFormat.pop();
    for (const candidate of Object.entries(possibleFormat)) {
      if (bestMatchCount === candidate[1]) {
        highestMatchFormats.push(candidate[0]);
      }
    }

    return { highestMatchFormats, dateFormatIndexMap };
  }

  /**
   * Creates an array of 3 regex array that contains all the permutation of day, month, year regex in any order. e.g [[year month day]... [monthText year day] ... ] etc
   * @param elements {DateAtomRegExType}
   */
  static getDateFormatPermutation = (elements: DateAtomRegExType[]) => {
    const permutation: PermutationListType = [];
    const elementsIteration = [...elements];

    while (elementsIteration.length > 0) {
      /* Get each element for the start of each permutation */
      const t1 = elementsIteration.pop();

      /* For all other elements of different part. create a sub array and loop */
      const t2Loop = elements.filter((e) => e.datePart != t1.datePart);
      for (const t2 of t2Loop) {
        /* For all other elements not part or t1 or t2. Loop in them as t3loop */
        const t3Loop = elements.filter(
          (e) => e.datePart != t1.datePart && e.datePart != t2.datePart
        );
        for (const t3 of t3Loop) {
          const triplet = [t1, t2, t3] as TripletType;
          permutation.push(triplet);
        }
      }
    }
    return permutation;
  };

  // static getTZFormatPermutation = (elements: DateAtomRegExType[]) => {
  //   return [];
  // };
  //

  /*TODO change the places properly so only one method remains here*/
  static getDateStringFromStampStatically(
    timestamp: number,
    dateFormatIndex?: DateFormatIndex
  ): string {
    const dateFormatInstance = new DateFormat();
    return dateFormatInstance.getDateStringFromStamp(timestamp, dateFormatIndex);
  }

  getDateStringFromStamp(timestamp: number, dateFormatIndex?: DateFormatIndex): string {
    const date = new Date(timestamp);

    // Extract date components
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
    const day = date.getDate().toString().padStart(2, "0");

    let formattedDate = "";
    if (!dateFormatIndex) {
      formattedDate = `${year}-${month}-${day}`;
    } else {
      const array = [];
      array[dateFormatIndex.year] = year;
      array[dateFormatIndex.month] = month;
      array[dateFormatIndex.day] = day;

      formattedDate = array.join(dateFormatIndex.separator);
    }

    return formattedDate;
  }

  isDateStringValid(dateString: string): boolean {
    const dateObject = new Date(dateString);
    return dateObject.toString() !== "Invalid Date";
  }

  cleanDateString(dateString: string): string {
    return dateString?.trim().replace(/[-/]/g, "");
  }

  parseStringToDate(date: string) {
    // YYYYMMDD
    const year = parseInt(date.substring(0, 4), 10);
    const month = parseInt(date.substring(4, 6), 10) - 1; // Months are 0-based in JavaScript
    const day = parseInt(date.substring(6, 8), 10);
    return new Date(year, month, day);
  }

  toRelativeDays(date: Date) {
    return formatDistanceToNow(date, { addSuffix: true });
  }

  toDateWithRelativeDays(date: string) {
    const dateString = this.cleanDateString(date);
    const MAX_LENGTH_DATE_STRING = 8;

    if (dateString.length !== MAX_LENGTH_DATE_STRING) {
      return "";
    }

    const DATE_FORMAT = "yyyy-MM-dd";
    const parsedDate = this.parseStringToDate(dateString);
    const formattedDate = this.datePipe.transform(parsedDate, DATE_FORMAT);
    const formattedDuration = this.toRelativeDays(parsedDate);
    return {
      date: formattedDate,
      duration: formattedDuration,
      text: `${formattedDate} (${formattedDuration})`,
    };
  }
}
