import { Injectable, Injector } from "@angular/core";
import { take } from "rxjs";

import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { SyncIcon } from "@bitwarden/web-vault/app/models/enum/sync.enum";
import { ISync, ISyncStore } from "@bitwarden/web-vault/app/models/interfaces/sync.interface";
import {
  AccountState,
  GlossConnector,
  Origin,
  StatusPoint,
} from "@bitwarden/web-vault/app/models/types/general-types";
import { SyncStatusPoints } from "@bitwarden/web-vault/app/services/api/basiq/fixture/sync-status-messages";
import { SyncStore } from "@bitwarden/web-vault/app/services/syncing/syncing.store";

import { InstitutionGroup } from "../../models/types/institution.type";
import { AccountView } from "../../models/view/account.view";

import { BasiqInstitutionConnection } from "./basiq-institution-connection";

@Injectable({
  providedIn: "root",
})
export class BasiqConnector implements ISync {
  id: string;
  accountViews: AccountView[];
  institutionConnections: BasiqInstitutionConnection[] = [];
  origin = Origin.basiq;

  private authService: BasiqAuth;
  private syncStore: SyncStore;

  constructor(accountViews: AccountView[], private injector: Injector) {
    this.id = crypto.randomUUID();
    this.authService = this.injector.get(BasiqAuth);
    this.accountViews = accountViews;
  }

  async sync() {
    this.syncStore = this.injector.get(SyncStore);

    const isBasiqUser = await this.authService.isBasiqUser();
    if (!isBasiqUser) {
      this.failSync(SyncStatusPoints.noBasiqUser);
      return;
    }

    const glossConnector = await this.getConnector();
    if (!glossConnector?.isOpen) {
      const statusPoint: StatusPoint = this.getConnectorStatusPoint(glossConnector);
      this.failSync(statusPoint);
    } else {
      this.setInstitutionConnections();
      this.runInstoLevelSync();
    }
  }

  runInstoLevelSync() {
    this.institutionConnections.forEach((instoConnection) => {
      instoConnection.sync().then(() => null);
    });
  }

  /** Return a status point based on the connector state */
  getConnectorStatusPoint(glossConnector: GlossConnector): StatusPoint {
    return {
      key: "failed",
      icon: SyncIcon.xCircle,
      data: {
        type: "failure",
        message: glossConnector.message,
        messageI18nKey: glossConnector.message,
        actions: glossConnector.actions,
      },
    };
  }

  failSync(statusPoint: StatusPoint) {
    this.syncStore.getCurrentState.pipe(take(1)).subscribe((currentState) => {
      this.updateState(currentState, statusPoint);
    });
  }

  updateState(currentState: ISyncStore, statusPoint: StatusPoint) {
    const newStatus = this.getNewState(currentState, statusPoint);

    this.syncStore.updateAccountStatus(newStatus);
  }

  getNewState(currentState: ISyncStore, statusPoint: StatusPoint): AccountState[] {
    return currentState.accountsState.map((accountStatus) => {
      return this.getUpdatedAccountStatus(accountStatus, statusPoint);
    });
  }

  getUpdatedAccountStatus(accountStatus: AccountState, statusPoint: StatusPoint): AccountState {
    const isAccount = this.accountViews.some(
      (accountView) => accountView.originalBook.id === accountStatus.accountId
    );

    if (isAccount) {
      accountStatus.point = statusPoint;
      accountStatus.isCompleted = true;
    }

    return accountStatus;
  }

  async getConnector(): Promise<GlossConnector> {
    try {
      const isConsent = await this.authService.isUserConsent();
      if (!isConsent) {
        return {
          message: "We need your consent to continue",
          connectorOrigin: Origin.basiq,
          isOpen: false,
          key: "consent-error",
          actions: ["consent"],
        };
      }

      return {
        message: "Connector is available",
        connectorOrigin: Origin.basiq,
        isOpen: true,
        key: "connector-available",
        actions: [],
      };
    } catch (error) {
      return {
        message: "Something went wrong",
        connectorOrigin: Origin.basiq,
        isOpen: false,
        key: "consent-error",
        actions: ["retry", "consent"],
      };
    }
  }

  private getGroupOfInstitutions(): InstitutionGroup[] {
    const sortedAccountViews = this.accountViews.sort((a: AccountView, b: AccountView) =>
      a.name.localeCompare(b.name)
    );
    return sortedAccountViews.reduce((grouped: InstitutionGroup[], accountView) => {
      const key = accountView.institution.basiqId;
      const group = grouped.find((g) => g.institutionId === key);
      if (!group) {
        grouped.push({ institutionId: key, accountViews: [accountView] });
      } else {
        group.accountViews.push(accountView);
      }
      return grouped;
    }, []);
  }

  setInstitutionConnections() {
    const groupOfInstitutions = this.getGroupOfInstitutions();

    for (const institutionGroup of groupOfInstitutions) {
      const institutionConnector = new BasiqInstitutionConnection(institutionGroup, this.injector);
      this.institutionConnections.push(institutionConnector);
    }
  }
}
