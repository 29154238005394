import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  AfterViewInit,
  Injector,
  OnChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSelect } from "@angular/material/select";
import * as d3 from "d3";
import { debounce } from "lodash";
import { DeviceDetectorService } from "ngx-device-detector";

import { DashboardParameters } from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { Arrows } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/arrows";
import { Axis } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/axis";
import { DataLine } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/data-line";
import { DataPoints } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/data-points";
import { DateTimeFormatter } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/date-time-formatter";
import { DottedLines } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/dotted-lines";
import { InOutBoxes } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/in-out-boxes";
import { Scales } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/scales";
import { Slider } from "@bitwarden/web-vault/app/components/primary-summary-graph/graph-elements/slider";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import {
  Category,
  getDefaultCategory,
} from "@bitwarden/web-vault/app/models/data/blobby/category.data";
import {
  Classification,
  getDefaultClassification,
} from "@bitwarden/web-vault/app/models/data/blobby/classification.data";
import { PreferenceType } from "@bitwarden/web-vault/app/models/enum/preferenceType";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { GranularityProperty } from "@bitwarden/web-vault/app/models/types/balanceGroupingTypes";
import { BandData, GraphDataSet } from "@bitwarden/web-vault/app/models/types/graph.types";
import { ScenarioData } from "@bitwarden/web-vault/app/models/types/scenario-group.types";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { PerformanceService } from "@bitwarden/web-vault/app/services/performance/performance.service";
import { MainProgressBar } from "@bitwarden/web-vault/app/services/progress-bar/main-progress-bar";
import { SharedService } from "@bitwarden/web-vault/app/shared/sharedAppEvent.service";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper-common";

type D3ActionType = "select" | "lock";

@Component({
  selector: "app-primary-summary-graph",
  templateUrl: "./primary-summary-graph.component.html",
})

/**
 * PrimarySummaryGraphComponent - this component handles rendering of the primary summary graph including all the D3
 *                                rendering.
 */
export class PrimarySummaryGraphComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild(MatMenuTrigger) processMenuTrigger!: MatMenuTrigger;
  @ViewChild("accountSelect") accountSelect!: MatSelect;
  @ViewChild("directionSelect") directionSelect!: MatSelect;
  @ViewChild("categorySelect") categorySelect!: MatSelect;
  @ViewChild("classificationSelect") classificationSelect!: MatSelect;
  @ViewChild("granularitySelect") granularitySelect!: MatSelect;
  // input of formatted data to be plotted on the graph
  @Input() graphData: Array<GraphDataSet>;
  @Input() scenarioData: ScenarioData;
  @Input() balanceData: Array<GraphDataSet>;
  @Input() dashboardParameters: DashboardParameters;

  // these are passed down to the set the granularity dropdown
  @Input() defaultGranularity: GranularityProperty;
  @Input() granularityOptions: Array<GranularityProperty>;

  // these are passed down to the date range picker component
  @Input() defaultDates: Array<Date>;

  @Output() handleSpinner = new EventEmitter<boolean>();
  @Output() callFilter = new EventEmitter<any>();
  @Output() callScenario = new EventEmitter<any>();
  private isMobile = false;
  // d3 related elements. Some are difficult to type properly so have used any.
  private svg: any;
  private psgContainerSelection: any;
  private graphContentGroup: any;
  private previousHighlightedPoint: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private previousHighlightedScenarioPoints: Array<
    d3.Selection<SVGSVGElement, unknown, HTMLElement, any>
  > = [];
  private balancePointData: number[][];
  private maxYAxisValue: number;
  private minYAxisValue: number;
  private markerLine: d3.Selection<SVGSVGElement, unknown, HTMLElement, any>;
  private xScale: d3.ScaleBand<string>;
  private yScale: d3.ScaleLinear<number, number, never>;

  private graphRendered = false; // indicates graph has been initialised
  private parsedDates: Array<{ mY: string; display: string }>;
  private bandData: BandData;
  private scenarioCount = 0;
  private prevBandDate: Date; // calculated values for additional scenario points
  private prevBandBalance: number; // calculated values for additional scenario points
  private _selectedBand: number;
  private _isBandSelectionLocked = false;

  // Sizing and spacing settings
  private outerWidth = 800;
  private outerHeight = 250;

  // Graph Elements controlled by graph element classes
  private dateTimeFormatter: DateTimeFormatter;
  private scales: Scales;
  private axis: Axis;
  private slider: Slider;
  private dataLine: DataLine;
  private inOutBoxes: InOutBoxes;
  private dottedLines: DottedLines;
  private arrows: Arrows;
  private dataPoints: DataPoints;

  /** Holds the selected accounts that user has selected and applied the filters to */
  selectedAccountIds: string[] = [];

  /** holds the base currency, so we show in graph's Y axis's label */
  baseCurrency = "AUD";

  /** Holds all the accounts of the user*/
  accounts: Book[] = [];

  /** Holds the accounts that user has selected and applied the filters to */
  selectedAccounts: Book[] = [];

  /** Holds the accounts that user has searched in the searchbar above the options list */
  filteredAccounts: Book[] = [];

  directions: TransactionDirection[] = [TransactionDirection.In, TransactionDirection.Out];
  /** Holds the options of direction user has chosen  */
  selectedDirections: TransactionDirection[] = [TransactionDirection.In, TransactionDirection.Out];

  /** Holds all the categories of the user*/
  categories: Category[] = [];

  /** Holds the categories that user has selected and applied the filters to */
  selectedCategories: Category[] = [];

  /** Holds the categories that user has searched in the searchbar above the options list */
  filteredCategories: Category[] = [];

  /** Holds all the classifications of the user*/
  classifications: Classification[] = [];

  /** Holds the classifications that user has selected and applied the filters to */
  selectedClassifications: Classification[] = [];

  /** Holds the classifications that user has searched in the searchbar above the options list */
  filteredClassifications: Classification[] = [];

  /** Holds the granularity that user has selected and applied to the graph */
  selectedGranularity: string;

  /** Holds the options that user choose but not applied the filters yet */
  selectedAccountsControl: FormControl<Book[]> = new FormControl();
  selectedDirectionsControl: FormControl<TransactionDirection[]> = new FormControl(this.directions);
  selectedCategoriesControl: FormControl<Category[]> = new FormControl();
  selectedClassificationsControl: FormControl<Classification[]> = new FormControl();
  selectedGranularityControl: FormControl<string> = new FormControl();

  // spacing around the graph
  private margin: { top: number; bottom: number; left: number; right: number } = {
    top: 20,
    bottom: 70,
    left: 60,
    right: 20,
  };

  private initialised = false;

  constructor(
    private dashboardService: DashboardService, // get the dashboard service, so we can update dates and filter dates
    private dataRepositoryService: DataRepositoryService, // get the dashboard service, so we can update dates and filter dates
    private perfService: PerformanceService,
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private preferenceService: PreferenceService,
    private mainProgressBar: MainProgressBar,
    private injector: Injector
  ) {
    this.isMobile = this.deviceService.isMobile();
    if (this.isMobile) {
      this.outerWidth = 350;
      this.margin.left = 43;
      this.margin.right = 0;
    }
    this.sharedService.applyEvent$.subscribe(() => {
      this.applySelectedAccounts(this.selectedAccounts);
    });
  }

  ngAfterViewInit() {
    this.perfService.end();
  }

  private updateAxisStylesForMobile() {
    if (this.isMobile) {
      this.svg.selectAll("#x-axis g.tick text, #y-axis g.tick text").style("font-size", "0.76rem");
    }
  }

  /**
   * ngOnInit - Initialise the d3 elements
   */
  async ngOnInit() {
    this.initialised = false;
    this.baseCurrency = (await this.preferenceService.get(PreferenceType.baseCurrency)) as string;
    this.perfService.mark("PrimarySummaryGraphComponent");
    this.accounts = await this.dataRepositoryService.getAllBooks();
    this.selectedAccountsControl.setValue(this.accounts);
    this.selectedAccounts = this.accounts;
    this.selectedAccountIds = this.accounts.map((account) => account.id);
    this.filteredAccounts = [...this.selectedAccounts];
    this.categories = await this.dataRepositoryService.getAllCategories();
    this.categories.push(getDefaultCategory());
    this.selectedCategoriesControl.setValue(this.categories);
    this.selectedCategories = this.categories;
    this.filteredCategories = [...this.selectedCategories];
    this.classifications = await this.dataRepositoryService.getAllClassifications();
    this.classifications.push(getDefaultClassification());
    this.selectedClassificationsControl.setValue(this.classifications);
    this.selectedClassifications = this.classifications;
    this.filteredClassifications = [...this.selectedClassifications];

    this.initSVG();

    this.initGroupings();
    this.initDateFormatters();
    this.initScales();
    this.initAxis();
    this.initSlider();
    this.initDataLine();
    this.initMarker();
    this.initInOutBoxes();
    this.initDottedLines();
    this.initArrows();
    this.initDataPoints();

    this.initialised = true;
    this.updateGraph();
  }

  ngOnChanges(changes: SimpleChanges) {
    // if the dashboardID changes, update the hud max height
    // note this must happen before the graph plotting in case granularity changes and the axis needs to be updated
    if (changes?.dashboardParameters) {
      this.resetGranularity(this.defaultGranularity);
    }
    if (changes?.defaultGranularity) {
      // only reset the granularity if the default is updated to something other than null
      if (this.defaultGranularity) {
        this.resetGranularity(this.defaultGranularity);
        // this.updateGranularityOptions(this.granularityOptions);
      }
    }
    // do the following if graphData has been updated
    if (changes?.graphData?.currentValue?.length > 0) {
      this.scenarioData = {
        scenario: [],
        balance: [],
      };
      this.updateGraph();
      this.initMarker();
      setTimeout(() => this.mainProgressBar.setIsLoadingCompleted(true), 0);
    }
    // do the following if scenarioData has been updated
    if (
      changes?.scenarioData?.currentValue?.scenario?.length > 0 ||
      changes?.scenarioData?.currentValue?.balance?.length > 0
    ) {
      // set the balance component to graphData so the balance portion of the line can be plotted
      this.graphData = this.scenarioData.balance;
      this.updateGraph();
      this.initMarker();

      setTimeout(() => this.mainProgressBar.setIsLoadingCompleted(true), 0);
    }
  }

  ngOnDestroy() {
    if (this.svg) {
      this.svg.remove();
    }
  }

  async graphScenario() {
    this.accountSelect.close();
    this.handleSpinner.emit(true);
    setTimeout(() => {
      this.callScenario.emit();
    }, 250);
  }

  // <editor-fold desc="Filter Functions">
  private applyFilters() {
    this.handleSpinner.emit(true);
    setTimeout(() => {
      this.callFilter.emit({
        selectedAccounts: this.selectedAccounts,
        selectedDirections: this.selectedDirections,
        selectedCategories: this.selectedCategories,
        selectedClassifications: this.selectedClassifications,
      });
    }, 250);
  }

  /** filters the transactions based on selected accounts */
  applySelectedAccounts(selectedAccounts: Book[]) {
    this.selectedAccounts = selectedAccounts;
    /*  this.accountSelect.close();*/
    this.applyFilters();
  }

  private updateGraph(): void {
    if (
      this.initialised === false ||
      (!this.graphData && !this.scenarioData) ||
      (this.graphData.length === 0 && this.scenarioData?.scenario.length === 0)
    ) {
      return;
    }

    this._isBandSelectionLocked = false;
    this.updateGraphElements();
    this.inOutBoxes.updateIncomeExpensesBoxes(this.graphData);
    this.dottedLines.plotDottedLines(this.graphData, this.dataLine);
    this.dataLine.updateBalanceLineGraph(this.graphData);
    this.plotBalancePoints();
    this.plotScenarios();

    this.addMouseMoveEvent();

    // todo Alex hack to set the MarkerLine and dot on the right band. It's always the most left side at this stage.
    // this should be manage by code like setDefaultSelection in the future.
    this._selectedBand = this.updateMarketLineDraw(this.innerWidth() - 1, this.innerWidth());
    // this.inOutBoxes.updateEstimateIncomeExpensesBoxes(this.graphData);
    // this.updateEstimateBalanceLine();
    // this.updateEstBalancePoints();
  }

  private updateMarketLineDraw(relative_x: number, max_x: number): number {
    const snappedPos = this.slider.snap([relative_x], max_x);
    this.dataLine.moveMarkerLine(snappedPos.graph[0]);
    this.highlightPoint(snappedPos.graph[0]);
    return snappedPos.bands[0];
  }

  private updateGraphElements() {
    // TODO @Sinan@Michelle - My guess is previously we were handling granularity changes in here but since we moved it to navbar it is not getting the granularity update in dateFormatter
    //  since we are passing dateFormatter as a parameter we need to make sure it gets the granularity that is being used in the service
    this.dateTimeFormatter.updateGranularity(this.dashboardService.granularity);
    // get a set of displayed dates for the x-axis
    this.parsedDates = this.dateTimeFormatter.parseDates(this.graphData, this.scenarioData);

    // create the combined band data to be used for snapping
    this.bandData = this.slider.combineDataForBands(this.graphData, this.scenarioData);

    [this.minYAxisValue, this.maxYAxisValue] = this.axis.calculateMaxMinYAxisValues(
      this.dottedLines,
      this.graphData,
      this.scenarioData
    );

    // set the scales for x and y
    this.scales.setScales(
      this.innerWidth(),
      this.innerHeight(),
      this.minYAxisValue,
      this.maxYAxisValue,
      this.parsedDates,
      this.graphData,
      this.scenarioData
    );

    if (!this.graphRendered) {
      this.axis.drawAxis(this.innerHeight(), this.graphData, this.parsedDates);
      this.graphRendered = true;
      this.slider.drawDateSlider(this.innerWidth(), this.innerHeight(), this.bandData);
    } else {
      this.axis.redrawAxis(this.innerHeight(), this.graphData, this.parsedDates);
      this.slider.updateDateSlider(this.innerWidth(), this.bandData);
    }

    // calculate the previous balance before the Anchor point if the anchor point is plottable
    this.calculateAnchorPrevBalance();

    this.updateAxisStylesForMobile();
  }

  /**
   *
   * @private
   */
  private plotBalancePoints() {
    this.removeMidPoints();
    this.balancePointData = this.dataPoints.generatePointData(this.graphData);
    this.dataPoints.updatePoints("balancePoints", "black", this.balancePointData, 1);

    const checkPoints = [0];
    if (this.graphData.length > 1) {
      checkPoints.push(this.graphData.length - 1);
    }

    this.plotMidPoints("midBalancePoints", checkPoints, this.graphData);
  }

  private plotScenarios() {
    const scenarioEndPoints = [];
    if (this.scenarioData?.scenario.length > 0) {
      let index = 0;
      for (const scenario of this.scenarioData.scenario) {
        this.scenarioCount = index;
        this.dataLine.updateScenarioLines(scenario.graphData, index, this.arrows, this.dataPoints);
        index++;

        if (scenario.graphData.length > 1) {
          scenarioEndPoints.push(scenario.graphData[scenario.graphData.length - 1]);
        }
      }
      const checkPoints = [...scenarioEndPoints.keys()];
      this.plotMidPoints("scenarioMidPoints", checkPoints, scenarioEndPoints);
      if (this.scenarioData?.anchorPoint) {
        const anchorPoint = this.dataLine.plotAnchorPoint(
          this.scenarioData.anchorPoint,
          this.scenarioData.scenario
        );
        if (this.prevBandBalance !== null) {
          this.dataLine.plotPrevBandBalance(this.prevBandBalance, this.prevBandDate, anchorPoint);
        }
      } else {
        this.dataLine.removeAnchorPoint();
      }
    } else {
      this.dataLine.removeScenarioLines(this.scenarioCount);
      this.scenarioCount = 0;
    }
  }

  private plotMidPoints(
    pointName: string,
    checkPoints: Array<number>,
    dataPoints: Array<GraphDataSet>
  ) {
    const midPoints = [];

    // check if there is a midway starting point or end point that needs to be plotted
    for (const checkPoint of checkPoints) {
      if (dataPoints?.[checkPoint]?.midDate && dataPoints[checkPoint].midDate) {
        if (dataPoints[checkPoint].balance !== null) {
          const yValue = this.yScale(dataPoints[checkPoint].balance);
          const bandData = this.slider.findBand(dataPoints[checkPoint].midDate);
          const xValue = this.slider.calculateMiddleX(bandData, dataPoints[checkPoint].midDate);
          const midPoint = [xValue, yValue];
          midPoints.push(midPoint);
        }
      }
    }

    if (midPoints.length > 0) {
      this.dataPoints.updatePoints(pointName, "#2A6CE2", midPoints, 1, "#2A6CE2");
    }
  }

  private removeMidPoints() {
    this.graphContentGroup.selectAll("#midBalancePoints").remove();
    this.graphContentGroup.selectAll("#scenarioMidPoints").remove();
  }

  private calculateAnchorPrevBalance() {
    this.prevBandDate = null;
    this.prevBandBalance = null;

    if (this.scenarioData?.anchorPoint?.groupedBalance) {
      const anchorBandIndex = this.slider.findBandIndex(
        new Date(this.scenarioData.anchorPoint.anchorDate)
      );
      const prevBandIndex = anchorBandIndex - 1;

      if (prevBandIndex >= 0 && this.bandData?.[prevBandIndex]) {
        // find the balance for the previous band date before the anchor point
        this.prevBandDate = this.dateTimeFormatter.timeParse(this.bandData[prevBandIndex].endDate);
        this.prevBandBalance = this.dashboardService.getBalanceOnDate(
          this.scenarioData.anchorPoint.groupedBalance,
          this.prevBandDate
        );

        // if the previous balance is above or below the max Y axis, then we need to extend the axis to accommodate
        if (this.prevBandBalance !== null && this.prevBandBalance > this.maxYAxisValue) {
          this.maxYAxisValue = this.prevBandBalance;
          this.scales.setYScales(this.innerHeight(), this.minYAxisValue, this.maxYAxisValue);
          this.axis.redrawYAxis(this.innerHeight(), this.graphData);
        } else if (this.prevBandBalance !== null && this.prevBandBalance < this.minYAxisValue) {
          this.minYAxisValue = this.prevBandBalance;
          this.scales.setYScales(this.innerHeight(), this.minYAxisValue, this.maxYAxisValue);
          this.axis.redrawYAxis(this.innerHeight(), this.graphData);
        }
      }
    }
  }

  // </editor-fold desc="Update Graph Functions">

  // <editor-fold desc="Mouse Move Events">

  private addMouseMoveEvent() {
    this.svg.on("mousemove", (e: any) =>
      this.debounceD3Event(this.handleMouseMoveEvent(e, "select"), 100)
    );
    this.svg.on("click", (e: any) =>
      this.debounceD3Event(this.handleMouseMoveEvent(e, "lock"), 100)
    );
  }

  private handleMouseMoveEvent(e: any, action: D3ActionType): any {
    const pointerCoords = d3.pointer(e);
    // only update if the y movement is below the hud box and above the y-axis
    if (!this.isYCoordinateInRange(pointerCoords[1])) {
      return;
    }

    // 1 - If you are move hovering the graph and the band selection is locked. Do not allow the band to be selected
    // 2 - IF you re-click the char unlock the band selection, and process to normal selection
    // 3 - If you are clicking the graph and the band selection is not lock. Select the band and lock ( at the end )
    if (action === "select" && this._isBandSelectionLocked) {
      return;
    } else if (action === "lock" && this._isBandSelectionLocked === true) {
      this._isBandSelectionLocked = false;
    } else if (action === "lock" && !this._isBandSelectionLocked) {
      this._isBandSelectionLocked = true;
    }

    this._selectedBand = this.updateMarketLineDraw(
      pointerCoords[0] - this.margin.left,
      this.innerWidth()
    );
  }

  private debounceD3Event(handleMouseEvent: any, wait: number) {
    return () => {
      debounce(handleMouseEvent, wait);
    };
  }

  private isYCoordinateInRange(yCoordinate: number) {
    return yCoordinate >= 0 && yCoordinate <= this.innerHeight();
  }

  private highlightPoint(xPosition: number) {
    // Reset the color of the previously highlighted point
    if (this.previousHighlightedPoint) {
      this.previousHighlightedPoint.attr("fill", "white");
    }
    for (const previousHighlightedScenarioPoint of this.previousHighlightedScenarioPoints) {
      previousHighlightedScenarioPoint.attr("fill", "white");
    }
    this.previousHighlightedScenarioPoints = [];

    // Select the point you want to change the color of based on its coordinates
    const highlightedPoint = this.graphContentGroup
      .selectAll("#balancePoints")
      .filter((d: [number, number]) => d[0] === xPosition);

    // Change the fill color to green for the new highlighted point
    highlightedPoint.attr("fill", "#078888").attr("fill-opacity", 0.8);

    for (let i = 0; i <= this.scenarioCount; i++) {
      const highlightedScenarioPoint = this.graphContentGroup
        .selectAll("#scenarioPoints_" + i)
        .filter((d: [number, number]) => d[0] === xPosition);

      // Change the fill color to green for the new highlighted point
      highlightedScenarioPoint.attr("fill", "#078888").attr("fill-opacity", 0.8);

      this.previousHighlightedScenarioPoints.push(highlightedScenarioPoint);
    }

    // Store the reference to the newly highlighted point as the previousHighlightedPoint
    this.previousHighlightedPoint = highlightedPoint;
  }

  // </editor-fold desc="Mouse Movement Events">

  // <editor-fold desc="Update Filters Functions">

  /**
   * resetGranularity - resets the granularity on the dashboard to be monthly. Called when the
   * dashboard is changed. Needs to update the dropdown selector and also trigger the date time
   * formatter so that the scales are accurate.
   * @private
   */
  private resetGranularity(granularity: GranularityProperty) {
    this.selectedGranularity = granularity;
    this.selectedGranularityControl.setValue(this.selectedGranularity);
    if (this.dateTimeFormatter) {
      this.dateTimeFormatter.updateGranularity(this.selectedGranularity);
    }
  }

  /*

  private subscribeToGranularityChanges(): void {
    this.sharedService.selectedGranularity$.subscribe((granularity) => {
      if (granularity) {
        this.handleUpdateGranularity(granularity);
      }
    });
  }
*/

  // </editor-fold desc="Update Filters Functions">

  // <editor-fold desc="Initialization Functions">
  private initSVG() {
    this.psgContainerSelection = d3.select("div#psgContainer");
    this.svg = this.psgContainerSelection
      .append("svg")
      .attr("id", "primarySummaryGraphContainer")
      .attr("viewBox", "0 0 " + this.outerWidth + " " + this.outerHeight)
      .attr("preserveAspectRatio", "none")
      .classed("primary-summary-graph", true);
  }

  private initGroupings() {
    // parent grouping for graph elements
    this.graphContentGroup = this.svg
      .append("g")
      .attr("id", "primarySummaryGraph")
      .attr("transform", `translate(${this.margin.left},${this.margin.top})`);
  }

  private initAxis() {
    this.axis = new Axis(
      this.graphContentGroup,
      this.dateTimeFormatter,
      this.xScale,
      this.yScale,
      this.baseCurrency,
      this.injector
    );
  }

  private initScales() {
    this.scales = new Scales(this.graphContentGroup, this.dateTimeFormatter);
    this.xScale = this.scales.xScale;
    this.yScale = this.scales.yScale;
  }

  private initSlider() {
    this.slider = new Slider(
      this.graphContentGroup,
      this.dateTimeFormatter,
      this.xScale,
      this.yScale,
      this.dashboardService,
      this.injector
    );
  }

  private initDateFormatters() {
    this.dateTimeFormatter = new DateTimeFormatter(this.selectedGranularity);
  }

  private initMarker() {
    if (!this.graphData) {
      // don't create the marker if the graph has no data yet
      return;
    }
    if (!this.markerLine && this.dataLine) {
      // only create the marker if it hasn't been created
      this.markerLine = this.dataLine.createMarkerLine(this.innerHeight());
    }
  }

  private initDataLine() {
    this.dataLine = new DataLine(
      this.graphContentGroup,
      this.dateTimeFormatter,
      this.xScale,
      this.yScale,
      this.slider
    );
  }

  private initInOutBoxes() {
    this.inOutBoxes = new InOutBoxes(
      this.graphContentGroup,
      this.dateTimeFormatter,
      this.xScale,
      this.yScale,
      this.slider
    );
  }

  private initDottedLines() {
    this.dottedLines = new DottedLines(
      this.graphContentGroup,
      this.dateTimeFormatter,
      this.slider,
      this.xScale,
      this.yScale
    );
  }

  private initArrows() {
    this.arrows = new Arrows(this.graphContentGroup, this.xScale, this.yScale);

    this.arrows.createArrowMarkers();
  }

  private initDataPoints() {
    this.dataPoints = new DataPoints(
      this.graphContentGroup,
      this.dateTimeFormatter,
      this.xScale,
      this.yScale
    );
  }

  // </editor-fold desc="Initialization Functions">

  // <editor-fold desc="Helper Functions">
  private innerWidth(): number {
    return this.outerWidth - this.margin.left - this.margin.right;
  }

  private innerHeight(): number {
    return this.outerHeight - this.margin.top - this.margin.bottom;
  }
  // </editor-fold desc="Helper Functions">

  get selectedBand(): number {
    if (typeof this._selectedBand === "undefined") {
      this._selectedBand = this.setDefaultSelection();
    }
    return this._selectedBand;
  }

  private setDefaultSelection() {
    if (this.dashboardParameters) {
      if (this.dashboardParameters.type === "transactionOnly") {
        return this.calculateBandIndexBasedOnSelection(-1, this.graphData.length);
      } else if (this.dashboardParameters.type === "scenarioOnly") {
        return this.calculateBandIndexBasedOnSelection(-1, this.scenarioData.scenario.length);
      } else if (this.dashboardParameters.type === "transactionAndScenario") {
        return this.calculateBandIndexBasedOnSelection(-1, this.scenarioData.scenario.length);
      }
    }
  }

  /**
   * Determines the selected band based on the current selection and the available bands in the map.
   * This function allows for negative indexing, where negative values count backwards from the end of the map.
   *
   * @param {number} currentSelection - The current selection index. If negative, it counts from the end of the map.
   * @param {number} dataSetSize - Size of the dataset.
   * @returns {number} The calculated band index. If the calculated index is out of bounds, it returns 0 or the actual index.
   */
  calculateBandIndexBasedOnSelection(currentSelection: number, dataSetSize: number): number {
    // If the current selection is negative, add it to the dataSetSize to calculate the index from the end.
    // Use modulo to ensure the index wraps around if it's beyond the dataSetSize.
    if (currentSelection < 0) {
      return Math.max(0, (dataSetSize + currentSelection) % dataSetSize);
    } else {
      // If the selection is positive, use modulo to ensure it wraps around if it exceeds the dataSetSize.
      return currentSelection % dataSetSize;
    }
  }

  // </editor-fold desc="Private Functions">
  protected readonly HelperCommon = HelperCommon;
  protected readonly RoleScope = RoleScope;
}
