export enum InputTypes {
  text = "text",
  number = "number",
  password = "password",
  time = "time",
}

export const InputTypesArray = [
  InputTypes.text,
  InputTypes.number,
  InputTypes.password,
  InputTypes.time,
];
