<div
  *ngFor="let option of options"
  class="tw-flex tw-h-14 tw-w-full tw-cursor-pointer tw-items-center tw-rounded-xl tw-p-5"
  [ngClass]="{ 'tw-bg-neutral-50': isChecked(option) }"
  (click)="onInputClick(option.value)"
>
  <div class="tw-relative tw-flex tw-cursor-pointer tw-items-center tw-justify-center">
    <div class="tw-h-6 tw-w-6 tw-rounded-full tw-bg-white tw-shadow-radio-outer"></div>
    <div
      class="tw-absolute tw-rounded-full tw-w-3 tw-h-3 tw-shadow-radio-inner
      {{ isChecked(option) ? 'tw-bg-primary' : 'tw-bg-white' }} "
    ></div>
  </div>
  <label class="tw-ml-3 tw-cursor-pointer tw-text-lg tw-font-medium">{{ option.label }}</label>
</div>
