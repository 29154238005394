<app-spinner [showSpinner]="showSpinner" [diameter]="50"></app-spinner>
<div class="content">
  <div class="tw-flex tw-justify-end">
    <div class="tw-mb-2 tw-flex tw-gap-2">
      <!--@Sinan need a way to update gloss button icon from spinning to check and vice verse for example-->
      <app-gloss-button [options]="mergeButton"></app-gloss-button>
      <app-gloss-button *ngIf="isCompleted" [options]="syncCompleteButton"></app-gloss-button>
      <app-gloss-button *ngIf="isSyncing" [options]="syncProcessButton"></app-gloss-button>
    </div>
  </div>

  <div *ngIf="vm$ | async as vm" class="tw-h-[500px] tw-w-full">
    <ag-grid-angular
      class="ag-theme-quartz"
      style="height: 100%"
      [rowData]="vm.accountStatus"
      [columnDefs]="colDefs"
      [context]="context"
      [autoSizeStrategy]="autoSizeStrategy"
      (firstDataRendered)="onFirstDataRendered($event)"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
  </div>
</div>
